import React, { Fragment } from "react";
import translate from "../../Translations/index";

/**
 * Modal komponenta pro zobrazení select inputu se strukturovaným seznamem stránek
 * @param {Object} props parametry komponenty
 * @param props.page - strukturovaný seznam stránek
 * @returns
 */
const PageSelect = (props) => {
    return (
        <select className="form-control form-control-sm" value={props.value} onChange={props.onChange} name={!!props.name ? props.name : ""}>
            <option value={0}>{!props.selectDefaultTitle ? `-- ${translate.t("NotHaveParentItem")} --` : props.selectDefaultTitle}</option>

            <OptionLevelListRows {...props} parentSequenceName="" />
        </select>
    );
};
const OptionLevelListRows = (props) => {
    return (
        <>
            {props?.pageItemList?.map((item, index) => (
                <Fragment key={index}>
                    {(!props.excludeOptionSelector || (!!props.excludeOptionSelector && !props.excludeOptionSelector(item))) && (
                        <option value={item.pageID} disabled={!!props.disabledSelector ? props.disabledSelector(item) : false}>
                            {props.parentSequenceName}
                            {item.name}
                        </option>
                    )}
                    {!!item.subpages?.length && (
                        <OptionLevelListRows {...props} pageItemList={item.subpages} parentSequenceName={props.parentSequenceName + item.name + " -> "} />
                    )}
                </Fragment>
            ))}
        </>
    );
};
export default PageSelect;
