/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_PERFORMANCE_CATEGORY, GET_PERFORMANCE_CATEGORY } from "../Queries/performanceCategory";
import PerformanceCategoryLib from "../Library/performanceCategory";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import { Editor } from "@tinymce/tinymce-react";

const AddEditPerformanceCategory = (props) => {
    const client = useApolloClient();
    const performanceCategoryLib = new PerformanceCategoryLib(props);
    const helper = new Helper();
    const [selectedLang, setSelectedLang] = useState("cs");
    const [formData, setFormData] = useState({
        performanceCategoryID: props.selectedPerformanceCategoryID,
        sportID: "0",
        lang: translate.locale,
        langs: [],
    });
    const [allLanguageMutations, setAllLanguageMutations] = useState([]);

    const {
        loading: getLoading,
        error: getError,
        data: performanceCategoryData,
    } = useQuery(GET_PERFORMANCE_CATEGORY, {
        variables: { performanceCategoryID: formData.performanceCategoryID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            setAllLanguageMutations(data.allLanguageMutations);
            if (formData.performanceCategoryID != 0) {
                let langs = performanceCategoryLib.completeLangsArray(
                    helper.copyObjectsWithoutParam(data.performanceCategoryWithLangs.langs, "__typename"),
                    data.allLanguageMutations
                );

                setFormData({
                    ...formData,
                    sportID: data.performanceCategoryWithLangs.sportID,
                    langs: langs,
                });
            } else {
                let langs = performanceCategoryLib.completeLangsArray([], data.allLanguageMutations);
                setFormData({ ...formData, langs: langs });
            }
        },
    });

    const [addPerformanceCategory, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_PERFORMANCE_CATEGORY, {
        onCompleted(data) {
            setFormData({ ...formData, performanceCategoryID: data.addEditPerformanceCategory.performanceCategoryID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addPerformanceCategoryData = () => {
        let notify = new Notification();
        if (!(formData.ageLimitFrom == "" && formData.ageLimitTo == "")) {
            if (formData.sportID != "0") {
                let dataToSend = {
                    ...formData,
                    sportID: parseInt(formData.sportID),
                };
                addPerformanceCategory({
                    variables: {
                        addEditData: dataToSend,
                    },
                });
            } else {
                notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
            }
        } else {
            notify.setNotification(translate.t("MustFillOneLimit"), "danger");
        }
    };
    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md performanceCategory-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {formData.performanceCategoryID && formData.performanceCategoryID != 0
                            ? translate.t("EditPerformanceCategory")
                            : translate.t("AddPerformanceCategory")}
                    </h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 && (
                        <div className="ml-auto">
                            <select
                                className="form-control"
                                name="selectedLang"
                                onChange={(e) => {
                                    setSelectedLang(e.target.value);
                                }}
                            >
                                {allLanguageMutations &&
                                    allLanguageMutations.map((item, index) => (
                                        <option key={index} value={item.suffix}>
                                            {" "}
                                            {item.suffix}{" "}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">
                                                    {translate.t("PerformanceCategoryTitle")}{" "}
                                                    {allLanguageMutations && allLanguageMutations.length > 1 && <Fragment>({selectedLang})</Fragment>}
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="title"
                                                        value={helper.getLangValue(formData, "title", selectedLang)}
                                                        onChange={(e) => helper.formLangHandle(e, formData, setFormData, selectedLang)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Sport")}</label>
                                                <div className="form-group">
                                                    <select
                                                        value={formData.sportID}
                                                        className="form-control"
                                                        name="roleID"
                                                        onChange={(e) => setFormData({ ...formData, sportID: e.target.value })}
                                                    >
                                                        <option value={0}>-- {translate.t("ChooseSport")} --</option>
                                                        {performanceCategoryData?.sports?.map((item, index) => (
                                                            <option key={index} value={item.sportID}>
                                                                {item.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="input-label">
                                                    {translate.t("Description")}{" "}
                                                    {allLanguageMutations && allLanguageMutations.length > 1 && <Fragment>({selectedLang})</Fragment>}
                                                </label>
                                                <div className="form-group">
                                                    <Editor
                                                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                                                        name="description"
                                                        value={helper.getLangValue(formData, "description", selectedLang)}
                                                        init={{
                                                            promotion: false,
                                                            height: 260,
                                                            plugins: "link image code",
                                                            toolbar: "undo redo bold italic alignleft aligncenter alignright code",
                                                        }}
                                                        onEditorChange={(content) =>
                                                            helper.formLangHandleEditor(content, "description", formData, setFormData, selectedLang)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => addPerformanceCategoryData()}>
                        {formData.performanceCategoryID && formData.performanceCategoryID != 0 ? translate.t("Edit") : translate.t("Add")}
                    </button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddEditPerformanceCategory;
