/*
    Přidání štítku
*/
import React from "react";
import Modal from "react-modal";
import translate from "../../Translations/index";
import PrimaryData from "./PrimaryData";

const AddEditDiscipline = (props) => {
    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg discipline-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {props.selectedDisciplineID && props.selectedDisciplineID != 0 ? translate.t("EditDiscipline") : translate.t("AddDiscipline")}
                    </h4>
                    <button discipline="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <PrimaryData
                    inModal={true}
                    openCloseModal={props.openCloseModal}
                    selectedCompetitionHashID={props.selectedCompetitionHashID}
                    selectedDisciplineHashID={props.selectedDisciplineHashID}
                    sportID={props.sportID}
                />
            </div>
        </Modal>
    );
};

export default AddEditDiscipline;
