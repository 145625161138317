/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import { GET_DISCIPLINE_TEAMS } from "../Queries/discipline";
import Notification from "../../../Library/notification";
import translate from "../../Translations/index";
import Helper from "../../../Library/helper";
import moment from "moment";

class Team {
    constructor(props) {
        this.props = props;
    }

    /**
     * Přidání/Editace
     * @param {*} addTeam funkce z apollo, pomocí které se posílají data na server
     */

    addTeam = async (addTeam, formData, athleteCountInTeam, callback = null) => {
        let notify = new Notification();
        if (athleteCountInTeam == 1) {
            formData.title = formData.athletes[0].name + " " + formData.athletes[0].surname;
        }

        if (formData && formData.title != "") {
            let athletes = [];
            for (const athlete of formData.athletes) {
                athletes.push({
                    athleteID: athlete.athleteID,
                });
            }
            addTeam({
                variables: {
                    addEditData: {
                        hashID: formData.hashID,
                        disciplineHashID: formData.disciplineHashID,
                        title: formData.title,
                        athletes,
                        lang: translate.locale,
                    },
                },
            }).then(
                (responseData) => {
                    if (callback) {
                        callback(true, responseData);
                    }
                },
                (errorData) => {
                    if (callback) {
                        callback(false, errorData);
                    }
                }
            );
        } else {
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache, response, hashID, variables) {
        const { allDisciplineTeams } = cache.readQuery({ query: GET_DISCIPLINE_TEAMS, variables });
        if (!(hashID && hashID != "")) {
            const arr = [response.data.addEditTeam, ...allDisciplineTeams];
            //arr.sort(this.sortListFunction);

            cache.writeQuery({
                query: GET_DISCIPLINE_TEAMS,
                data: {
                    allDisciplineTeams: arr,
                },
                variables,
            });
        } else {
            var arr = [...allDisciplineTeams];

            arr.forEach((item, index) => {
                if (item.hashID == response.data.addEditTeam.hashID) {
                    arr[index].hashID = response.data.addEditTeam.hashID;
                    arr[index].title = response.data.addEditTeam.title;
                    arr[index].disciplineHashID = response.data.addEditTeam.disciplineHashID;
                    arr[index].athletes = response.data.addEditTeam.athletes;
                }
            });
            //arr.sort(this.sortListFunction);
            cache.writeQuery({
                query: GET_DISCIPLINE_TEAMS,
                data: {
                    allDisciplineTeams: arr,
                },
                variables,
            });
        }
    }

    sortListFunction(a, b) {
        if (moment(a.date).toDate() < moment(b.date).toDate()) {
            return 1;
        } else if (moment(a.date).toDate() > moment(b.date).toDate()) {
            return -1;
        } else {
            if (a.disciplineID > b.disciplineID) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    /**
     *
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky
     */

    updateAfterDelete(cache, response, variables) {
        var resp = response.data.deleteTeams.split(",");

        const { allDisciplineTeams } = cache.readQuery({ query: GET_DISCIPLINE_TEAMS, variables });
        var arr = [...allDisciplineTeams];

        resp.forEach((it, ind) => {
            arr.forEach((item, index) => {
                if (item.hashID == it) {
                    arr = [...arr.slice(0, index), ...arr.slice(index + 1)];
                }
            });
        });

        cache.writeQuery({
            query: GET_DISCIPLINE_TEAMS,
            variables,
            data: {
                allDisciplineTeams: [...arr],
            },
        });
    }
}

export default Team;
