/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit globalDiscipline
 */
export const ADD_EDIT_GLOBAL_DISCIPLINE = gql`
    mutation AddEditGlobalDiscipline($addEditData: AddEditGlobalDisciplineDataInput) {
        addEditGlobalDiscipline(addEditData: $addEditData) {
            title
        }
    }
`;

/**
 *  Data federace
 */

export const GET_GLOBAL_DISCIPLINE = gql`
    query GlobalDiscipline($globalDisciplineID: ID, $lang: String) {
        globalDiscipline(globalDisciplineID: $globalDisciplineID) {
            globalDisciplineID
            title
            sportID
        }
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 *  Seznam veškerých federací
 */
export const GET_GLOBAL_DISCIPLINES = gql`
    query GlobalDisciplines($filters: GlobalDisciplinesFilters, $lang: String) {
        allGlobalDisciplines(filters: $filters) {
            globalDisciplineID
            title
            sportID
            sport(lang: $lang) {
                title
            }
        }
        globalDisciplinesCount(filters: $filters)
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 * Smazání federací.
 */
export const DELETE_GLOBAL_DISCIPLINES = gql`
    mutation deleteGlobalDisciplines($globalDisciplineIDs: ID!) {
        deleteGlobalDisciplines(globalDisciplineIDs: $globalDisciplineIDs)
    }
`;
