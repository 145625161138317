/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditOrganiserContact from "./AddEditOrganiserContact";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ORGANISER_CONTACTS, DELETE_ORGANISER_CONTACTS } from "../Queries/organiser";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";

const OrganiserContactList = (props) => {
    const [selectedOrganiserContacts, setSelectedOrganiserContacts] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedOrganiserContactID, setSelectedOrganiserContactID] = useState("");

    const {
        loading,
        error: getError,
        data: organiserContactListData,
        fetchMore,
        refetch,
    } = useQuery(GET_ORGANISER_CONTACTS, {
        variables: {
            organiserID: props.organiserID,
        },
        fetchPolicy: "cache-and-network",
    });

    const [deleteOrganiserContact, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_ORGANISER_CONTACTS, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            refetch();
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, organiserContactID) => {
        setSelectedOrganiserContactID(organiserContactID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteOrganiserContacts funkce, která posílá data na server
     * @param {*} organiserContactID ID organiserContactu
     */

    const initiateDeleteOrganiserContact = (e, organiserContactID) => {
        var checked = e.target.checked;
        var arr = [...selectedOrganiserContacts];

        if (!checked) {
            for (let i in selectedOrganiserContacts) {
                if (selectedOrganiserContacts[i] == organiserContactID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [organiserContactID, ...arr];
        }

        setSelectedOrganiserContacts(arr);
    };

    const delOrganiserContact = (action) => {
        if (action) {
            let organiserContactIDs = selectedOrganiserContacts.join(",");

            deleteOrganiserContact({
                variables: {
                    organiserContactIDs: organiserContactIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedOrganiserContacts.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div>
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("OrganiserContactList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("OrganiserContactTitle")}</div>
                                                <div>{translate.t("OrganiserContactEmail")}</div>
                                                <div>{translate.t("OrganiserContactPhone")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {organiserContactListData?.organiserContacts?.map((item, index) => {
                                                var checked = false;
                                                for (let i in selectedOrganiserContacts) {
                                                    if (selectedOrganiserContacts[i] == item.organiserContactID) checked = true;
                                                }

                                                return (
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.name}</div>
                                                        <div>{item.email}</div>
                                                        <div>{item.phone}</div>
                                                        <div className="text-right">
                                                            <img
                                                                onClick={() => openCloseModal(true, item.organiserContactID)}
                                                                className="edit-icon"
                                                                src={edit}
                                                            />
                                                            <input
                                                                className="delete-checked"
                                                                type="checkbox"
                                                                name="deleteOrganiserContact[]"
                                                                checked={checked}
                                                                onChange={(e) => initiateDeleteOrganiserContact(e, item.organiserContactID)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {!loading && (
                                                <>{!organiserContactListData?.organiserContacts?.length && <NoItems text={translate.t("NoItemsAddedYet")} />}</>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditOrganiserContact
                    openCloseModal={openCloseModal}
                    organiserContactID={selectedOrganiserContactID}
                    organiserID={props.organiserID}
                    updated={() => refetch()}
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delOrganiserContact} />}
        </div>
    );
};

export default OrganiserContactList;
