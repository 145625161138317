/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit competition
 */
export const ADD_EDIT_COMPETITION = gql`
    mutation AddEditCompetition($addEditData: AddEditCompetitionDataInput) {
        addEditCompetition(addEditData: $addEditData) {
            competitionID
            hashID
            title
            dateFrom
            dateTo
            organizatorName
            organizatorSurname
        }
    }
`;

export const EDIT_COMPETITION_INVITATION_LETTER_TEXT = gql`
    mutation EditCompetitionInvitationLetterText($competitionHashID: ID!, $invitationLetterText: String) {
        editCompetitionInvitationLetterText(competitionHashID: $competitionHashID, invitationLetterText: $invitationLetterText)
    }
`;

/**
 *  Competition data
 */

export const GET_COMPETITION = gql`
    query Competition($hashID: ID!, $lang: String) {
        competition(hashID: $hashID) {
            competitionID
            hashID
            adminUserHashID
            sportID
            organiserID
            countryID
            title
            dateFrom
            dateTo
            place
            description
            competitionStateID
            photo
            file
            fileSize
            organizatorName
            organizatorSurname
        }

        allAdminUsers(lang: "en") {
            hashID
            name
            surname
            email
            roleID
            roleTitle
        }

        allCountries {
            countryID
            name
            code
        }

        organisers {
            organiserID
            name
        }

        allSportsBySignedAdminUser(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

export const GET_COMPETITION_INVITATION_LETTER_TEXT = gql`
    query Competition($hashID: ID) {
        competition(hashID: $hashID) {
            competitionID
            hashID
            invitationLetterText
        }
    }
`;
/**
 *  Seznam veškerých competitionů
 */
export const GET_COMPETITIONS = gql`
    query AllCompetitions($filters: CompetitionsFilters) {
        competitionsByRole(filters: $filters) {
            competitionID
            hashID
            sportID
            organiserID
            title
            dateFrom
            dateTo
            competitionStateID
            organiser {
                name
            }
        }
        competitionsByRoleListCount(filters: $filters)
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_COMPETITIONS = gql`
    mutation deleteCompetitions($hashIDs: ID!) {
        deleteCompetitions(hashIDs: $hashIDs)
    }
`;

/**
 *  Seznam veškerých competitionů
 */
export const GET_COMPETITION_TEAMS = gql`
    query AllCompetitionTeams($hashIDs: Int) {
        allCompetitionTeams(hashIDs: $hashIDs) {
            teamID
            title
            athletes {
                athleteID
                name
                surname
                email
                phone
            }
        }
    }
`;
