/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit discipline
 */
export const ADD_EDIT_DISCIPLINE = gql`
    mutation AddEditDiscipline($addEditData: AddEditDisciplineDataInput) {
        addEditDiscipline(addEditData: $addEditData) {
            competitionHashID
            hashID
        }
    }
`;
/**
 * add/edit discipline
 */
export const OPEN_CLOSE_DISCIPLINE = gql`
    mutation OpenCloseDiscipline($disciplineHashID: ID, $closed: Int) {
        openCloseDiscipline(disciplineHashID: $disciplineHashID, closed: $closed)
    }
`;

/**
 *  Discipline data
 */

export const GET_DISCIPLINE = gql`
    query Discipline($hashID: ID!, $lang: String, $sportID: ID) {
        discipline(hashID: $hashID) {
            disciplineID
            hashID
            globalDisciplineID
            competitionHashID
            ageCategoryID
            rankingPointTableID
            title
            athleteCountInTeam
            sportUnitCount
            maxTeamRegistrationCount
            registrationClosed
            advancingToFinalCount
            heatsEnabled
            performanceCategories(filters: { lang: $lang }) {
                performanceCategoryID
                title
            }
        }
        allAgeCategorys(filters: { lang: $lang, sportID: $sportID }) {
            ageCategoryID
            title
        }
        allPerformanceCategories(filters: { sportID: $sportID, lang: $lang }) {
            performanceCategoryID
            title
        }
        allGlobalDisciplines(filters: { sportID: $sportID }) {
            globalDisciplineID
            title
        }
        allRankingPointTables(filters: { sportID: $sportID }) {
            rankingPointTableID
            name
        }
    }
`;

/**
 *  Registration data
 */

export const GET_REGISTRATION_DATA = gql`
    query Discipline($competitionHashID: ID, $disciplineHashID: ID, $lang: String) {
        competition(hashID: $competitionHashID) {
            competitionStateID
        }
        discipline(hashID: $disciplineHashID) {
            disciplineID
            hashID
            competitionHashID
            ageCategoryID
            title
            athleteCountInTeam
            registrationClosed
        }
        allAgeCategorys(filters: { lang: $lang, offset: 0, limit: 1000000 }) {
            ageCategoryID
            title
        }
    }
`;

/**
 *  Seznam veškerých disciplineů
 */
export const GET_DISCIPLINES = gql`
    query AllDisciplines($competitionHashID: ID) {
        allDisciplines(competitionHashID: $competitionHashID) {
            disciplineID
            hashID
            competitionHashID
            ageCategoryID
            title
            athleteCountInTeam
            registrationClosed
        }
        disciplineListCount(competitionHashID: $competitionHashID)
    }
`;

/**
 * Smazání discipliny.
 */
export const DELETE_DISCIPLINES = gql`
    mutation deleteDisciplines($hashIDs: ID!) {
        deleteDisciplines(hashIDs: $hashIDs)
    }
`;

/**
 *  Seznam veškerých disciplineů
 */
export const GET_DISCIPLINE_TEAMS = gql`
    query AllDisciplineTeams($disciplineHashID: ID) {
        allDisciplineTeams(disciplineHashID: $disciplineHashID) {
            hashID
            title
            disciplineHashID
            athletes {
                hashID
                name
                surname
                email
            }
        }
    }
`;

/**
 *  Seznam veškerých disciplineů
 */
export const GET_DISCIPLINE_LADDERS = gql`
    query AllDisciplineLadders($disciplineHashID: ID, $lang: String) {
        allDisciplineLadders(disciplineHashID: $disciplineHashID, lang: $lang) {
            hashID
            title
            description
        }
    }
`;

/**
 * Správa týmů ve výsledcích disciplíny
 */
export const GENERATE_RESULTS = gql`
    mutation generateResults($disciplineID: ID, $lang: String) {
        generateResults(disciplineID: $disciplineID, lang: $lang)
    }
`;
export const ADD_EDIT_DISCIPLINE_RESULT = gql`
    mutation AddEditDisciplineResult($addEditData: AddEditDisciplineResultDataInput) {
        addEditDisciplineResult(addEditData: $addEditData) {
            disciplineResultID
        }
    }
`;
export const GET_DISCIPLINE_RESULTS = gql`
    query DisciplineResults($disciplineID: ID) {
        disciplineResults(disciplineID: $disciplineID) {
            disciplineResultID
            teamID
            title
            rank
            time
        }
    }
`;
export const GET_DISCIPLINE_RESULT = gql`
    query DisciplineResult($disciplineResultID: ID, $disciplineID: ID, $disciplineResultIdToInclude: ID) {
        disciplineResult(disciplineResultID: $disciplineResultID) {
            disciplineResultID
            teamID
            title
            rank
            time
        }
        disciplineUnassignedResultTeams(disciplineID: $disciplineID, disciplineResultIdToInclude: $disciplineResultIdToInclude) {
            teamID
            title
        }
        disciplineResultsCount(disciplineID: $disciplineID)
    }
`;
export const DELETE_DISCIPLINE_RESULTS = gql`
    mutation deleteDisciplineResults($disciplineResultIDs: ID!) {
        deleteDisciplineResults(disciplineResultIDs: $disciplineResultIDs)
    }
`;
