/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_FEDERATION, GET_FEDERATION } from "../Queries/federation";
import FederationLib from "../Library/federation";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import EditFederationLogo from "./EditFederationLogo";

const AddEditFederation = (props) => {
    const federationLib = new FederationLib(props);
    const [selectedTab, setTab] = useState(1);

    const [formData, setFormData] = useState({
        federationID: props.selectedFederationID,
        sportID: "0",
        name: "",
        email: "",
        web: "",
        countryID: "0",
        showOnWeb: "0",
    });

    const {
        loading: getLoading,
        error: getError,
        data: federationData,
    } = useQuery(GET_FEDERATION, {
        variables: { federationID: formData.federationID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.federationID != "") {
                setFormData({
                    ...formData,
                    name: data.federation.name,
                    countryID: data.federation.countryID,
                    sportID: data.federation.sportID,
                    email: data.federation.email,
                    web: data.federation.web,
                    adminUserID: data.federation.adminUserID,
                    showOnWeb: data.federation.showOnWeb,
                });
            }
        },
    });

    const [addFederation, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_FEDERATION, {
        onCompleted(data) {
            setFormData({ ...formData, federationID: data.addEditFederation.federationID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addFederationData = () => {
        if (!!formData.name && formData.countryID != "0" && formData.sportID != "0" && !!formData.email) {
            let dataToSend = {
                ...formData,
                showOnWeb: parseInt(formData.showOnWeb),
            };
            addFederation({
                variables: {
                    addEditData: dataToSend,
                },
            });
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md federation-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {formData.federationID && formData.federationID != "" ? translate.t("EditFederation") : translate.t("AddFederation")}
                    </h4>

                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <ul className={`nav nav-tabs`} role="tablist">
                                        <li className={`nav-item mr-2`}>
                                            <a className={`nav-link ${selectedTab == 1 ? `active` : ""}`} onClick={(e) => setTab(1)}>
                                                {translate.t("MainData")}
                                            </a>
                                        </li>
                                        <li className={`nav-item mr-2`}>
                                            <a
                                                className={`nav-link ${selectedTab == 2 ? `active` : ""}  ${!formData.federationID ? "disabled" : ""}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (formData.federationID) setTab(2);
                                                }}
                                            >
                                                {translate.t("Logo")}
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        {selectedTab == 1 && (
                                            <>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("FederationTitle")}</label>
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={formData.name}
                                                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("Country")}</label>
                                                            <div className="form-group">
                                                                <select
                                                                    value={formData.countryID}
                                                                    className="form-control"
                                                                    name="roleID"
                                                                    onChange={(e) => setFormData({ ...formData, countryID: e.target.value })}
                                                                >
                                                                    <option value={0}>-- {translate.t("ChooseCountry")} --</option>
                                                                    {federationData &&
                                                                        federationData.allCountries.map((item, index) => (
                                                                            <option key={index} value={item.countryID}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("Sport")}</label>
                                                            <div className="form-group">
                                                                <select
                                                                    value={formData.sportID}
                                                                    className="form-control"
                                                                    name="sportID"
                                                                    onChange={(e) => setFormData({ ...formData, sportID: e.target.value })}
                                                                >
                                                                    <option value={0}>-- {translate.t("ChooseSport")} --</option>
                                                                    {federationData &&
                                                                        federationData.sports.map((item, index) => (
                                                                            <option key={index} value={item.sportID}>
                                                                                {item.title}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("Email")}</label>
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={formData.email}
                                                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("ShowOnWeb")}</label>
                                                            <div className="form-group">
                                                                <select
                                                                    value={formData.showOnWeb}
                                                                    className="form-control"
                                                                    name="showOnWeb"
                                                                    onChange={(e) => setFormData({ ...formData, showOnWeb: e.target.value })}
                                                                >
                                                                    <option value={1}>{translate.t("Yes")}</option>
                                                                    <option value={0}>{translate.t("No")}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("Web")}</label>
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={formData.web}
                                                                    onChange={(e) => setFormData({ ...formData, web: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-end mt-4">
                                                    <button type="button" className="btn btn-primary mr-2" onClick={() => addFederationData()}>
                                                        {formData.federationID && formData.federationID != "" ? translate.t("Edit") : translate.t("Add")}
                                                    </button>
                                                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                                                        {translate.t("Close")}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                        {selectedTab == 2 && (
                                            <EditFederationLogo
                                                federationID={formData.federationID}
                                                openCloseModal={(status) => props.openCloseModal(status)}
                                            />
                                        )}
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddEditFederation;
