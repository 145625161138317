/**
 * soubor se seznamem graphql dotazů
 */
import gql from "graphql-tag";

export const ADD_EDIT_COMPETITION_SPONSOR = gql`
    mutation AddEditCompetitionSponsor($addEditData: AddEditCompetitionSponsorDataInput) {
        addEditCompetitionSponsor(addEditData: $addEditData) {
            competitionSponsorID
        }
    }
`;

/**
 *  Data federace
 */

export const GET_COMPETITION_SPONSOR = gql`
    query CompetitionSponsor($competitionSponsorID: ID) {
        competitionSponsor(competitionSponsorID: $competitionSponsorID) {
            competitionSponsorID
            competitionID
            name
            photo
        }
    }
`;

export const GET_COMPETITION_SPONSORS = gql`
    query CompetitionSponsors($competitionID: ID, $filters: CompetitionSponsorsFilters) {
        competitionSponsors(competitionID: $competitionID, filters: $filters) {
            competitionSponsorID
            competitionID
            name
            photo
        }
    }
`;

export const DELETE_COMPETITION_SPONSORS = gql`
    mutation deleteCompetitionSponsors($competitionSponsorIDs: ID!) {
        deleteCompetitionSponsors(competitionSponsorIDs: $competitionSponsorIDs)
    }
`;
