import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { SERVER_URL } from "../../../Config";
import translate from "../../Translations/index";

const TinyMceInlineTextOnlyEditor = (props) => {
    const editorRef = useRef(null);

    return (
        <div>
            <Editor
                tinymceScriptSrc={`${SERVER_URL}/Public/tinymce/tinymce.min.js`}
                onInit={(evt, editor) => (editorRef.current = editor)}
                name="htmlContent"
                value={props.value}
                inline={true}
                min_height={100}
                init={{
                    plugins: "autoresize",
                    toolbar1: "alignleft aligncenter alignright alignjustify pastetext",
                    language_url: `${SERVER_URL}/Public/tinymce/langs/cs.js`,
                    language: translate.locale ? translate.locale : "en",
                    paste_as_text: true,
                    menubar: false,
                    relative_urls: false,
                    remove_script_host: false,
                    entity_encoding: "raw",
                    ...(!!props.validElements && { valid_elements: props.validElements }),
                }}
                onEditorChange={(content, editor) => props.onChange(content, editor)}
            />
        </div>
    );
};

export default TinyMceInlineTextOnlyEditor;
