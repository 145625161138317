/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditSport from "./AddEditSport";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_SPORTS, DELETE_SPORTS } from "../Queries/sport";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import SportLib from "../Library/sport";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import SearchInput from "../../General/Components/SearchInput";

const Sports = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    helper.checkAdminRights({ ...props, client }, ["1", "2"]);

    const [listVariables, setListVariables] = useState({
        filters: {
            offset: 0,
            limit: 50,
            searchQuery: "",
            lang: translate.locale,
        },
    });

    const [selectedSports, setSelectedSports] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedID, setSelectedID] = useState("");
    const {
        loading,
        error: getError,
        data: sportListData,
        fetchMore,
        refetch,
    } = useQuery(GET_SPORTS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
    });

    const [deleteSport, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_SPORTS, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            refetch({ filters: { ...listVariables.filters, limit: sportListData.sports.length } });
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, sportID) => {
        setSelectedID(sportID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteSports funkce, která posílá data na server
     * @param {*} sportID ID sportu
     */

    const initiateDeleteSport = (e, sportID) => {
        var checked = e.target.checked;
        var arr = [...selectedSports];

        if (!checked) {
            for (let i in selectedSports) {
                if (selectedSports[i] == sportID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [sportID, ...arr];
        }

        setSelectedSports(arr);
    };

    const delSport = (action) => {
        if (action) {
            let sportIDs = selectedSports.join(",");

            deleteSport({
                variables: {
                    sportIDs: sportIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedSports.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div id="settings" className="whole-container">
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("SportList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, 0)}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className={`d-flex px-3 py-2 bg-light border-bottom align-items-center`}>
                            <div className="form-group ml-auto">
                                <div className="text-nowrap fs12">{translate.t("Search")}</div>
                                <SearchInput
                                    className={"form-control form-control-sm "}
                                    value={searchQuery}
                                    onChange={(text) => {
                                        setListVariables({ ...listVariables, filters: { ...listVariables.filters, searchQuery: text, offset: 0 } });
                                    }}
                                    textChanged={(text) => {
                                        setSearchQuery(text);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("SportTitle")}</div>
                                                <div className="text-center">{translate.t("ShowOnWeb")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {sportListData?.sports?.map((item, index) => {
                                                var checked = false;
                                                for (let i in selectedSports) {
                                                    if (selectedSports[i] == item.sportID) checked = true;
                                                }

                                                return (
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{helper.getLangValueText(item.langs, "title")}</div>
                                                        <div className="text-center">{item.showOnWeb == "1" ? translate.t("Yes") : translate.t("No")}</div>
                                                        <div className="text-right">
                                                            <img onClick={() => openCloseModal(true, item.sportID)} className="edit-icon" src={edit} />
                                                            <input
                                                                className="delete-checked"
                                                                type="checkbox"
                                                                name="deleteSport[]"
                                                                checked={checked}
                                                                onChange={(e) => initiateDeleteSport(e, item.sportID)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {!loading && (
                                                <>
                                                    {!!sportListData?.sports?.length ? (
                                                        <>
                                                            {sportListData.sports.length < sportListData.sportsCount && (
                                                                <div className="text-center p-5">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() =>
                                                                            fetchMore({
                                                                                variables: {
                                                                                    ...listVariables,
                                                                                    filters: { ...listVariables.filters, offset: sportListData.sports.length },
                                                                                },
                                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                                    if (!fetchMoreResult) return prev;
                                                                                    return Object.assign({}, prev, {
                                                                                        sports: [...prev.sports, ...fetchMoreResult.sports],
                                                                                    });
                                                                                },
                                                                            })
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {translate.t("LoadMore")}{" "}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                                    )}
                                                </>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditSport
                    openCloseModal={openCloseModal}
                    selectedID={selectedID}
                    listVariables={listVariables}
                    updated={() => refetch({ filters: { ...listVariables.filters, limit: sportListData.sports.length } })}
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delSport} />}
        </div>
    );
};

export default Sports;
