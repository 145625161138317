/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditLadderOrganiser from "./AddEditLadderOrganiser";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation } from "@apollo/client";
import { GET_LADDER_ORGANISERS, DELETE_LADDER_ORGANISERS } from "../Queries/ladder";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";

const LadderOrganiserList = (props) => {
    const [selectedLadderOrganisers, setSelectedLadderOrganisers] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedLadderOrganiserID, setSelectedLadderOrganiserID] = useState("");

    const {
        loading,
        error: getError,
        data: ladderOrganiserListData,
        fetchMore,
        refetch,
    } = useQuery(GET_LADDER_ORGANISERS, {
        variables: {
            ladderID: props.ladderID,
        },
        fetchPolicy: "cache-and-network",
    });

    const [deleteLadderOrganiser, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_LADDER_ORGANISERS, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            refetch();
            !!props.updated && props.updated();
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, ladderOrganiserID) => {
        setSelectedLadderOrganiserID(ladderOrganiserID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteLadderOrganisers funkce, která posílá data na server
     * @param {*} ladderOrganiserID ID ladderOrganiseru
     */

    const initiateDeleteLadderOrganiser = (e, ladderOrganiserID) => {
        var checked = e.target.checked;
        var arr = [...selectedLadderOrganisers];

        if (!checked) {
            for (let i in selectedLadderOrganisers) {
                if (selectedLadderOrganisers[i] == ladderOrganiserID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [ladderOrganiserID, ...arr];
        }

        setSelectedLadderOrganisers(arr);
    };

    const delLadderOrganiser = (action) => {
        if (action) {
            let ladderOrganiserIDs = selectedLadderOrganisers.join(",");

            deleteLadderOrganiser({
                variables: {
                    ladderOrganiserIDs: ladderOrganiserIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedLadderOrganisers.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div>
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("LadderOrganiserList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Name")}</div>
                                                <div className="text-center">{translate.t("CanEditLadder")}</div>
                                                <div className="text-center">{translate.t("CanDeleteLadder")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {ladderOrganiserListData?.ladderOrganisers?.map((item, index) => {
                                                var checked = false;
                                                for (let i in selectedLadderOrganisers) {
                                                    if (selectedLadderOrganisers[i] == item.ladderOrganiserID) checked = true;
                                                }

                                                return (
                                                    <div key={index} className="data-list-item-content">
                                                        <div>
                                                            {item.name} {item.surname}
                                                        </div>
                                                        <div className="text-center">{item.canEditLadder == 1 ? translate.t("Yes") : translate.t("No")}</div>
                                                        <div className="text-center">{item.canDeleteLadder == 1 ? translate.t("Yes") : translate.t("No")}</div>
                                                        <div className="text-right">
                                                            <img
                                                                onClick={() => openCloseModal(true, item.ladderOrganiserID)}
                                                                className="edit-icon"
                                                                src={edit}
                                                            />

                                                            <input
                                                                className="delete-checked"
                                                                type="checkbox"
                                                                name="deleteLadderOrganiser[]"
                                                                checked={checked}
                                                                onChange={(e) => initiateDeleteLadderOrganiser(e, item.ladderOrganiserID)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {!loading && (
                                                <>{!ladderOrganiserListData?.ladderOrganisers?.length && <NoItems text={translate.t("NoItemsAddedYet")} />}</>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditLadderOrganiser
                    openCloseModal={openCloseModal}
                    ladderOrganiserID={selectedLadderOrganiserID}
                    ladderID={props.ladderID}
                    updated={() => {
                        refetch();
                        !!props.updated && props.updated();
                    }}
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delLadderOrganiser} />}
        </div>
    );
};

export default LadderOrganiserList;
