import { useApolloClient } from "@apollo/client";

function withApollo(Component) {
    return function WithApolloComponent(props) {
        const client = useApolloClient();
        return <Component {...props} client={client} />;
    };
}

export default withApollo;
