import React, { useEffect, useRef } from "react";
import AddElement from "./AddElement";
import {
    addSection,
    onDragEnd,
    onDragSectionEnd,
    removeSection,
    setSectionBackgroundColorClass,
    setSectionFullWidth,
    showHideHelpers,
} from "../Library/contentManager";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useReactiveVar } from "@apollo/client";
import { cmDataVar, initialContentManagerData } from "../Library/cmData";
import DragColumnsElement from "./DragColumnsElement";
import TextElement from "./TextElement";
import ArticlesElement from "./ArticlesElement";
import BannerElement from "./BannerElement";
import ContactFormElement from "./ContactFormElement";
import translate from "../../Translations/index";
import ButtonElement from "./ButtonElement";
import Tournaments from "./TournamentsElement";
import Ladders from "./LaddersElement";
import Athletes from "./AthletesElement";
import Federations from "./FederationsElement";
import Clubs from "./ClubElement";
import Sports from "./SportsElement";
/**
 * Modal komponenta pro tvorbu wysiwyg obsahu
 * @param {Object} props parametry komponenty
 * @param props.content - obsah content manageru
 * @param props.initialContent - obsah content manageru
 * @param props.onChange - callback volaný po editaci záznamu
 * @param props.onSave - callback volaný po stisku uložení tlačítka
 * @returns
 */
const ContentManager = (props) => {
    const cmData = useReactiveVar(cmDataVar);
    const contentRef = useRef();

    useEffect(() => {
        window.addEventListener("resize", resized);
        resized();
        return () => {
            window.removeEventListener("resize", resized);
            cmDataVar({ ...cmDataVar(), content: initialContentManagerData.content });
        };
    }, []);

    useEffect(() => {
        cmDataVar({ ...cmDataVar(), content: !!props.content ? JSON.parse(props.content) : initialContentManagerData.content });
    }, [props.content]);

    //console.log(props.content);
    useEffect(() => {
        //console.log(JSON.stringify(cmDataVar().content));
        //console.log(JSON.stringify(props.content));
        //console.log(props.content);
        if (props.content == null || !!cmDataVar().content.toString() != props.content.toString()) {
            !!props.onChange && props.onChange(cmDataVar().content);
        }
    }, [cmDataVar().content]);
    const resized = () => {
        cmDataVar({ ...cmDataVar(), contentWidth: contentRef.current.clientWidth, contentHeight: contentRef.current.clientHeight });
    };

    const saveContent = () => {
        props.onSave && props.onSave(cmDataVar().content);
    };

    return (
        <div className={`content-manager d-flex flex-column`}>
            <div className={`cm-header d-flex flex-row`}>
                <div className="d-flex flex-row align-items-center">
                    <input
                        className="form-check-input mr-1 ml-0 mt-1 position-relative"
                        type="checkbox"
                        checked={cmData.showHelpers}
                        id="controllElementsCheck"
                        onChange={() => showHideHelpers()}
                    />
                    <label className="form-check-label" htmlFor="controllElementsCheck">
                        {translate.t("ControllElements")}
                    </label>
                </div>
                <button className="btn btn-primary ml-auto" onClick={() => saveContent()}>
                    {translate.t("SaveContent")}
                </button>
            </div>
            <div className="d-flex flex-row">
                <div className={`cm-content`} ref={contentRef}>
                    <DragDropContext onDragEnd={(result) => onDragSectionEnd(result)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {cmData.content.sections &&
                                        cmData.content.sections.map((item, index) => {
                                            return (
                                                <Draggable key={`sec-${index}`} draggableId={`sec-${index}`} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps}>
                                                            <section
                                                                key={index}
                                                                className={`
                                                                    ${item.backgroundColorClass} 
                                                                    ${item.paddingClass} 
                                                                    ${item.className} 
                                                                    ${item.elements.length == 0 ? `cm-min-eight` : ""} 
                                                                `}
                                                                style={{
                                                                    ...(!!item.backgroundImage && { backgroundImage: `url("${item.backgroundImage}")` }),
                                                                }}
                                                            >
                                                                {item.elements.length == 0 && (
                                                                    <div className={`cm-start-info`}>{translate.t("ClickAddTtoInsertFirstContent")}</div>
                                                                )}
                                                                {cmData.showHelpers && (
                                                                    <div className={`cm-menu`}>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="mr-1">
                                                                                <AddElement isSection={true} lastIndexes={"" + index + ""} element={item} />
                                                                            </div>

                                                                            <div className="form-group mr-1 mb-0">
                                                                                <select
                                                                                    value={item.backgroundColorClass}
                                                                                    className="form-control form-control-sm"
                                                                                    name="section-bg-color"
                                                                                    onChange={(e) => setSectionBackgroundColorClass(e, index)}
                                                                                >
                                                                                    <option value=""> --- {translate.t("BackgroundColor")} ---</option>
                                                                                    <option value="circle"> {translate.t("WithCircle")} </option>
                                                                                    <option value="blue"> {translate.t("BlueBg")} </option>
                                                                                </select>
                                                                            </div>

                                                                            <div className="form-group mr-1 mb-0">
                                                                                <select
                                                                                    value={item.fullWidth}
                                                                                    className="form-control form-control-sm"
                                                                                    name="section-full-width"
                                                                                    onChange={(e) => setSectionFullWidth(e, index)}
                                                                                >
                                                                                    <option value="0"> {translate.t("FixedWidth")} </option>
                                                                                    <option value="1"> {translate.t("FullWidth")} </option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="mr-1">
                                                                                <div
                                                                                    className={`d-flex align-items-center justify-content-center item-container cursor-move`}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <i
                                                                                        className={`las la-arrows-alt-v text-primary`}
                                                                                        title={translate.t("MoveSection")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {index != 0 && (
                                                                                <div className="form-group">
                                                                                    <div className={`item-container`} onClick={() => removeSection(index)}>
                                                                                        <i
                                                                                            className={`las la-times text-danger `}
                                                                                            title={translate.t("RemoveSection")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className={item.fullWidth == 1 ? "container-fluid" : "container"}>
                                                                    <DragDropContext onDragEnd={(result) => onDragEnd(result, "" + index + "")}>
                                                                        <Droppable droppableId="droppable">
                                                                            {(provided, snapshot) => (
                                                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                                    {item.elements &&
                                                                                        item.elements.map((elmItem, elmIndex) => {
                                                                                            return (
                                                                                                <Draggable
                                                                                                    key={`elm-${elmIndex + 1}`}
                                                                                                    draggableId={`elm-${elmIndex + 1}`}
                                                                                                    index={elmIndex}
                                                                                                >
                                                                                                    {(provided, snapshot) => (
                                                                                                        <div
                                                                                                            ref={provided.innerRef}
                                                                                                            {...provided.draggableProps}
                                                                                                        >
                                                                                                            {elmItem.drag_columns && (
                                                                                                                <DragColumnsElement
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    fullWidth={item.fullWidth}
                                                                                                                    element={elmItem.drag_columns}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.text && (
                                                                                                                <TextElement
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.text}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.articles && (
                                                                                                                <ArticlesElement
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.articles}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.banner && (
                                                                                                                <BannerElement
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.banner}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.contact_form && (
                                                                                                                <ContactFormElement
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.contact_form}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.button && (
                                                                                                                <ButtonElement
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.button}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.tournaments && (
                                                                                                                <Tournaments
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.tournaments}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.ladders && (
                                                                                                                <Ladders
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.ladders}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.athletes && (
                                                                                                                <Athletes
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.athletes}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.federationList && (
                                                                                                                <Federations
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.federationList}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.clubList && (
                                                                                                                <Clubs
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.clubList}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                            {elmItem.sportList && (
                                                                                                                <Sports
                                                                                                                    handle={provided.dragHandleProps}
                                                                                                                    element={elmItem.sportList}
                                                                                                                    lastIndexes={index + "-" + elmIndex}
                                                                                                                    index={index}
                                                                                                                />
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Draggable>
                                                                                            );
                                                                                        })}

                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </DragDropContext>
                                                                </div>

                                                                {cmData.showHelpers && (
                                                                    <div className={`cm-add-section`}>
                                                                        <i
                                                                            className={`las la-plus text-primary fs16`}
                                                                            onClick={() => addSection(index)}
                                                                            title={translate.t("AddSection")}
                                                                        />
                                                                    </div>
                                                                )}
                                                                {cmData.showHelpers && <hr className={`cm-hr`} />}
                                                            </section>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
};

export default ContentManager;
