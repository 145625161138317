import React, { Fragment } from "react";
import { removeElement, selectElement, setElementData, showSettings } from "../Library/contentManager";
import { cmDataVar } from "../Library/cmData";
import { useReactiveVar } from "@apollo/client";
import translate from "../../Translations/index";

const Tournaments = ({ lastIndexes, element, handle }) => {
    const cmData = useReactiveVar(cmDataVar);

    console.log(element);
    var parentIndexes = "";

    var indexes = lastIndexes.split("-");
    if (indexes.length > 2) {
        indexes.pop();
        parentIndexes = indexes.join("-");
    }

    return (
        <div className={`cm-elm-content`}>
            {cmData.showHelpers && element.showMenu && (
                <div className={`cm-menu`} onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex">
                        <div className="mr-1">
                            <div className={`item-container`} onClick={() => showSettings("tournaments", lastIndexes)}>
                                <i className={`las la-cog text-primary `} title={translate.t("ModuleSettings")} />
                            </div>
                            {element.showSettings && (
                                <Fragment>
                                    <div className={`settings settings-tournaments`}>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">{translate.t("ShowFilters")}:</div>
                                                </div>
                                                <select
                                                    value={element.showFilters}
                                                    className="form-control form-control-sm"
                                                    name="showFilters"
                                                    onChange={(e) => setElementData(e, lastIndexes, "tournaments")}
                                                >
                                                    <option value="1"> {translate.t("Yes")} </option>
                                                    <option value="0"> {translate.t("No")} </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">{translate.t("Class")}:</div>
                                                </div>
                                                <input
                                                    type="text"
                                                    value={element.className}
                                                    className="form-control form-control-sm"
                                                    name="className"
                                                    onChange={(e) => setElementData(e, lastIndexes, "tournaments")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`cm-closing-overlay`} onClick={() => showSettings("tournaments", lastIndexes)}></div>
                                </Fragment>
                            )}
                        </div>
                        {parentIndexes != "" && (
                            <div className="mr-1">
                                <div
                                    className={`d-flex align-items-center justify-content-center item-container`}
                                    onClick={(e) => selectElement(e, "drag_column", parentIndexes)}
                                >
                                    <i className={`las la-level-up-alt text-secondary `} title={translate.t("ParentElement")} />
                                </div>
                            </div>
                        )}
                        <div className={`d-flex align-items-center justify-content-center mr-1 item-container`} onClick={() => removeElement(lastIndexes)}>
                            <i className={`las la-times text-danger `} title={translate.t("RemoveElement")} />
                        </div>
                        <div className={`cmd-ragable-item`} {...handle}>
                            <div className={`d-flex align-items-center justify-content-center item-container cursor-move`}>
                                <i className={`las la-arrows-alt-v text-primary`} title={translate.t("MoveElement")} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div
                className={`cm-tournaments d-flex flex-column align-items-center justify-content-center`}
                onClick={(e) => {
                    e.stopPropagation();
                    !element.showMenu && selectElement(e, "tournaments", lastIndexes);
                }}
            >
                <div className="title">{translate.t("CompetitionList")}</div>
                <div className="info">{translate.t("TournamentListWillBeShownHere")}</div>
            </div>

            {cmData.showHelpers && <div className={`cm-col-border ${element.showMenu ? `cm-selected` : ""}`}></div>}
        </div>
    );
};

export default Tournaments;
