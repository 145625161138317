/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditDisciplineResult from "./AddEditDisciplineResult";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation } from "@apollo/client";
import { GET_DISCIPLINE_RESULTS, DELETE_DISCIPLINE_RESULTS, GENERATE_RESULTS } from "../Queries/discipline";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";

const DisciplineResultList = (props) => {
    const [selectedDisciplineResults, setSelectedDisciplineResults] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedDisciplineResultID, setSelectedDisciplineResultID] = useState("");
    const [showGenerateResultsNotifi, setShowGenerateResultsNotifi] = useState(false);

    const {
        loading,
        error: getError,
        data: disciplineResultListData,
        fetchMore,
        refetch,
    } = useQuery(GET_DISCIPLINE_RESULTS, {
        variables: {
            disciplineID: props.disciplineID,
        },
        fetchPolicy: "cache-and-network",
    });

    const [deleteDisciplineResult, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_DISCIPLINE_RESULTS, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            refetch();
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const [generateResults, { loading: genHeatsLoading }] = useMutation(GENERATE_RESULTS, {
        onCompleted(data) {
            refetch();
            let notify = new Notification();
            notify.setNotification(translate.t("ResultsSuccessfullyGenerated"));
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const openCloseModal = (open, disciplineResultID) => {
        setSelectedDisciplineResultID(disciplineResultID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteDisciplineResults funkce, která posílá data na server
     * @param {*} disciplineResultID ID disciplineResultu
     */

    const initiateDeleteDisciplineResult = (e, disciplineResultID) => {
        var checked = e.target.checked;
        var arr = [...selectedDisciplineResults];

        if (!checked) {
            for (let i in selectedDisciplineResults) {
                if (selectedDisciplineResults[i] == disciplineResultID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [disciplineResultID, ...arr];
        }

        setSelectedDisciplineResults(arr);
    };

    const delDisciplineResult = (action) => {
        if (action) {
            let disciplineResultIDs = selectedDisciplineResults.join(",");

            deleteDisciplineResult({
                variables: {
                    disciplineResultIDs: disciplineResultIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedDisciplineResults.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };
    const runGenerateResults = (action) => {
        if (action) {
            generateResults({ variables: { disciplineID: props.disciplineID, lang: translate.locale } });
        }
        setShowGenerateResultsNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div>
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("DisciplineResultList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Name")}</div>
                                                <div className="text-center">{translate.t("DisciplineRank")}</div>
                                                <div className="text-center">{translate.t("DisciplineResultTime")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {disciplineResultListData?.disciplineResults?.map((item, index) => {
                                                var checked = false;
                                                for (let i in selectedDisciplineResults) {
                                                    if (selectedDisciplineResults[i] == item.disciplineResultID) checked = true;
                                                }

                                                return (
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.title}</div>
                                                        <div className="text-center">
                                                            {item.rank == 0 ? <span class="badge badge-danger">{translate.t("NotRankedYet")}</span> : item.rank}
                                                        </div>
                                                        <div className="text-center">{item.time}</div>
                                                        <div className="text-right">
                                                            <img
                                                                onClick={() => openCloseModal(true, item.disciplineResultID)}
                                                                className="edit-icon"
                                                                src={edit}
                                                            />
                                                            <input
                                                                className="delete-checked"
                                                                type="checkbox"
                                                                name="deleteDisciplineResult[]"
                                                                checked={checked}
                                                                onChange={(e) => initiateDeleteDisciplineResult(e, item.disciplineResultID)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {!loading && (
                                                <>
                                                    {!disciplineResultListData?.disciplineResults?.length && (
                                                        <>
                                                            <NoItems text={translate.t("NoItemsAddedYet")} />
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <button className="btn btn-primary" onClick={() => setShowGenerateResultsNotifi(true)}>
                                                                    {translate.t("GenerateResultsAutomatically")}
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditDisciplineResult
                    openCloseModal={openCloseModal}
                    disciplineResultID={selectedDisciplineResultID}
                    disciplineID={props.disciplineID}
                    updated={() => refetch()}
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delDisciplineResult} />}
            {showGenerateResultsNotifi && (
                <ModalNotification yesNo={true} text={translate.t("AreYouSureYouWantToGenerateResults?")} callback={runGenerateResults} />
            )}
        </div>
    );
};

export default DisciplineResultList;
