/**
 * soubor se seznamem graphql dotazů
 */
import gql from 'graphql-tag';


/**
 *  Přidá/Upraví tým
 */
export const GET_TEAM_FOR_EDIT = gql`
    query TeamForEdit($hashID:ID,$disciplineHashID:ID){
        team(hashID: $hashID){ 
            hashID
            disciplineHashID
            title
            athletes{
                athleteID
                hashID
                name
                surname
                email
            }
        }
        discipline(hashID:$disciplineHashID){  
            disciplineID
            hashID
            competitionHashID
            ageCategoryID
            title
            athleteCountInTeam
        } 
    }
`;
/**
 *  Přidá/Upraví tým
 */
export const ADD_EDIT_TEAM = gql`
    mutation AddEditTeam($addEditData:AddEditTeamDataInput){
        addEditTeam(addEditData:$addEditData){ 
            hashID
            disciplineHashID
            title
            athletes{
                hashID
                name
                surname
                email
            }
        }
    }
`;
/**
 * Smazání týmu.
 */
export const DELETE_TEAMS = gql`
    mutation DeleteTeams($hashIDs:ID!) {
        deleteTeams(hashIDs:$hashIDs)
    }
`;




