import React, { Component, useEffect, useRef, useState } from "react";
import TinyMceFileManager from "./TinyMceFileManager";
import { Editor } from "@tinymce/tinymce-react";
import { SERVER_URL } from "../../../Config";
import translate from "../../Translations/index";

const TinyMceInlineEditor = (props) => {
    const editorRef = useRef(null);
    const [showManager, setShowManager] = useState(false);
    const [tinyMCECallBack, setTinyMCECallBack] = useState();
    const [tinyMCEFileType, setTinyMCEFileType] = useState();

    return (
        <div>
            <Editor
                tinymceScriptSrc={`${SERVER_URL}/Public/tinymce/tinymce.min.js`}
                onInit={(evt, editor) => (editorRef.current = editor)}
                name="htmlContent"
                value={props.value}
                inline={true}
                min_height={100}
                init={{
                    plugins: "link code lists image autoresize table media",
                    toolbar1:
                        "undo redo bold italic underline strikethrough indent outdent numlist bullist link forecolor removeformat image media table superscript subscript",
                    toolbar2: "h1 h2 h3 h4 h5 h6 alignleft aligncenter alignright alignjustify blockquote pastetext fontsize styles",

                    file_picker_types: "file image media",
                    file_picker_callback: (callback, value, meta) => {
                        if (meta.filetype == "image" || meta.filetype == "file" || meta.filetype == "media") {
                            setShowManager(true);
                            setTinyMCECallBack(() => callback);
                            setTinyMCEFileType(meta.filetype);
                        }
                    },
                    font_size_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 50px 100px 150px 200px 250px",
                    language_url: `${SERVER_URL}/Public/tinymce/langs/cs.js`,
                    language: translate.locale ? translate.locale : "en",
                    image_advtab: true,
                    menubar: false,
                    relative_urls: false,
                    remove_script_host: false,
                    table_default_attributes: {
                        border: "1",
                        borderColor: "#000000",
                    },
                    entity_encoding: "raw",
                    max_height: 900,
                    color_map: ["000000", "Černá", "2a4df4", "Modrá"],
                    content_style: `
                        .circle-photo { border-radius:50%; }   
                    `,
                    formats: {
                        circlePhoto: {
                            selector: "img",
                            attributes: { class: "circle-photo" },
                        },
                    },
                    style_formats: [
                        { title: "Odstavec", format: "p" },
                        { title: "Kulatá fotka", format: "circlePhoto" },
                    ],
                }}
                onEditorChange={(content) => props.onChange(content)}
            />
            {showManager && <TinyMceFileManager callback={() => setShowManager(false)} tinyMCECallBack={tinyMCECallBack} tinyMCEFileType={tinyMCEFileType} />}
        </div>
    );
};

export default TinyMceInlineEditor;
