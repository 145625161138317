import { makeVar } from "@apollo/client";
import { SECTION } from "./elements";

export const initialContentManagerData = {
    showHelpers: true,
    lang: "cs",
    contentWidth: 0,
    contentHeight: 0,
    content: {
        sections: [SECTION],
    },
};

export const cmDataVar = makeVar(initialContentManagerData);
