/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit ladder
 */
export const ADD_EDIT_LADDER = gql`
    mutation AddEditLadder($addEditData: AddEditLadderDataInput) {
        addEditLadder(addEditData: $addEditData) {
            hashID
            title
        }
    }
`;

/**
 * add ladder to discipline
 */
export const ADD_LADDER_TO_DISCIPLINE = gql`
    mutation AddLadderToDiscipline($disciplineHashID: ID, $ladderHashID: ID, $lang: String) {
        addLadderToDiscipline(disciplineHashID: $disciplineHashID, ladderHashID: $ladderHashID, lang: $lang) {
            hashID
            title
            description
        }
    }
`;

/**
 *  Ladder data
 */

export const GET_LADDER = gql`
    query LadderWithLangs($hashID: ID) {
        ladderWithLangs(hashID: $hashID) {
            ladderID
            hashID
            adminUserID
            addedByOrganiserID
            langs {
                lang
                title
                description
            }
        }
        allLanguageMutations(onlyEnabled: true) {
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

/**
 *  Seznam veškerých ladderů
 */
export const GET_LADDERS = gql`
    query AllLadders($filters: LaddersFilters) {
        laddersByRole(filters: $filters) {
            hashID
            ladderID
            title
            addedByOrganiserID
            canEditLadder
            canDeleteLadder
            langs {
                lang
                title
            }
            ownerOrganiser {
                name
            }
            organisers {
                organiserID
                name
            }
        }
        laddersByRoleCount(filters: $filters)
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_LADDERS = gql`
    mutation deleteLadders($hashIDs: ID!) {
        deleteLadders(hashIDs: $hashIDs)
    }
`;
/**
 * Smazání kategorie.
 */
export const DELETE_LADDERS_FROM_DISCIPLINE = gql`
    mutation DeleteLaddersFromDiscipline($disciplineHashID: ID, $hashIDs: ID) {
        deleteLaddersFromDiscipline(disciplineHashID: $disciplineHashID, hashIDs: $hashIDs)
    }
`;

/**
 * Správa uživatelů (admin)
 */

export const ADD_EDIT_LADDER_ORGANISER = gql`
    mutation AddEditLadderOrganiser($addEditData: AddEditLadderOrganiserDataInput) {
        addEditLadderOrganiser(addEditData: $addEditData) {
            ladderOrganiserID
        }
    }
`;
export const GET_LADDER_ORGANISERS = gql`
    query LadderOrganisers($ladderID: ID) {
        ladderOrganisers(ladderID: $ladderID) {
            ladderOrganiserID
            organiserID
            name
            canEditLadder
            canDeleteLadder
        }
    }
`;
export const GET_LADDER_ORGANISER = gql`
    query LadderOrganiser($ladderOrganiserID: ID) {
        ladderOrganiser(ladderOrganiserID: $ladderOrganiserID) {
            ladderOrganiserID
            organiserID
            name
            canEditLadder
            canDeleteLadder
        }
        organisers {
            organiserID
            name
        }
    }
`;
export const DELETE_LADDER_ORGANISERS = gql`
    mutation deleteLadderOrganisers($ladderOrganiserIDs: ID!) {
        deleteLadderOrganisers(ladderOrganiserIDs: $ladderOrganiserIDs)
    }
`;
