/**
 *  komponenta pro štítky pro produkty
 */
import React, { Component, Fragment, useState } from "react";
import AddEditDiscipline from "./AddEditDiscipline";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_DISCIPLINE_TEAMS, GET_REGISTRATION_DATA, OPEN_CLOSE_DISCIPLINE } from "../Queries/discipline";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import DisciplineLib from "../Library/discipline";
import TeamLib from "../Library/team";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import AddEditTeam from "./AddEditTeam";
import { withRouter } from "react-router-dom";
import { DELETE_TEAMS } from "../Queries/team";

const Registration = (props) => {
    //zjistime jestli ma prihlaseny administrator spravna prava
    //const helper = new Helper();
    //const adminUserData = helper.checkAdminRights(props,["1","2","3"]);
    const client = useApolloClient();

    const listVariables = {
        disciplineHashID: props.match.params.disciplineHashID,
    };

    const [selectedTeamHashID, setSelectedTeamHashID] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const {
        loading,
        error: getError,
        data: teamListData,
        fetchMore,
    } = useQuery(GET_DISCIPLINE_TEAMS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
    });

    const {
        loading: getDisciplineLoading,
        error: getDisciplineError,
        data: disciplineData,
        refetch: refetchDiscipline,
    } = useQuery(GET_REGISTRATION_DATA, {
        variables: { competitionHashID: props.match.params.competitionHashID, disciplineHashID: props.match.params.disciplineHashID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
    });

    const [deleteTeams, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_TEAMS, {
        update(cache, response) {
            let teamLib = new TeamLib(this);
            teamLib.updateAfterDelete(cache, response, listVariables);

            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const [openCloseDisciplineRegistration, { data: openCloseData, loading: openCloseLoading, error: openCloseError }] = useMutation(OPEN_CLOSE_DISCIPLINE, {
        update(cache, response) {
            refetchDiscipline();
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, teamHashID) => {
        setSelectedTeamHashID(teamHashID);
        setShowAdd(open);
    };

    /**
     * smazání
     * @param {Event} e event kliku
     * @param {String} hashID ID disciplineu
     */

    const initiateDeleteTeam = (e, hashID) => {
        var checked = e.target.checked;
        var arr = [...selectedTeams];

        if (!checked) {
            for (let i in selectedTeams) {
                if (selectedTeams[i] == hashID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [hashID, ...arr];
        }

        setSelectedTeams(arr);
    };

    const delTeam = (action) => {
        if (action) {
            let hashIDs = selectedTeams.join(",");

            deleteTeams({
                variables: {
                    hashIDs: hashIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedTeams.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };
    const openCloseReg = (disciplineHashID, closed) => {
        openCloseDisciplineRegistration({
            variables: {
                disciplineHashID,
                closed,
            },
        });
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <Fragment>
            <div className="mb-3">
                <div className="d-flex align-items-center discipline-title">
                    {translate.t("Registration")}
                    {disciplineData?.discipline?.registrationClosed ? (
                        <button className="btn btn-danger btn-thiner ml-2" onClick={() => openCloseReg(disciplineData.discipline.hashID, 0)}>
                            {translate.t("OpenRegistration")}
                        </button>
                    ) : (
                        <button className="btn btn-primary btn-thiner ml-2" onClick={() => openCloseReg(disciplineData.discipline.hashID, 1)}>
                            {translate.t("CloseRegistration")}
                        </button>
                    )}
                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                        {translate.t("RegisterAthlete")}
                    </button>
                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                        {translate.t("DeleteSelected")}
                    </button>
                </div>
                <div className="">
                    {!error ? (
                        <div>
                            <div className="data-list">
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div>{disciplineData?.discipline?.athleteCountInTeam > 1 ? translate.t("Team") : translate.t("Athlete")}</div>
                                        <div className="text-right">{translate.t("Options")}</div>
                                    </div>
                                </div>
                                <Fragment>
                                    {teamListData &&
                                        teamListData.allDisciplineTeams &&
                                        teamListData.allDisciplineTeams.map((item, index) => {
                                            var checked = false;
                                            for (let i in selectedTeams) {
                                                if (selectedTeams[i] == item.hashID) checked = true;
                                            }

                                            return (
                                                <div key={index} className="data-list-item-content">
                                                    <div>
                                                        {disciplineData?.discipline?.athleteCountInTeam > 1 ? (
                                                            <>
                                                                <div className={disciplineData?.discipline?.athleteCountInTeam > 1 ? "bold " : " "}>
                                                                    {item.title}
                                                                </div>

                                                                <div className="ml-2">
                                                                    {item.athletes.map((athleteItem, athleteIndex) => {
                                                                        return (
                                                                            <div key={athleteIndex} className="fs12">
                                                                                {athleteItem.name} {athleteItem.surname} ({athleteItem.email})
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className={disciplineData?.discipline?.athleteCountInTeam > 1 ? "bold " : " "}>
                                                                {item.title} {item.athletes[0] && "(" + item.athletes[0].email + ")"}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="text-right">
                                                        <img onClick={() => openCloseModal(true, item.hashID)} className="edit-icon" src={edit} />
                                                        <input
                                                            className="delete-checked"
                                                            type="checkbox"
                                                            name="deleteTeams[]"
                                                            checked={checked}
                                                            onChange={(e) => initiateDeleteTeam(e, item.hashID)}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </Fragment>
                            </div>

                            {!loading ? (
                                <Fragment>
                                    {teamListData && teamListData.allDisciplineTeams && teamListData.allDisciplineTeams.length == 0 ? (
                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                    ) : (
                                        <Fragment>
                                            {!loading && teamListData && teamListData.allDisciplineTeams.length < teamListData.disciplineListCount && (
                                                <div className="text-center p-5">
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() =>
                                                            fetchMore({
                                                                variables: { ...listVariables, offset: teamListData.allDisciplineTeams.length },
                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                    if (!fetchMoreResult) return prev;
                                                                    return Object.assign({}, prev, {
                                                                        allDisciplineTeams: [...prev.allDisciplineTeams, ...fetchMoreResult.allDisciplineTeams],
                                                                    });
                                                                },
                                                            })
                                                        }
                                                    >
                                                        {" "}
                                                        {translate.t("LoadMore")}{" "}
                                                    </button>
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    ) : (
                        <Fragment>{error && <Error text={error} />}</Fragment>
                    )}
                </div>
            </div>
            {showAdd && (
                <AddEditTeam
                    openCloseModal={openCloseModal}
                    disciplineHashID={props.match.params.disciplineHashID}
                    selectedTeamHashID={selectedTeamHashID}
                    listVariables={listVariables}
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delTeam} />}
        </Fragment>
    );
};

export default withRouter(Registration);
