/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import Loading from "../../../GlobalComponents/Loading";
import Modal from "react-modal";
import Notification from "../../../Library/notification";
import NotificationClass from "../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";

import "react-datepicker/dist/react-datepicker.css";
import { ADD_NOTIFICATION } from "../Queries/notification";
import { withRouter } from "react-router-dom";
import { GET_DISCIPLINES } from "../../Discipline/Queries/discipline";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

const SendNotification = (props) => {
    const client = useApolloClient();
    const notificationClass = new NotificationClass(props);
    const [formData, setFormData] = useState({
        competitionHashID: props.match.params.competitionHashID,
        title: "",
        text: "",
        athleteIDs: [],
        sendToGroupID: 0,
        disciplineHashID: "",
        lang: translate.locale,
    });

    const {
        loading,
        error: getError,
        data: disciplineListData,
    } = useQuery(GET_DISCIPLINES, {
        variables: {
            competitionHashID: formData.competitionHashID,
        },
        fetchPolicy: "cache-and-network",
    });
    const [addNotification, { data, loading: addLoading, error: addError }] = useMutation(ADD_NOTIFICATION, {
        onCompleted(data) {
            props.openCloseModal(false);
        },
        update(cache, response) {
            notificationClass.updateList(cache, response, props.listVariables);

            const notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySent"));
        },
        onError(err) {
            const helper = new Helper();
            err = helper.getApolloErrorText(err);
            let notify = new Notification();
            notify.setNotification(err, "danger");
        },
    });

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{translate.t("SendNotification")}</h4>

                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>
                        {!addLoading ? (
                            <Fragment>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("MessageTitle")}</label>
                                            <div className="form-group">
                                                <input
                                                    className="form-control"
                                                    athlete="text"
                                                    name="title"
                                                    value={formData.name}
                                                    onChange={(e) => notificationClass.formHandle(e, formData, setFormData)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("MessageText")}</label>
                                            <div className="form-group">
                                                <textarea
                                                    className="form-control"
                                                    value={formData.text}
                                                    athlete="text"
                                                    name="text"
                                                    onChange={(e) => notificationClass.formHandle(e, formData, setFormData)}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("GroupToSendMessage")}</label>
                                            <div className="form-group">
                                                <select
                                                    value={formData.sendToGroupID}
                                                    className="form-control"
                                                    name="sendToGroupID"
                                                    onChange={(e) => notificationClass.formHandle(e, formData, setFormData)}
                                                >
                                                    <option value={0}>-- {translate.t("SelectSendGroup")} --</option>
                                                    <option value={1}>{translate.t("AllFromCompetition")}</option>
                                                    <option value={2}>{translate.t("AthletesFromDiscipline")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        {formData.sendToGroupID == 2 && (
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Discipline")}</label>
                                                <div className="form-group">
                                                    <select
                                                        value={formData.disciplineHashID}
                                                        className="form-control"
                                                        name="disciplineHashID"
                                                        onChange={(e) => notificationClass.formHandle(e, formData, setFormData)}
                                                    >
                                                        <option value="">-- {translate.t("SelectDiscipline")} --</option>
                                                        {disciplineListData &&
                                                            disciplineListData.allDisciplines &&
                                                            disciplineListData.allDisciplines.map((item, index) => (
                                                                <option key={index} value={item.hashID}>
                                                                    {" "}
                                                                    {item.title}{" "}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <Loading />
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => notificationClass.addNotification(addNotification, formData)}>
                        {translate.t("Send")}
                    </button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default withRouter(SendNotification);
