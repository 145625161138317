/*
    Přidání štítku
*/
import React from "react";
import Modal from "react-modal";
import PrimaryData from "./PrimaryData";
import translate from "../../Translations/index";

const AddEditCompetition = (props) => {
    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg competition-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {props.selectedCompetitionHashID && props.selectedCompetitionHashID != 0
                            ? translate.t("EditCompetition")
                            : translate.t("AddCompetition")}
                    </h4>
                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <PrimaryData
                    inModal={true}
                    openCloseModal={props.openCloseModal}
                    selectedCompetitionHashID={props.selectedCompetitionHashID}
                    setSelectedCompetitionHashID={props.setSelectedCompetitionHashID}
                    listVariables={props.listVariables}
                />
            </div>
        </Modal>
    );
};

export default AddEditCompetition;
