import React, { Fragment } from "react";
import {
    removeBannerImageElement,
    removeElement,
    reorderBannerList,
    selectElement,
    setElementData,
    showSettings,
    uploadBannerImageElement,
} from "../Library/contentManager";
import { cmDataVar } from "../Library/cmData";
import { useReactiveVar } from "@apollo/client";
import TinyMceInlineTextOnlyEditor from "../../TinyMce/Components/TinyMceInlineTextOnlyEditor";
import TinyMceInlineEditor from "../../TinyMce/Components/TinyMceInlineEditor";
import arrowRightBlueIcon from "../../../Public/Images/ContentManager/arrow_right_blue.svg";
import edit from "../../../Public/Images/remove.svg";
import translate from "../../Translations/index";
import { useState } from "react";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const BannerElement = ({ lastIndexes, element, handle }) => {
    const cmData = useReactiveVar(cmDataVar);
    const [selectedBannerIndex, setSelectedBannerIndex] = useState(null);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    console.log(element);
    var parentIndexes = "";

    var indexes = lastIndexes.split("-");
    if (indexes.length > 2) {
        indexes.pop();
        parentIndexes = indexes.join("-");
    }

    const confirmDeleteBanner = (status) => {
        if (status) {
            removeBannerImageElement(lastIndexes, element.imgList, selectedBannerIndex);
        }
        setShowDeleteNotifi(false);
    };

    const markBannerToDelete = (index) => {
        setSelectedBannerIndex(index);
        setShowDeleteNotifi(true);
    };

    return (
        <div className={`cm-elm-content`}>
            {cmData.showHelpers && element.showMenu && (
                <div className={`cm-menu`} onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex">
                        <div className="mr-1">
                            <div className={`item-container`} onClick={() => showSettings("banner", lastIndexes)}>
                                <i className={`las la-cog text-primary `} title={translate.t("ModuleSettings")} />
                            </div>
                            {element.showSettings && (
                                <Fragment>
                                    <div className={`settings settings-banner`}>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">{translate.t("ShowButton")}:</div>
                                                </div>
                                                <select
                                                    value={element.showButton}
                                                    className="form-control form-control-sm"
                                                    name="showButton"
                                                    onChange={(e) => setElementData(e, lastIndexes, "banner")}
                                                >
                                                    <option value="1"> {translate.t("Yes")} </option>
                                                    <option value="0"> {translate.t("No")} </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">{translate.t("ButtonLink")}:</div>
                                                </div>
                                                <input
                                                    type="text"
                                                    value={element.linkUrl}
                                                    className="form-control form-control-sm"
                                                    name="linkUrl"
                                                    onChange={(e) => setElementData(e, lastIndexes, "banner")}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">{translate.t("Class")}:</div>
                                                </div>
                                                <input
                                                    type="text"
                                                    value={element.className}
                                                    className="form-control form-control-sm"
                                                    name="className"
                                                    onChange={(e) => setElementData(e, lastIndexes, "banner")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`cm-closing-overlay`} onClick={() => showSettings("banner", lastIndexes)}></div>
                                </Fragment>
                            )}
                        </div>
                        {parentIndexes != "" && (
                            <div className="mr-1">
                                <div
                                    className={`d-flex align-items-center justify-content-center item-container`}
                                    onClick={(e) => selectElement(e, "drag_column", parentIndexes)}
                                >
                                    <i className={`las la-level-up-alt text-secondary `} title={translate.t("ParentElement")} />
                                </div>
                            </div>
                        )}
                        <div className={`d-flex align-items-center justify-content-center mr-1 item-container`} onClick={() => removeElement(lastIndexes)}>
                            <i className={`las la-times text-danger `} title={translate.t("RemoveElement")} />
                        </div>
                        <div className={`cmd-ragable-item`} {...handle}>
                            <div className={`d-flex align-items-center justify-content-center item-container cursor-move`}>
                                <i className={`las la-arrows-alt-v text-primary`} title={translate.t("MoveElement")} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div
                className={`cm-banner-content`}
                onClick={(e) => {
                    e.stopPropagation();
                    !element.showMenu && selectElement(e, "banner", lastIndexes);
                }}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="container-fluid">
                            <div className="row ">
                                <div className="col col-6 slider-desc-col" rel="0">
                                    <div className="slider-description">
                                        {cmData.showHelpers ? (
                                            <div className={`title edit`}>
                                                <TinyMceInlineEditor
                                                    value={!!element.htmlTitle ? element.htmlTitle : ""}
                                                    onChange={(content, editor) => {
                                                        setElementData(null, lastIndexes, "banner", content, null, "htmlTitle");
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div className={`title`} dangerouslySetInnerHTML={{ __html: element.htmlTitle }}></div>
                                        )}
                                        {cmData.showHelpers ? (
                                            <div className={`text edit`}>
                                                <TinyMceInlineEditor
                                                    value={!!element.htmlText ? element.htmlText : ""}
                                                    onChange={(content, editor) => {
                                                        setElementData(null, lastIndexes, "banner", content, null, "htmlText");
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div className={`text`} dangerouslySetInnerHTML={{ __html: element.htmlText }}></div>
                                        )}
                                        {element.showButton == "1" && (
                                            <>
                                                {cmData.showHelpers ? (
                                                    <div className="d-flex justify-content-start">
                                                        <button className="btn btn-white d-flex align-items-center">
                                                            <TinyMceInlineTextOnlyEditor
                                                                value={!!element.htmlButtonText ? element.htmlButtonText : ""}
                                                                validElements={"p,br"}
                                                                onChange={(content, editor) => {
                                                                    setElementData(
                                                                        null,
                                                                        lastIndexes,
                                                                        "banner",
                                                                        editor.getContent({ format: "text" }),
                                                                        null,
                                                                        "buttonText"
                                                                    );
                                                                    setElementData(null, lastIndexes, "banner", content, null, "htmlButtonText");
                                                                }}
                                                            />{" "}
                                                            <img className="arrow" src={arrowRightBlueIcon} />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex justify-content-start">
                                                        <button className="btn btn-white d-flex align-items-center">
                                                            <span dangerouslySetInnerHTML={{ __html: element.buttonText }}></span>
                                                            <img className="arrow" src={arrowRightBlueIcon} />
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="col d-flex flex-column justify-content-center align-items-center flex-grow-1" rel="1">
                                    {element?.imgList?.length > 0 && (
                                        <div className="cm-banner-img-list d-flex flex-wrap">
                                            <DragDropContext
                                                onDragEnd={(result) =>
                                                    reorderBannerList(lastIndexes, element.imgList, result.source.index, result.destination.index)
                                                }
                                            >
                                                <Droppable droppableId="droppable" direction="horizontal">
                                                    {(provided, snapshot) => (
                                                        <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex">
                                                            {element.imgList.map((img, index) => {
                                                                return (
                                                                    <Draggable key={`banimg-${index + 1}`} draggableId={`banimg-${index + 1}`} index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                className="cm-banner-img mr-2 mb-2"
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <img src={img} alt={`Banner ${index}`} />
                                                                                <div className="cm-banner-img-menu justify-content-center align-items-center">
                                                                                    <img
                                                                                        onClick={() => markBannerToDelete(index)}
                                                                                        className="icon"
                                                                                        src={edit}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                );
                                                            })}

                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                    )}
                                    <div className="mt-4">
                                        <input
                                            type="file"
                                            className={`hidden-input`}
                                            id={`image-new-${lastIndexes}`}
                                            onChange={(e) => uploadBannerImageElement(e, lastIndexes, element.imgList)}
                                        />

                                        <label className={`btn btn-primary`} htmlFor={`image-new-${lastIndexes}`}>
                                            {translate.t("UploadPhoto")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showDeleteNotifi && (
                <ModalNotification yesNo={true} text={translate.t("AreYouSureYouWantToDeleteSelectedBanner")} callback={confirmDeleteBanner} />
            )}
            {cmData.showHelpers && <div className={`cm-col-border ${element.showMenu ? `cm-selected` : ""}`}></div>}
        </div>
    );
};

export default BannerElement;
