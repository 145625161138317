/*
    Přidání štítku
*/
import React, { Component, Fragment, useState } from "react";
import Modal from "react-modal";
import { GET_DISCIPLINE } from "../Queries/discipline";
import { ADD_EDIT_TEAM, GET_TEAM_FOR_EDIT } from "../Queries/team";
import DisciplineLib from "../Library/discipline";
import TeamLib from "../Library/team";
import translate from "../../Translations/index";
import searchIcon from "../../../Public/Images/search_grey.svg";
import AthleteSearchInput from "../../Athlete/Components/AthleteSearchInput";
import removeIcon from "../../../Public/Images/remove.svg";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import { useApolloClient, useQuery, useMutation } from "@apollo/client";

const initialAthleteObject = {
    athleteID: 0,
    name: "",
    surname: "",
    email: "",
};

const AddEditTeam = (props) => {
    const client = useApolloClient();
    const disciplineLib = new DisciplineLib(props);
    const teamLib = new TeamLib(props);

    const [formData, setFormData] = useState({
        selectedLang: "cs",
        hashID: props.selectedTeamHashID,
        disciplineHashID: props.disciplineHashID,
        title: "",
        athletes: [],
        lang: translate.locale,
    });

    const {
        loading: getLoading,
        error: getError,
        data: teamData,
    } = useQuery(GET_TEAM_FOR_EDIT, {
        variables: { hashID: formData.hashID, disciplineHashID: formData.disciplineHashID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.hashID != "") {
                let athletes = [];
                if (data.discipline.athleteCountInTeam >= data.team.athletes.length) {
                    for (let i = 0; i < data.discipline.athleteCountInTeam; i++) {
                        if (data.team.athletes[i]) {
                            athletes.push({ ...data.team.athletes[i] });
                        } else {
                            athletes.push({ ...initialAthleteObject });
                        }
                    }
                } else {
                    athletes = [...data.team.athletes];
                }
                setFormData({ ...formData, title: data.team.title, athletes: athletes });
            } else {
                let athletes = [];
                for (let i = 0; i < data.discipline.athleteCountInTeam; i++) {
                    athletes.push({ ...initialAthleteObject });
                }
                setFormData({ ...formData, athletes });
            }
        },
    });

    const [addTeam, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_TEAM, {
        onCompleted(data) {
            props.openCloseModal(false);
        },
        update(cache, response) {
            teamLib.updateList(cache, response, formData.hashID, props.listVariables);

            let notify = new Notification();
            if (formData.hashID && formData.hashID != 0) notify.setNotification(translate.t("SuccessfullySaved"));
            else notify.setNotification(translate.t("SuccessfullyAdded"));
        },
        onError(err) {
            const helper = new Helper();
            err = helper.getApolloErrorText(err);
            let notify = new Notification();
            notify.setNotification(err, "danger");
        },
    });

    const athleteSelected = (athlete, index) => {
        let athletes = [...formData.athletes];
        athletes[index] = athlete;
        setFormData({ ...formData, athletes });
    };
    const removeItem = (index) => {
        let athletes = [...formData.athletes];
        athletes[index] = initialAthleteObject;
        setFormData({ ...formData, athletes });
    };

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md discipline-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content add-team">
                <div className="modal-header d-flex align-items-center">
                    {formData.athletes.length > 1 ? (
                        <h4 className="modal-title">{formData.hashID == "" ? translate.t("RegisterTeam") : translate.t("EditTeam")}</h4>
                    ) : (
                        <h4 className="modal-title">{translate.t("RegisterAthlete")}</h4>
                    )}
                    <button discipline="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>
                        {formData.athletes.length > 1 && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="input-label">*{translate.t("TeamTitle")}</label>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                discipline="text"
                                                name="title"
                                                value={formData.title}
                                                onChange={(e) => disciplineLib.formHandle(e, formData, setFormData)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            {formData.athletes.map((item, index) => {
                                return (
                                    <div className="col-12 mb-3" key={index}>
                                        {item.athleteID == 0 ? (
                                            <div className="d-flex flex-row align-items-center">
                                                <label className="input-label mr-3 mb-0 text-nowrap">
                                                    *{translate.t("SearchAthlete")} {index + 1}:
                                                </label>
                                                <div className="flex-fill">
                                                    <AthleteSearchInput athleteSelected={(athlete) => athleteSelected(athlete, index)} />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="d-flex flex-row align-items-center">
                                                <label className="input-label mr-3 mb-0 text-nowrap">
                                                    *{translate.t("Athlete")} {index + 1}:
                                                </label>
                                                <div className="flex-fill text-truncate">
                                                    <div className="d-flex align-items-center hp-46">
                                                        <div
                                                            className="selected-athlete flex-fill text-truncate"
                                                            title={item.name + " " + item.surname + "(" + item.email + ")"}
                                                        >
                                                            <div className="text-truncate">
                                                                {item.name} {item.surname} ({item.email})
                                                            </div>
                                                        </div>
                                                        <img
                                                            className="hp-15 athlete-search-icon cursor-pointer ml-2"
                                                            onClick={(e) => removeItem(index)}
                                                            src={removeIcon}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        discipline="button"
                        className="btn btn-primary flex-fill"
                        onClick={() => teamLib.addTeam(addTeam, formData, teamData.discipline.athleteCountInTeam)}
                    >
                        {formData.hashID && formData.hashID != "" ? translate.t("Edit") : translate.t("Add")}
                    </button>
                    <button discipline="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddEditTeam;
