/*
    routování aplikace
*/
import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "../Modules/Auth/Components/Login";
import Layout from "../Layout/Layout.js";
import NoMatch from "./NoMatch";

class Routes extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Login}></Route>
                    <Route path="/:l" component={Layout}></Route>

                    <Route component={NoMatch} />
                </Switch>
            </BrowserRouter>
        );
    }
}
export default Routes;
