import React, { Component, Fragment } from "react";
import { ApolloProvider } from "@apollo/client";
import withTranslation from "./Modules/Translations/withTranslation";
import { ADMIN_USER } from "./GlobalQueries/globalQueries";
import Loading from "./GlobalComponents/Loading";

// routování url adres dsf dsfg dfg dg df gdfg dfg df gdfg dfg rfasdf asdf asdf asd fassad
import Routes from "./Routes/index.js";

import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "react-notifications-component/dist/theme.css";
import { ReactNotifications } from "react-notifications-component";
import { Tooltip } from "react-tooltip";
import { getApolloInstance } from "./Library/apolloClient.js";
const client = getApolloInstance();

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.getAdminUserData();
    }

    getAdminUserData = async () => {
        const data = await client.query({
            query: ADMIN_USER,
            errorPolicy: "all",
            fetchPolicy: "network-only",
        });
        this.setState({ loading: false });
    };

    render() {
        return (
            <Fragment>
                {!this.state.loading ? (
                    <ApolloProvider client={client}>
                        <Routes />
                        <Tooltip id="simple-600" className="ub-tooltip max-width-600" />
                    </ApolloProvider>
                ) : (
                    <Loading />
                )}
                <ReactNotifications />
            </Fragment>
        );
    }
}

export default withTranslation(App, client);
