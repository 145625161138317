/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import { EDIT_COMPETITION_INVITATION_LETTER_TEXT, GET_COMPETITION_INVITATION_LETTER_TEXT } from "../Queries/competition";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import { Editor } from "@tinymce/tinymce-react";
import { withRouter } from "react-router-dom";

import { ADMIN_USER } from "../../../GlobalQueries/globalQueries";

const InvitationLetter = (props) => {
    const client = useApolloClient();
    const adminUserData = client.readQuery({ query: ADMIN_USER });

    const [selectedLang, setSelectedLang] = useState("cs");
    const [formData, setFormData] = useState({
        hashID: props.selectedCompetitionHashID,
        invitationLetterText: "",
        lang: translate.locale,
    });

    const { loading: getLoading } = useQuery(GET_COMPETITION_INVITATION_LETTER_TEXT, {
        variables: { hashID: formData.hashID, lang: translate.locale, sportID: props.sportID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.hashID != "") {
                setFormData({
                    ...formData,
                    invitationLetterText: data.competition.invitationLetterText,
                });
            }
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(err.message ? err.message : err.toString(), "danger");
        },
    });

    const [editCompetitionInvitationLetterText, { data, loading: addLoading, error: addError }] = useMutation(EDIT_COMPETITION_INVITATION_LETTER_TEXT, {
        onCompleted(data) {
            //pokud pridavame novy zavod, tak po pridani presmerujeme do detailu
            if (!formData.hashID) {
                props.history.push("/competition/" + data.addEditCompetition.hashID + "/primary-data");
            }
        },
        update(cache, response) {
            let notify = new Notification();
            if (formData.hashID) notify.setNotification(translate.t("SuccessfullySaved"));
            else notify.setNotification(translate.t("SuccessfullyAdded"));
        },
        onError(err) {
            const helper = new Helper();
            err = helper.getApolloErrorText(err);
            let notify = new Notification();
            notify.setNotification(err, "danger");
        },
    });

    const handleSubmit = () => {
        editCompetitionInvitationLetterText({
            variables: { competitionHashID: formData.hashID, invitationLetterText: formData.invitationLetterText, lang: translate.locale },
        });
    };

    return (
        <Fragment>
            <div>
                <div>
                    {!getLoading ? (
                        <Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <Editor
                                                tinymceScriptSrc="/tinymce/tinymce.min.js"
                                                name="perex"
                                                value={formData.invitationLetterText}
                                                init={{
                                                    promotion: false,
                                                    plugins: "link image code autoresize",
                                                    toolbar: "undo redo bold italic alignleft aligncenter alignright code",
                                                    min_height: 400,
                                                    max_height: 800,
                                                }}
                                                onEditorChange={(content) => setFormData({ ...formData, invitationLetterText: content })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
            <div className={"d-flex " + (props.inModal ? "modal-footer" : "")}>
                <button
                    competition="button"
                    className={"btn btn-primary " + (!props.inModal ? "ml-auto" : "flex-fill")}
                    onClick={() => handleSubmit()}
                    disabled={addLoading}
                >
                    {formData.hashID && formData.hashID != 0 ? translate.t("Edit") : translate.t("Add")}
                </button>
            </div>
        </Fragment>
    );
};

export default withRouter(InvitationLetter);
