/**
 *  komponenta pro štítky pro produkty
 */
import React, { Component, Fragment, useState } from "react";
import AddEditCompetitionSponsor from "./AddEditCompetitionSponsor";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_COMPETITION_SPONSORS, DELETE_COMPETITION_SPONSORS } from "../Queries/competitionSponsor";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import { withRouter, NavLink } from "react-router-dom";
import { COMPETITION_SPONSOR_IMAGE_URL, SERVER_URL } from "../../../Config";

const CompetitionSponsors = (props) => {
    const client = useApolloClient();

    const listVariables = {
        competitionID: props.competitionID,
    };

    const [selectedCompetitionSponsors, setSelectedCompetitionSponsors] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedCompetitionSponsorID, setSelectedCompetitionSponsorID] = useState(0);
    const {
        loading,
        error: getError,
        data: competitionSponsorListData,
        fetchMore,
        refetch,
    } = useQuery(GET_COMPETITION_SPONSORS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
    });

    const [deleteCompetitionSponsor, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_COMPETITION_SPONSORS, {
        update(cache, response) {
            refetch();

            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, competitionSponsorID) => {
        setSelectedCompetitionSponsorID(competitionSponsorID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteCompetitionSponsors funkce, která posílá data na server
     * @param {*} competitionSponsorHashID hash ID discipliny
     */

    const initiateDeleteCompetitionSponsor = (e, competitionSponsorHashID) => {
        var checked = e.target.checked;
        var arr = [...selectedCompetitionSponsors];

        if (!checked) {
            for (let i in selectedCompetitionSponsors) {
                if (selectedCompetitionSponsors[i] == competitionSponsorHashID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [competitionSponsorHashID, ...arr];
        }

        setSelectedCompetitionSponsors(arr);
    };

    const delCompetitionSponsor = (action) => {
        if (action) {
            let competitionSponsorIDs = selectedCompetitionSponsors.join(",");

            deleteCompetitionSponsor({
                variables: {
                    competitionSponsorIDs: competitionSponsorIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedCompetitionSponsors.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div>
            <div className="mb-3">
                <div className="d-flex align-items-center mb-3">
                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                        {translate.t("Add")}
                    </button>
                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                        {translate.t("DeleteSelected")}
                    </button>
                </div>
                <div className="">
                    {!error ? (
                        <div>
                            <div className="data-list">
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div>{translate.t("CompetitionSponsorTitle")}</div>
                                        <div className="text-center">{translate.t("SponsorPhoto")}</div>
                                        <div className="text-right">{translate.t("Options")}</div>
                                    </div>
                                </div>
                                <Fragment>
                                    {competitionSponsorListData &&
                                        competitionSponsorListData.competitionSponsors &&
                                        competitionSponsorListData.competitionSponsors.map((item, index) => {
                                            var checked = false;
                                            for (let i in selectedCompetitionSponsors) {
                                                if (selectedCompetitionSponsors[i] == item.competitionSponsorID) checked = true;
                                            }

                                            return (
                                                <div key={index} className="data-list-item-content">
                                                    <div>{item.name}</div>
                                                    <div className="text-center">
                                                        {!!item.photo && (
                                                            <img
                                                                style={{ maxWidth: "200px", maxHeight: "70px" }}
                                                                src={SERVER_URL + "/" + COMPETITION_SPONSOR_IMAGE_URL + "/stredni_" + item.photo}
                                                            />
                                                        )}
                                                    </div>

                                                    <div className="text-right">
                                                        <img onClick={() => openCloseModal(true, item.competitionSponsorID)} className="edit-icon" src={edit} />
                                                        <input
                                                            className="delete-checked"
                                                            type="checkbox"
                                                            name="deleteFederation[]"
                                                            checked={checked}
                                                            onChange={(e) => initiateDeleteCompetitionSponsor(e, item.competitionSponsorID)}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </Fragment>
                            </div>

                            {!loading ? (
                                <Fragment>
                                    {competitionSponsorListData &&
                                    competitionSponsorListData.competitionSponsors &&
                                    competitionSponsorListData.competitionSponsors.length == 0 ? (
                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                    ) : (
                                        <Fragment>
                                            {!loading &&
                                                competitionSponsorListData &&
                                                competitionSponsorListData.competitionSponsors.length <
                                                    competitionSponsorListData.competitionSponsorListCount && (
                                                    <div className="text-center p-5">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() =>
                                                                fetchMore({
                                                                    variables: {
                                                                        ...listVariables,
                                                                        offset: competitionSponsorListData.competitionSponsors.length,
                                                                    },
                                                                    updateQuery: (prev, { fetchMoreResult }) => {
                                                                        if (!fetchMoreResult) return prev;
                                                                        return Object.assign({}, prev, {
                                                                            competitionSponsors: [
                                                                                ...prev.competitionSponsors,
                                                                                ...fetchMoreResult.competitionSponsors,
                                                                            ],
                                                                        });
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            {" "}
                                                            {translate.t("LoadMore")}{" "}
                                                        </button>
                                                    </div>
                                                )}
                                        </Fragment>
                                    )}
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    ) : (
                        <Fragment>{error && <Error text={error} />}</Fragment>
                    )}
                </div>
            </div>
            {showAdd && (
                <AddEditCompetitionSponsor
                    openCloseModal={openCloseModal}
                    competitionSponsorID={selectedCompetitionSponsorID}
                    competitionID={props.competitionID}
                    sportID={props.sportID}
                    updated={refetch}
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delCompetitionSponsor} />}
        </div>
    );
};

export default withRouter(CompetitionSponsors);
