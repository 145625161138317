/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_ORGANISER_CONTACT, GET_ORGANISER_CONTACT } from "../Queries/organiser";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";

const AddEditOrganiserContact = (props) => {
    const [formData, setFormData] = useState({
        organiserContactID: props.organiserContactID,
        organiserID: props.organiserID,
        name: "",
        email: "",
        phone: "",
    });

    const {
        loading: getLoading,
        error: getError,
        data: organiserContactData,
    } = useQuery(GET_ORGANISER_CONTACT, {
        variables: { organiserContactID: formData.organiserContactID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.organiserContactID != "") {
                setFormData({
                    ...formData,
                    name: data.organiserContact.name,
                    email: data.organiserContact.email,
                    phone: data.organiserContact.phone,
                });
            }
        },
    });

    const [addOrganiserContact, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_ORGANISER_CONTACT, {
        onCompleted(data) {
            setFormData({ ...formData, organiserContactID: data.addEditOrganiserContact.organiserContactID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addOrganiserContactData = () => {
        if (!!formData.name && !!formData.email && !!formData.phone) {
            let dataToSend = {
                ...formData,
            };
            addOrganiserContact({
                variables: {
                    addEditData: dataToSend,
                },
            });
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    const changeSelectedSports = (item) => {
        if (formData.sportIDs.includes(item.sportID)) {
            setFormData({ ...formData, sportIDs: [...formData.sportIDs.filter((filterItem) => filterItem != item.sportID)] });
        } else {
            formData.sportIDs.push(item.sportID);
            setFormData({ ...formData, sportIDs: [...formData.sportIDs] });
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md organiserContact-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {formData.organiserContactID && formData.organiserContactID != ""
                            ? translate.t("EditOrganiserContact")
                            : translate.t("AddOrganiserContact")}
                    </h4>

                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("OrganiserContactTitle")}</label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("OrganiserContactEmail")}</label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("OrganiserContactPhone")}</label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-4">
                                        <button type="button" className="btn btn-primary mr-2" onClick={() => addOrganiserContactData()}>
                                            {formData.organiserContactID && formData.organiserContactID != "" ? translate.t("Edit") : translate.t("Add")}
                                        </button>
                                        <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                                            {translate.t("Close")}
                                        </button>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddEditOrganiserContact;
