import { CONTENT_MANAGER_IMAGE_URL, SERVER_URL } from "../../../Config/index";
import { cmDataVar } from "./cmData";
import {
    TEXT,
    BUTTON,
    DRAG_COLUMN,
    DRAG_COLUMNS,
    ELEMENTS_LIST,
    HEADLINE,
    HTML_ELM,
    IMAGE,
    NEWSLETTER,
    WORK_IN_NUMBERS,
    SOCIAL_NETWORKS,
    PARAGRAPH,
    SECTION,
    VIDEO,
    TOURNAMENTS,
    ARTICLES,
    BANNER,
    INFO_BORDERED_BLOCKS,
    INFO_BORDERED_BLOCK,
    CONTACT_FORM,
    LADDERS,
    ATHLETES,
    FEDERATION_LIST,
    CLUB_LIST,
    SPORT_LIST,
} from "./elements";
import { REMOVE_CM_IMAGE, UPLOAD_CM_IMAGE } from "../Queries/contentManager";
import client from "../../../Library/apolloClient";

/**
 * Přidání elementu do sekce nebo sloupce
 * @param {String} element název elementu, který se přidává
 * @param {String} index index sekce nebo sloupce do které se vkládá element (index je string několika čísel oddělených spojovníkem)
 */

export function addElementTo(element, index) {
    switch (element) {
        case "drag_columns":
            addElement(index, DRAG_COLUMNS);
            break;
        case "text":
            addElement(index, TEXT);
            break;
        case "image":
            addElement(index, IMAGE);
            break;
        case "tournaments":
            addElement(index, TOURNAMENTS);
            break;
        case "ladders":
            addElement(index, LADDERS);
            break;
        case "athletes":
            addElement(index, ATHLETES);
            break;
        case "federationList":
            addElement(index, FEDERATION_LIST);
            break;
        case "clubList":
            addElement(index, CLUB_LIST);
            break;
        case "sportList":
            addElement(index, SPORT_LIST);
            break;
        case "article":
            addElement(index, ARTICLES);
            break;
        case "banner":
            addElement(index, BANNER);
            break;
        case "info_bordered_blocks":
            addElement(index, INFO_BORDERED_BLOCKS);
            break;
        case "headline":
            addElement(index, HEADLINE(cmDataVar().allLanguages));
            break;
        case "paragraph":
            addElement(index, PARAGRAPH(cmDataVar().allLanguages));
            break;
        case "button":
            addElement(index, BUTTON);
            break;
        case "video":
            addElement(index, VIDEO(cmDataVar().allLanguages));
            break;
        case "articles":
            addElement(index, ARTICLES);
            break;
        case "newsletter":
            addElement(index, NEWSLETTER);
            break;
        case "work_in_numbers":
            addElement(index, WORK_IN_NUMBERS);
            break;
        case "social_networks":
            addElement(index, SOCIAL_NETWORKS);
            break;
        case "contact_form":
            addElement(index, CONTACT_FORM);
            break;
        case "html":
            addElement(index, HTML_ELM(cmDataVar().allLanguages));
            break;
    }
}

/**
 * Přidání elementu
 * @param {String} index index sekce nebo sloupce, kde se element vkládá (index je string několika čísel oddělených spojovníkem)
 * @param {String} element název lementu, který se přidává
 */

export function addElement(index, element) {
    hideAllMenus().then(() => {
        index = index.split("-");

        if (index.length == 1) {
            var arr = [...cmDataVar().content.sections];
            arr[index[0]] = { ...arr[index[0]], showAddElements: false, elements: [...arr[index[0]].elements, element] };

            cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
        } else {
            var arr = [...cmDataVar().content.sections];
            arr[index[0]] = recursiveAddElement(index, arr[index[0]], 1, element);
            cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
        }
    });
}

/**
 * POkud se element přidává do zanořeých sloupců, tak pro zanořování se používá tato metoda
 * @param {String} index index sekce nebo sloupce, kde se element vkládá (index je string několika čísel oddělených spojovníkem)
 * @param {Array} arrPrevious předchozí pole
 * @param {Int} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
 * @param {String} element název elementu, který se přidává
 */

export function recursiveAddElement(index, arrPrevious, count, element) {
    var arr = [...arrPrevious.elements];
    var ar = [...arr[index[count]]["drag_columns"].cols];

    if (count + 2 == index.length) {
        ar[index[count + 1]] = { ...ar[index[count + 1]], showAddElements: false, elements: [...ar[index[count + 1]].elements, element] };
        arr[index[count]] = { ...arr[index[count]], ["drag_columns"]: { ...arr[index[count]]["drag_columns"], cols: ar } };

        return { ...arrPrevious, elements: arr };
    } else {
        ar[index[count + 1]] = recursiveAddElement(index, ar[index[count + 1]], count + 2, element);
        arr[index[count]] = { ...arr[index[count]], ["drag_columns"]: { ...arr[index[count]]["drag_columns"], cols: ar } };

        return { ...arrPrevious, elements: arr };
    }
}

/**
 * možnost zobrazit dropdown menu s jednotlivými elemety, které chceme přidat buď v sekci nebo ve sloupcích
 * @param {String} index index sekce nebo sloupce (index je string několika čísel oddělených spojovníkem)
 */

export function showAddElements(index) {
    index = index.split("-");

    if (index.length == 1) {
        var arr = [...cmDataVar().content.sections];
        arr[index[0]] = { ...arr[index[0]], showAddElements: !cmDataVar().content.sections[index[0]].showAddElements };

        cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
    } else {
        var arr = [...cmDataVar().content.sections];
        arr[index[0]] = recursiveShowAddElement(index, 1, arr[index[0]]);

        cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
    }
}

/**
 * Pokud chceme zobrazit dropdown menu v zanořených sloupcích, použijeme rekurzi
 * @param {String} index index sekce nebo sloupce (index je string několika čísel oddělených spojovníkem)
 * @param {Int} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
 * @param {Array} arr předchozí pole
 */

export function recursiveShowAddElement(index, count, arr) {
    if (count + 2 == index.length) {
        var a = [...arr.elements];
        var ar = [...arr.elements[index[count]]["drag_columns"].cols];

        ar[index[count + 1]] = { ...ar[index[count + 1]], showAddElements: !ar[index[count + 1]].showAddElements };
        a[index[count]] = { ...a[index[count]], ["drag_columns"]: { ...a[index[count]]["drag_columns"], cols: ar } };

        return { ...arr, elements: a };
    } else {
        var a = [...arr.elements];
        var ar = [...arr.elements[index[count]]["drag_columns"].cols];

        ar[index[count + 1]] = recursiveShowAddElement(index, count + 2, ar[index[count + 1]]);
        a[index[count]] = { ...a[index[count]], ["drag_columns"]: { ...a[index[count]]["drag_columns"], cols: ar } };

        return { ...arr, elements: a };
    }
}

/**
 * Přidání nové sekce
 * @param {Int} index index sekce za kterou se má vložit nová sekce
 * @param {Object} cmParams state object content manageru
 * @param {Function} cmParams state funkce pro nastavení cmParams objektu
 */
export function addSection(index, cmParams, setCmParams) {
    const arr = [...cmDataVar().content.sections];
    arr.splice(index + 1, 0, SECTION);
    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * schová veškeré menu všech elementů
 */
export function hideAllMenus() {
    return new Promise((resolve, rejected) => {
        var sections = [...cmDataVar().content.sections];

        for (var i in sections) {
            if (sections[i].elements.length > 0) {
                for (var j = 0; j < sections[i].elements.length; j++) {
                    for (let el of ELEMENTS_LIST) {
                        if (sections[i].elements[j][el]) {
                            //console.log(sections[i].elements[j][el]);
                            sections[i].elements[j] = { ...sections[i].elements[j], [el]: { ...sections[i].elements[j][el], showMenu: false } };
                            if (["columns", "drag_columns", "info_bordered_blocks"].includes(el)) {
                                sections[i].elements[j][el].cols = recursiveHideAllMenus(sections[i].elements[j][el].cols);
                            }
                        }
                    }
                }
            }
        }

        cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: sections } });
        resolve();
    });
}

/**
 * Rekurentně schová všechny menu ve sloupcích
 * @param {Array} arr pole ve sloupcích
 */
export function recursiveHideAllMenus(arr) {
    var elem = [];
    var ar = [...arr];

    for (var j = 0; j < ar.length; j++) {
        ar[j] = { ...ar[j], showMenu: false };
        if (ar[j].elements) {
            elem = [...ar[j].elements];
            if (elem.length > 0) {
                for (var i = 0; i < elem.length; i++) {
                    for (let el of ELEMENTS_LIST) {
                        if (elem[i][el]) {
                            elem[i] = { ...elem[i], [el]: { ...elem[i][el], showMenu: false } };
                            if (["columns", "drag_columns", "info_bordered_blocks"].includes(el)) {
                                elem[i][el].cols = recursiveHideAllMenus(elem[i][el].cols);
                            }
                        }
                    }
                }
                ar[j] = { ...ar[j], elements: elem };
            }
        }
    }

    return ar;
}

/**
 * Vloží sloupec do elementu "seřazený sloupec"
 * @param {String} lastIndexes index sloupce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 */

export function addDragColumn(lastIndexes) {
    lastIndexes = lastIndexes.split("-");

    var arr = [...cmDataVar().content.sections];
    arr = recursiveAddDragColumn(lastIndexes, arr, 0);

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * Rekurentně vloží sloupec do elementu "seřazený sloupec"
 * @param {*} index index sloupce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 * @param {*} arrPrevious předchozí pole do kterého se vše ukládá
 * @param {*} count počet podle kterého se vyýbírá z indexu hodnota, na které se nachází element
 * @param {*} colsArr sloupce, které se vloží do elementu
 */

export function recursiveAddDragColumn(index, arrPrevious, count) {
    if (count + 2 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements][index[count + 1]].drag_columns.cols;

        var cols;
        if (!a) cols = [DRAG_COLUMN];
        else cols = [...a, DRAG_COLUMN];

        ar[index[count + 1]] = { ...ar[index[count + 1]], drag_columns: { ...ar[index[count + 1]].drag_columns, cols: cols } };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]].drag_columns.cols];

        ar[index[count + 1]] = {
            ...ar[index[count + 1]],
            drag_columns: { ...ar[index[count + 1]].drag_columns, cols: recursiveAddDragColumn(index, a, count + 2) },
        };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    }
}

export function removeDragColumn(lastIndexes, colIndex) {
    lastIndexes = lastIndexes.split("-");

    var arr = [...cmDataVar().content.sections];
    arr = recursiveRemoveDragColumn(lastIndexes, arr, 0, colIndex);

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

export function recursiveRemoveDragColumn(index, arrPrevious, count, colIndex) {
    if (count + 2 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements][index[count + 1]].drag_columns.cols;

        var cols = [...a.slice(0, colIndex), ...a.slice(colIndex + 1)];

        ar[index[count + 1]] = { ...ar[index[count + 1]], drag_columns: { ...ar[index[count + 1]].drag_columns, cols: cols } };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]].drag_columns.cols];

        ar[index[count + 1]] = {
            ...ar[index[count + 1]],
            drag_columns: { ...ar[index[count + 1]].drag_columns, cols: recursiveRemoveDragColumn(index, a, count + 2, colIndex) },
        };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    }
}

/**
 * Upraví pořadí sloupce
 * @param {Event} e ukazatel na selectbox ve kterém se vybírá počet sloupců
 * @param {String} lastIndexes index sloupce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 * @param {Int} oldPosition Předchozí pozice sloupce
 */

export function changeDragColPosition(e, lastIndexes, oldPosition) {
    var newPosition = e.target.value;

    lastIndexes = lastIndexes.split("-");

    var arr = [...cmDataVar().content.sections];
    arr = recursiveChangeDragColPosition(lastIndexes, arr, 0, newPosition, oldPosition);

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * Rekurentně vloží sloupec do elementu "seřazený sloupec"
 * @param {String} index index sloupce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 * @param {Array} arrPrevious předchozí pole do kterého se vše ukládá
 * @param {Int} count počet podle kterého se vyýbírá z indexu hodnota, na které se nachází element
 * @param {Array} colsArr sloupce, které se vloží do elementu
 * @param {Int} newPosition nová pozice sloupce
 * @param {Int} oldPosition předchozí pozice sloupce
 */

export function recursiveChangeDragColPosition(index, arrPrevious, count, newPosition, oldPosition) {
    if (count + 2 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements][index[count + 1]].drag_columns.cols;

        var cols = [...a];
        const col = cols[oldPosition - 1];

        cols = [...cols.slice(0, oldPosition - 1), ...cols.slice(oldPosition)];
        cols = [...cols.slice(0, newPosition - 1), col, ...cols.slice(newPosition - 1)];

        ar[index[count + 1]] = { ...ar[index[count + 1]], drag_columns: { ...ar[index[count + 1]].drag_columns, cols: cols } };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]].drag_columns.cols];

        ar[index[count + 1]] = {
            ...ar[index[count + 1]],
            drag_columns: {
                ...ar[index[count + 1]].drag_columns,
                cols: recursiveChangeDragColPosition(index, a, count + 2, newPosition, oldPosition),
            },
        };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    }
}

/**
 * Vloží blok do elementu "Ohraničené info bloky"
 * @param {String} lastIndexes index bloku v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 */

export function addInfoBorderedBlock(lastIndexes) {
    lastIndexes = lastIndexes.split("-");

    var arr = [...cmDataVar().content.sections];
    arr = recursiveAddInfoBorderedBlock(lastIndexes, arr, 0);

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * Rekurentně vloží blok do elementu "Ohraničené info bloky"
 * @param {*} index index bloku v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 * @param {*} arrPrevious předchozí pole do kterého se vše ukládá
 * @param {*} count počet podle kterého se vyýbírá z indexu hodnota, na které se nachází element
 */

export function recursiveAddInfoBorderedBlock(index, arrPrevious, count) {
    if (count + 2 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements][index[count + 1]].info_bordered_blocks.cols;

        var cols;
        if (!a) cols = [INFO_BORDERED_BLOCK];
        else cols = [...a, INFO_BORDERED_BLOCK];

        ar[index[count + 1]] = { ...ar[index[count + 1]], info_bordered_blocks: { ...ar[index[count + 1]].info_bordered_blocks, cols: cols } };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]]["drag_columns"].cols];

        ar[index[count + 1]] = {
            ...ar[index[count + 1]],
            ["drag_columns"]: { ...ar[index[count + 1]]["drag_columns"], cols: recursiveAddInfoBorderedBlock(index, a, count + 2) },
        };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    }
}
export function removeInfoBorderedBlock(lastIndexes, colIndex) {
    lastIndexes = lastIndexes.split("-");

    var arr = [...cmDataVar().content.sections];
    arr = recursiveRemoveInfoBorderedBlock(lastIndexes, arr, 0, colIndex);

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

export function recursiveRemoveInfoBorderedBlock(index, arrPrevious, count, colIndex) {
    if (count + 2 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements][index[count + 1]].info_bordered_blocks.cols;

        var cols = [...a.slice(0, colIndex), ...a.slice(colIndex + 1)];

        ar[index[count + 1]] = { ...ar[index[count + 1]], info_bordered_blocks: { ...ar[index[count + 1]].info_bordered_blocks, cols: cols } };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]]["drag_columns"].cols];

        ar[index[count + 1]] = {
            ...ar[index[count + 1]],
            ["drag_columns"]: { ...ar[index[count + 1]]["drag_columns"], cols: recursiveRemoveInfoBorderedBlock(index, a, count + 2, colIndex) },
        };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    }
}

/**
 * Upraví pořadí Ohraničeného info bloku
 * @param {Event} e ukazatel na selectbox ve kterém se vybírá počet sloupců
 * @param {String} lastIndexes index sloupce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 * @param {Int} oldPosition Předchozí pozice sloupce
 */

export function changeInfoBorderedBlockPosition(e, lastIndexes, oldPosition) {
    var newPosition = e.target.value;

    lastIndexes = lastIndexes.split("-");

    var arr = [...cmDataVar().content.sections];
    arr = recursiveChangeInfoBorderedBlockPosition(lastIndexes, arr, 0, newPosition, oldPosition);

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * Rekurentně najde a upraví pořadí elementu "Ohraničený info blok"
 * @param {String} index index sloupce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 * @param {Array} arrPrevious předchozí pole do kterého se vše ukládá
 * @param {Int} count počet podle kterého se vyýbírá z indexu hodnota, na které se nachází element
 * @param {Array} colsArr sloupce, které se vloží do elementu
 * @param {Int} newPosition nová pozice sloupce
 * @param {Int} oldPosition předchozí pozice sloupce
 */

export function recursiveChangeInfoBorderedBlockPosition(index, arrPrevious, count, newPosition, oldPosition) {
    if (count + 2 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements][index[count + 1]].info_bordered_blocks.cols;

        var cols = [...a];
        const col = cols[oldPosition - 1];

        cols = [...cols.slice(0, oldPosition - 1), ...cols.slice(oldPosition)];
        cols = [...cols.slice(0, newPosition - 1), col, ...cols.slice(newPosition - 1)];

        ar[index[count + 1]] = { ...ar[index[count + 1]], info_bordered_blocks: { ...ar[index[count + 1]].info_bordered_blocks, cols: cols } };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]]["drag_columns"].cols];

        ar[index[count + 1]] = {
            ...ar[index[count + 1]],
            ["drag_columns"]: {
                ...ar[index[count + 1]]["drag_columns"],
                cols: recursiveChangeInfoBorderedBlockPosition(index, a, count + 2, newPosition, oldPosition),
            },
        };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    }
}

/**
 * Nastavení elementu zobrazení nastavení
 * @param {String} type typ elementu u kterého se má zobrazit nastavení
 * @param {String} index index elementu (index je string několika čísel oddělených spojovníkem)
 */
export function showSettings(type, index) {
    index = index.split("-");

    var arr = [...cmDataVar().content.sections];
    arr = recursiveShowSettings(index, arr, 0, type);

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * Rekurentně najde element dle indexu a zobrazí mu nastavení
 * @param {String} index index elementu (index je string několika čísel oddělených spojovníkem)
 * @param {Array} arrPrevious předchozí pole, které e naplňuje
 * @param {Int} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
 * @param {String} type typ elementu (např headline,paragraph,...)
 */

export function recursiveShowSettings(index, arrPrevious, count, type) {
    /*
    if (type == "drag_column") {
        if (count + 3 == index.length) {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]].drag_columns.cols];

            a[index[count + 2]] = { ...a[index[count + 2]], showSettings: !a[index[count + 2]].showSettings };
            ar[index[count + 1]] = { ...ar[index[count + 1]], drag_columns: { ...ar[index[count + 1]].drag_columns, cols: a } };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        } else {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]].drag_columns.cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                drag_columns: { ...ar[index[count + 1]].drag_columns, cols: recursiveShowSettings(index, a, count + 2, type) },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        }
    } else {
        if (count + 2 == index.length) {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                [type]: { ...ar[index[count + 1]][type], showSettings: !ar[index[count + 1]][type].showSettings },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        } else {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]]["drag_columns"].cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                ["drag_columns"]: { ...ar[index[count + 1]]["drag_columns"], cols: recursiveShowSettings(index, a, count + 2, type) },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        }
    }
    */

    const parentListElementType = getParentListElementType(type);
    if (["drag_column", "info_bordered_block"].includes(type) && count + 3 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]][parentListElementType].cols];

        a[index[count + 2]] = { ...a[index[count + 2]], showSettings: !a[index[count + 2]].showSettings };
        ar[index[count + 1]] = { ...ar[index[count + 1]], [parentListElementType]: { ...ar[index[count + 1]][parentListElementType], cols: a } };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else if (count + 2 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];

        ar[index[count + 1]] = {
            ...ar[index[count + 1]],
            [type]: { ...ar[index[count + 1]][type], showSettings: !ar[index[count + 1]][type].showSettings },
        };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]]["drag_columns"].cols];

        ar[index[count + 1]] = {
            ...ar[index[count + 1]],
            ["drag_columns"]: { ...ar[index[count + 1]]["drag_columns"], cols: recursiveShowSettings(index, a, count + 2, type) },
        };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    }
}

/**
 * Nastavení elementu Nadpis
 * @param {Event} e ukazatel na položku co se nastavuje
 * @param {String} index index elementu (index je string několika čísel oddělených spojovníkem)
 * @param {String} element elemnt kterého se nastavení týká
 * @param {*} val hodnota nastavení
 * @param {Function} callBack
 * @param {String} type název parametru nastavení (např h1, textAlign,...)
 */
export function setElementData(e, index, element, val, callBack, type = null) {
    var value = "";

    if (typeof val != "undefined") {
        value = val;
    } else {
        value = e.target && e.target.value;
    }

    if (type !== null) {
        type = type;
    } else {
        type = (e.target && e.target.name) || (e._targetInst && e._targetInst.memoizedProps.name) || e;
    }

    index = index.split("-");

    /*
        if(element != "html"){
            value = value.replace(/<[^>]*>?/gm, '');
        }*/

    if (!value) value = "";

    var arr = [...cmDataVar().content.sections];
    arr = recursiveSetElementData(index, arr, 0, type, value, element);

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
    !!callBack && callBack();
}

/**
 * Rekurentně najde element dle indexu a uloží mu nastavení
 * @param {String} index index elementu (index je string několika čísel oddělených spojovníkem)
 * @param {Array} arrPrevious předchozí pole, které e naplňuje
 * @param {Int} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
 * @param {String} type název parametru nastavení (např h1, textAlign,...)
 * @param {*} value hodnota nastavení
 * @param {String} element elemnt kterého se nastavení týká
 */

export function recursiveSetElementData(index, arrPrevious, count, type, value, element) {
    /*
    if (element == "drag_column") {
        if (count + 3 == index.length) {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]].drag_columns.cols];

            a[index[count + 2]] = { ...a[index[count + 2]], [type]: value };
            ar[index[count + 1]] = { ...ar[index[count + 1]], drag_columns: { ...ar[index[count + 1]].drag_columns, cols: a } };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        } else {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]].drag_columns.cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                drag_columns: {
                    ...ar[index[count + 1]].drag_columns,
                    cols: recursiveSetElementData(index, a, count + 2, type, value, element),
                },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        }
    } else {
        if (count + 2 == index.length) {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];

            ar[index[count + 1]] = { ...ar[index[count + 1]], [element]: { ...ar[index[count + 1]][element], [type]: value } };

            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        } else {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]]["drag_columns"].cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                ["drag_columns"]: {
                    ...ar[index[count + 1]]["drag_columns"],
                    cols: recursiveSetElementData(index, a, count + 2, type, value, element),
                },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        }
    }
*/
    const parentListElementType = getParentListElementType(element);

    if (["drag_column", "info_bordered_block"].includes(element) && count + 3 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]][parentListElementType].cols];
        a[index[count + 2]] = { ...a[index[count + 2]], [type]: value };
        ar[index[count + 1]] = { ...ar[index[count + 1]], [parentListElementType]: { ...ar[index[count + 1]][parentListElementType], cols: a } };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else if (count + 2 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];

        ar[index[count + 1]] = { ...ar[index[count + 1]], [element]: { ...ar[index[count + 1]][element], [type]: value } };

        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else {
        var arr = [...arrPrevious];
        if (!!arrPrevious[index[count]].elements) {
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]]["drag_columns"].cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                ["drag_columns"]: {
                    ...ar[index[count + 1]]["drag_columns"],
                    cols: recursiveSetElementData(index, a, count + 2, type, value, element),
                },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };
        }

        return arr;
    }
}

/**
 * Vzbere element a zobrazí jeho menu
 * @param {Event} e typ elementu u kterého se má zobrazit nastavení
 * @param {String} type typ elementu u kterého se má zobrazit nastavení
 * @param {String} index index elementu (index je string několika čísel oddělených spojovníkem)
 */
export function selectElement(e, type, index) {
    e.preventDefault();
    e.stopPropagation();

    index = index.split("-");

    hideAllMenus().then(() => {
        var arr = [...cmDataVar().content.sections];
        arr = recursiveSelectElement(index, arr, 0, type);
        cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
    });
}
function getParentListElementType(type) {
    if (type == "drag_column") {
        return "drag_columns";
    } else if (type == "info_bordered_block") {
        return "info_bordered_blocks";
    }
    return "";
}
/**
 * Rekurentně najde element dle indexu a zobrazí mu menu
 * @param {String} index index elementu (index je string několika čísel oddělených spojovníkem)
 * @param {Array} arrPrevious předchozí pole, které e naplňuje
 * @param {Int} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
 * @param {String} type typ elementu (např headline,paragraph,...)
 */

export function recursiveSelectElement(index, arrPrevious, count, type) {
    const parentListElementType = getParentListElementType(type);

    if (["drag_column", "info_bordered_block"].includes(type) && count + 3 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]][parentListElementType].cols];

        a[index[count + 2]] = { ...a[index[count + 2]], showMenu: true };
        ar[index[count + 1]] = { ...ar[index[count + 1]], [parentListElementType]: { ...ar[index[count + 1]][parentListElementType], cols: a } };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else if (count + 2 == index.length) {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];

        ar[index[count + 1]] = { ...ar[index[count + 1]], [type]: { ...ar[index[count + 1]][type], showMenu: !ar[index[count + 1]][type].showMenu } };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else {
        //console.log(arrPrevious[index[count]].elements[index[count + 1]]);
        var arr = [...arrPrevious];
        if (!!arrPrevious[index[count]].elements) {
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]]["drag_columns"].cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                ["drag_columns"]: { ...ar[index[count + 1]]["drag_columns"], cols: recursiveSelectElement(index, a, count + 2, type) },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };
        }

        return arr;
    }
}

/**
 * Odstranění elementu
 * @param {*} index index elementu který se má odstranit (index je string několika čísel oddělených spojovníkem)
 */

export function removeElement(index) {
    index = index.split("-");

    var arr = [...cmDataVar().content.sections];
    arr = recursiveRemoveElement(index, arr, 0);

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * Rekurentně odebere element
 * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
 * @param {*} arrPrevious předchozí pole, které se naplňuje
 * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
 */

export function recursiveRemoveElement(index, arrPrevious, count) {
    if (count + 2 == index.length) {
        //jestliže se maže element obrázek
        if (arrPrevious[index[count]].elements[index[count + 1]].image && arrPrevious[index[count]].elements[index[count + 1]].image.url != "") {
            var fileName = arrPrevious[index[count]].elements[index[count + 1]].image.url.split("/");
            fileName = fileName.pop();
            removeImage(fileName);
        }

        if (arrPrevious[index[count]].elements[index[count + 1]].video) {
            const videoUrls = arrPrevious[index[count]].elements[index[count + 1]].video.mp4FileUrl;
            const posterUrls = arrPrevious[index[count]].elements[index[count + 1]].video.mp4FilePosterUrl;

            for (let i in videoUrls) {
                if (videoUrls[i] != "") {
                    var fName = videoUrls[i].split("/");
                    fName = fName.pop();

                    var fPosterName = posterUrls[i].split("/");
                    fPosterName = fPosterName.pop();

                    //removeVideo(fName);
                    removeImage(fPosterName);
                }
            }
        }

        var arr = [...arrPrevious];
        var ar = [
            ...arrPrevious[index[count]].elements.slice(0, index[count + 1]),
            ...arrPrevious[index[count]].elements.slice(parseInt(index[count + 1]) + 1),
        ];

        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    } else {
        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];
        var a = [...arrPrevious[index[count]].elements[index[count + 1]]["drag_columns"].cols];

        ar[index[count + 1]] = {
            ...ar[index[count + 1]],
            ["drag_columns"]: { ...ar[index[count + 1]]["drag_columns"], cols: recursiveRemoveElement(index, a, count + 2) },
        };
        arr[index[count]] = { ...arr[index[count]], elements: ar };

        return arr;
    }
}

/**
 * Zobrazení nástrojů pro celkovou správu content manageru
 */

export function showHideHelpers() {
    cmDataVar({ ...cmDataVar(), showHelpers: !cmDataVar().showHelpers });
}

/**
 * Funmkce pro přesouvání sekcí. Danou sekci uloží na své místo
 * @param {Object} result data z posouvače
 */
export function onDragSectionEnd(result) {
    if (result.destination.index != result.source.index) {
        var arr = [...cmDataVar().content.sections];

        if (result.source.index < result.destination.index) {
            arr.splice(result.destination.index + 1, 0, arr[result.source.index]);
            arr = [...arr.slice(0, result.source.index), ...arr.slice(result.source.index + 1)];
        } else {
            arr.splice(result.destination.index, 0, arr[result.source.index]);
            arr = [...arr.slice(0, result.source.index + 1), ...arr.slice(result.source.index + 2)];
        }

        cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
    }
}

/**
 * posunutí elementu
 * @param {String} index index sekce nebo sloupce, kde se element vkládá (index je string několika čísel oddělených spojovníkem)
 * @param {Object} result data z posouvace
 */

export function onDragEnd(result, index) {
    if (result.destination.index != result.source.index) {
        index = index.split("-");

        if (index.length == 1) {
            var arr = [...cmDataVar().content.sections];
            var elements = [...arr[index[0]].elements];

            if (result.source.index < result.destination.index) {
                elements.splice(result.destination.index + 1, 0, elements[result.source.index]);
                elements = [...elements.slice(0, result.source.index), ...elements.slice(result.source.index + 1)];
            } else {
                elements.splice(result.destination.index, 0, elements[result.source.index]);
                elements = [...elements.slice(0, result.source.index + 1), ...elements.slice(result.source.index + 2)];
            }

            arr[index[0]] = { ...arr[index[0]], elements: elements };

            cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
        } else {
            var arr = [...cmDataVar().content.sections];
            arr[index[0]] = recursiveonDragEnd(index, arr[index[0]], 1, result);

            cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
        }
    }
}

/**
 * POkud se element přesuvá v zanořeých sloupců, tak pro zanořování se používá tato metoda
 * @param {String} index index sekce nebo sloupce, kde se element vkládá (index je string několika čísel oddělených spojovníkem)
 * @param {Array} arrPrevious předchozí pole
 * @param {Int} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
 * @param {Object} result data z posouvace
 */

export function recursiveonDragEnd(index, arrPrevious, count, result) {
    var arr = [...arrPrevious.elements];
    var ar = [...arr[index[count]]["drag_columns"].cols];

    if (count + 2 == index.length) {
        var elements = [...ar[index[count + 1]].elements];

        if (result.source.index < result.destination.index) {
            elements.splice(result.destination.index + 1, 0, elements[result.source.index]);
            elements = [...elements.slice(0, result.source.index), ...elements.slice(result.source.index + 1)];
        } else {
            elements.splice(result.destination.index, 0, elements[result.source.index]);
            elements = [...elements.slice(0, result.source.index + 1), ...elements.slice(result.source.index + 2)];
        }

        ar[index[count + 1]] = { ...ar[index[count + 1]], elements: elements };
        arr[index[count]] = { ...arr[index[count]], ["drag_columns"]: { ...arr[index[count]]["drag_columns"], cols: ar } };

        return { ...arrPrevious, elements: arr };
    } else {
        ar[index[count + 1]] = recursiveonDragEnd(index, ar[index[count + 1]], count + 2, result);
        arr[index[count]] = { ...arr[index[count]], ["drag_columns"]: { ...arr[index[count]]["drag_columns"], cols: ar } };

        return { ...arrPrevious, elements: arr };
    }
}

/**
 * Odstranení sekce
 * @param {String} index index sekce která se odstraňuje (index je string několika čísel oddělených spojovníkem)
 */

export function removeSection(index) {
    //pokud existuje obrázek na pozadí
    if (cmDataVar().content.sections[index].backgroundImage != "") {
        var filename = cmDataVar().content.sections[index].backgroundImage.split("/");
        filename = filename.pop();

        //this.removeImage(filename);
    }

    goThroughtSectionElements(index, true);

    var arr = [...cmDataVar().content.sections.slice(0, index), ...cmDataVar().content.sections.slice(index + 1)];

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * Nastavení barvy pozadí sekce
 * @param {Event} e ukazatel na selectbox ve kterém se vybírá tato hodnota
 * @param {String} index index sekce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 */
export function setSectionBackgroundColorClass(e, index) {
    var val = e.target.value;

    var arr = [...cmDataVar().content.sections];
    arr[index] = { ...arr[index], backgroundColorClass: val };

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * funkce projede všechny elementy v sekci a pokud je deleting na true vymaže fotky
 * @param {Int} i pozice elementu
 * @param {Boolean} deleting jestli se mají vymazat fotky
 */
export function goThroughtSectionElements(i, deleting) {
    var sections = [...cmDataVar().content.sections];

    for (var j = 0; j < sections[i].elements.length; j++) {
        for (let el of ELEMENTS_LIST) {
            if (sections[i].elements[j][el]) {
                if (el == "image") {
                    if (deleting) {
                        if (sections[i].elements[j][el].url != "") {
                            var fileName = sections[i].elements[j][el].url.split("/");
                            fileName = fileName.pop();
                            removeImage(fileName);
                        }
                    }
                }
                if (el == "drag_columns") {
                    recursiveGoThroughtSectionElements(sections[i].elements[j][el].cols, deleting);
                }
            }
        }
    }
}

/**
 * Rekurze pro prostupování elementama v sekci
 * @param {*} arr
 * @param {*} deleting
 */

export function recursiveGoThroughtSectionElements(arr, deleting) {
    var elem = [];
    var ar = [...arr];

    for (var j = 0; j < ar.length; j++) {
        elem = [...ar[j].elements];
        if (elem.length > 0) {
            for (var i = 0; i < elem.length; i++) {
                for (let el of ELEMENTS_LIST) {
                    if (elem[i][el]) {
                        if (el == "image") {
                            if (deleting) {
                                if (elem[i][el].url != "") {
                                    var fileName = elem[i][el].url.split("/");
                                    fileName = fileName.pop();
                                    //this.removeImage(fileName);
                                }
                            }
                        }

                        if (el == "drag_columns") {
                            recursiveGoThroughtSectionElements(elem[i][el].cols, deleting);
                        }
                    }
                }
            }
        }
    }
}

/**
 * Nastavení sekce - nastavuje se vnitřní šířka, jestli je pevná nebo nebo ne
 * @param {Event} e ukazatel na selectbox ve kterém se vybírá tato hodnota
 * @param {String} index index sekce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 */

export function setSectionFullWidth(e, index) {
    var val = e.target.value;

    var arr = [...cmDataVar().content.sections];
    arr[index] = { ...arr[index], fullWidth: val };

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * Nastavení názvu
 * @param {*} e ukazatel na selectbox ve kterém se vybírá tato hodnota
 * @param {*} index index sekce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 */

export function setSectionName(e, index) {
    var val = e.target.value;

    var arr = [...cmDataVar().content.sections];
    arr[index] = { ...arr[index], sectionName: val };

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

/**
 * Nastavení třídy
 * @param {*} e ukazatel na selectbox ve kterém se vybírá tato hodnota
 * @param {*} index index sekce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
 */

export function setSectionClassName(e, index) {
    var val = e.target.value;

    var arr = [...cmDataVar().content.sections];
    arr[index] = { ...arr[index], className: val };

    cmDataVar({ ...cmDataVar(), content: { ...cmDataVar().content, sections: arr } });
}

export async function uploadImageElement(e, lastIndexes, oldUrl = "") {
    let fileToDelete = "";
    //zjistime nazev předchozího obrázku ke smazání
    if (oldUrl && oldUrl != "") {
        //zparsujeme url adresu lomítkem a vemzeme poslední položku - název souboru
        fileToDelete = oldUrl.split("/");
        fileToDelete = fileToDelete.pop();

        //zparsujeme název souboru a odebereme prefix (middle_, maxi_, ...)
        fileToDelete = fileToDelete.split("_");
        fileToDelete.shift();
        fileToDelete = fileToDelete.join("_");
    }

    var file = e.target.files[0];
    var fileName = await uploadImage(file, fileToDelete);

    setElementData("url", lastIndexes, "image", SERVER_URL + "/" + CONTENT_MANAGER_IMAGE_URL + "/middle_" + fileName);
    //saveContent();
}
export async function uploadBannerImageElement(e, lastIndexes, imgList, oldUrl = "") {
    let fileToDelete = "";
    /*
    //zjistime nazev předchozího obrázku ke smazání
    if (oldUrl && oldUrl != "") {
        //zparsujeme url adresu lomítkem a vemzeme poslední položku - název souboru
        fileToDelete = oldUrl.split("/");
        fileToDelete = fileToDelete.pop();

        //zparsujeme název souboru a odebereme prefix (middle_, maxi_, ...)
        fileToDelete = fileToDelete.split("_");
        fileToDelete.shift();
        fileToDelete = fileToDelete.join("_");
    }
        */

    var file = e.target.files[0];
    if (!file) return;

    var fileName = await uploadImage(file, fileToDelete);

    setElementData("imgList", lastIndexes, "banner", [...imgList, SERVER_URL + "/" + CONTENT_MANAGER_IMAGE_URL + "/wide_" + fileName]);
    //saveContent();
}
export async function removeBannerImageElement(lastIndexes, imgList, indexToDelete) {
    //odebere obrázek na pozici indexToDelete
    imgList.splice(indexToDelete, 1);

    setElementData("imgList", lastIndexes, "banner", imgList);
    //saveContent();
}
export async function reorderBannerList(lastIndexes, imgList, fromIndex, toIndex) {
    const items = Array.from(imgList);
    const [reorderedItem] = items.splice(fromIndex, 1);
    items.splice(toIndex, 0, reorderedItem);

    setElementData("imgList", lastIndexes, "banner", items);
    //saveContent();
}

export async function uploadImage(file, fileToDelete = "") {
    var filename = await client.mutate({
        context: { headers: { "apollo-require-preflight": true } },
        mutation: UPLOAD_CM_IMAGE,
        variables: {
            file,
            fileToDelete,
        },
    });

    return filename.data.uploadCmImage;
}

export function removeImage(filename) {
    //zparsujeme název souboru a odebereme prefix (middle_, maxi_, ...)
    filename = filename.split("_");
    filename.shift();
    filename = filename.join("_");

    client.mutate({
        mutation: REMOVE_CM_IMAGE,
        variables: {
            file: filename,
        },
    });
}
