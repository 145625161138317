/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditAdminUser from "./AddEditAdminUser";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { GET_ADMINUSERS, DELETE_ADMINUSERS } from "../Queries/adminUser";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import AdminUserLib from "../Library/adminUser";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import { useApolloClient, useQuery, useMutation } from "@apollo/client";

const AdminUsers = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    helper.checkAdminRights({ ...props, client }, ["1", "2"]);

    const listVariables = { lang: translate.locale };

    const [selectedAdminUsers, setSelectedAdminUsers] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedAdminUserID, setSelectedAdminUserID] = useState(0);
    const {
        loading,
        error: getError,
        data: adminUserListData,
    } = useQuery(GET_ADMINUSERS, {
        variables: listVariables,
    });

    const [deleteAdminUser, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_ADMINUSERS, {
        update(cache, response) {
            let adminUser = new AdminUserLib(this);
            adminUser.updateAfterDelete(cache, response, listVariables);

            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, adminUserID) => {
        setSelectedAdminUserID(adminUserID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteAdminUsers funkce, která posílá data na server
     * @param {*} adminUserID ID adminUseru
     */

    const initiateDeleteAdminUser = (e, adminUserID) => {
        var checked = e.target.checked;
        var arr = [...selectedAdminUsers];

        if (!checked) {
            for (let i in selectedAdminUsers) {
                if (selectedAdminUsers[i] == adminUserID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [adminUserID, ...arr];
        }

        setSelectedAdminUsers(arr);
    };

    const delAdminUser = (action) => {
        if (action) {
            let adminUserIDs = selectedAdminUsers.join(",");

            deleteAdminUser({
                variables: {
                    adminUserIDs: adminUserIDs,
                },
            });
        }
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedAdminUsers.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div id="settings" className="whole-container">
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("AdminUserList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, 0)}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className="card-body">
                            {!loading && !error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Name")}</div>
                                                <div className="text-center">{translate.t("Email")}</div>
                                                <div className="text-center">{translate.t("Role")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {adminUserListData.allAdminUsers &&
                                                adminUserListData.allAdminUsers.map((item, index) => {
                                                    var checked = false;
                                                    for (let i in selectedAdminUsers) {
                                                        if (selectedAdminUsers[i] == item.adminUserID) checked = true;
                                                    }

                                                    return (
                                                        <div key={index} className="data-list-item-content">
                                                            <div>
                                                                {item.name} {item.surname}
                                                            </div>
                                                            <div className="text-center">{item.email}</div>
                                                            <div className="text-center">{item.roleTitle}</div>
                                                            <div className="text-right">
                                                                <img onClick={() => openCloseModal(true, item.adminUserID)} className="edit-icon" src={edit} />
                                                                <input
                                                                    className="delete-checked"
                                                                    type="checkbox"
                                                                    name="deleteAdminUser[]"
                                                                    checked={checked}
                                                                    onChange={(e) => initiateDeleteAdminUser(e, item.adminUserID)}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </Fragment>
                                    </div>
                                    {adminUserListData.allAdminUsers && adminUserListData.allAdminUsers.length == 0 && (
                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                    )}
                                </div>
                            ) : (
                                <Fragment>
                                    {loading && <Loading />}
                                    {error && <Error text={error} />}
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && <AddEditAdminUser openCloseModal={openCloseModal} selectedAdminUserID={selectedAdminUserID} listVariables={listVariables} />}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delAdminUser} />}
        </div>
    );
};

export default AdminUsers;
