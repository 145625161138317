/**
 * soubor se seznamem veškerých graphql dotazů pro odkazy
 */
import gql from "graphql-tag";

/**
 * vložení odkazu
 */
export const ADD_EDIT_LINK = gql`
    mutation AddEditLink($linkID: ID, $parentID: String!, $linkContentTypeID: ID, $active: Int, $inHeader: Int, $inFooter: Int, $langs: [LinkLangsInput]) {
        addEditLink(
            linkID: $linkID
            parentID: $parentID
            linkContentTypeID: $linkContentTypeID
            active: $active
            inHeader: $inHeader
            inFooter: $inFooter
            langs: $langs
        ) {
            linkID
        }
    }
`;
export const EDIT_LINK_CONTENT = gql`
    mutation EditLinkContent($linkID: ID, $lang: String, $content: String) {
        editLinkContent(linkID: $linkID, lang: $lang, content: $content)
    }
`;

/**
 *  Úprava priority odkazu, když se přesouvají položky
 */
export const UPDATE_LINK_PRIORITY = gql`
    mutation updateLinkPriority($parentID: String!, $linkID: ID!, $fromIndex: Int!, $toIndex: Int!) {
        updateLinkPriority(parentID: $parentID, linkID: $linkID, fromIndex: $fromIndex, toIndex: $toIndex)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_LINK = gql`
    query LinkWithLangs($linkID: ID!) {
        linkWithLangs(linkID: $linkID) {
            linkID
            active
            parentID
            linkContentTypeID
            inHeader
            inFooter
            langs {
                lang
                name
                ownUrl
                description
                niceName
                metaTitle
                metaKeywords
                metaDescription
                content
            }
        }
        allLanguageMutations(onlyEnabled: true) {
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        linkContentTypes {
            linkContentTypeID
            title
        }
    }
`;

/**
 *  Seznam veškerých kategorií - maximlání zanoření je 7
 */
export const GET_LINKS = gql`
    query AllLinks($lang: String) {
        allLinks(lang: $lang) {
            linkID
            name
            active
            parentID
            inHeader
            inFooter
            langs {
                lang
                name
            }
            subLink {
                linkID
                name
                active
                parentID
                inHeader
                inFooter
                langs {
                    lang
                    name
                }
                subLink {
                    linkID
                    name
                    active
                    parentID
                    inHeader
                    inFooter
                    langs {
                        lang
                        name
                    }
                    subLink {
                        linkID
                        name
                        active
                        parentID
                        inHeader
                        inFooter
                        langs {
                            lang
                            name
                        }
                        subLink {
                            linkID
                            name
                            active
                            parentID
                            inHeader
                            inFooter
                            langs {
                                lang
                                name
                            }
                            subLink {
                                linkID
                                name
                                active
                                parentID
                                inHeader
                                inFooter
                                langs {
                                    lang
                                    name
                                }
                                subLink {
                                    linkID
                                    name
                                    active
                                    parentID
                                    inHeader
                                    inFooter
                                    langs {
                                        lang
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_LINKS = gql`
    mutation deleteLink($linkID: ID!, $parentID: String) {
        deleteLink(linkID: $linkID)
        reindexPriority(parentID: $parentID)
    }
`;
