/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import { GET_DISCIPLINE_LADDERS, GET_DISCIPLINES } from "../Queries/discipline";
import Notification from "../../../Library/notification";
import translate from "../../Translations/index";
import Helper from "../../../Library/helper";
import moment from "moment";

class Discipline {
    constructor(props) {
        this.props = props;
    }

    /**
     * Přidání/Editace
     * @param {*} addDiscipline funkce z apollo, pomocí které se posílají data na server
     */

    addDiscipline = async (addDiscipline, formData, callback = null) => {
        let notify = new Notification();

        if (formData && formData.title != "") {
            addDiscipline({
                variables: {
                    addEditData: {
                        hashID: formData.hashID,
                        competitionHashID: formData.competitionHashID,
                        ageCategoryID: formData.ageCategoryID,
                        globalDisciplineID: formData.globalDisciplineID,
                        athleteCountInTeam: parseInt(formData.athleteCountInTeam),
                        lang: translate.locale,
                    },
                },
            }).then(
                (responseData) => {
                    if (callback) {
                        callback(true, responseData);
                    }
                },
                (errorData) => {
                    if (callback) {
                        callback(false, errorData);
                    }
                }
            );
        } else {
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    /**
     * Přidání/Editace
     * @param {Function} addLadderToDiscipline funkce z apollo, pomocí které se posílají data na server
     * @param {Object} formData objekt s daty které se vkládají do databáze
     * @param {CallableFunction} callback návratová funkce
     */

    addLadderToDiscipline = async (addLadderToDiscipline, formData, callback = null) => {
        let notify = new Notification();

        if (formData && formData.ladderHashID != "") {
            addLadderToDiscipline({
                variables: {
                    disciplineHashID: formData.disciplineHashID,
                    ladderHashID: formData.ladderHashID,
                    lang: formData.lang,
                },
            }).then(
                (responseData) => {
                    if (callback) {
                        callback(true, responseData);
                    }
                },
                (errorData) => {
                    if (callback) {
                        callback(false, errorData);
                    }
                }
            );
        } else {
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache, response, selectedID, variables) {
        const { allDisciplines } = cache.readQuery({ query: GET_DISCIPLINES, variables });
        if (!(selectedID && selectedID != 0)) {
            const arr = [response.data.addEditDiscipline, ...allDisciplines];
            arr.sort(this.sortListFunction);

            cache.writeQuery({
                query: GET_DISCIPLINES,
                data: {
                    allDisciplines: arr,
                },
                variables,
            });
        } else {
            var arr = [...allDisciplines];

            arr.forEach((item, index) => {
                if (item.disciplineID == response.data.addEditDiscipline.disciplineID) {
                    arr[index].disciplineID = response.data.addEditDiscipline.disciplineID;
                    arr[index].competitionID = response.data.addEditDiscipline.competitionID;
                    arr[index].globalDisciplineID = response.data.addEditDiscipline.globalDisciplineID;
                    arr[index].athleteCountInTeam = response.data.addEditDiscipline.athleteCountInTeam;
                }
            });
            arr.sort(this.sortListFunction);
            cache.writeQuery({
                query: GET_DISCIPLINES,
                data: {
                    allDisciplines: arr,
                },
                variables,
            });
        }
    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateLadderList(cache, response, variables) {
        const { allDisciplineLadders } = cache.readQuery({ query: GET_DISCIPLINE_LADDERS, variables });

        const arr = [response.data.addLadderToDiscipline, ...allDisciplineLadders];

        cache.writeQuery({
            query: GET_DISCIPLINE_LADDERS,
            data: {
                allDisciplineLadders: arr,
            },
            variables,
        });
    }

    sortListFunction(a, b) {
        if (moment(a.date).toDate() < moment(b.date).toDate()) {
            return 1;
        } else if (moment(a.date).toDate() > moment(b.date).toDate()) {
            return -1;
        } else {
            if (a.disciplineID > b.disciplineID) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    /**
     *
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky
     */

    updateAfterDelete(cache, response, variables) {
        var resp = response.data.deleteTeams.split(",");

        const { allDisciplines } = cache.readQuery({ query: GET_DISCIPLINES, variables });
        var arr = [...allDisciplines];

        resp.forEach((it, ind) => {
            arr.forEach((item, index) => {
                if (item.disciplineID == it) {
                    arr = [...arr.slice(0, index), ...arr.slice(index + 1)];
                }
            });
        });

        cache.writeQuery({
            query: GET_DISCIPLINES,
            variables,
            data: {
                allDisciplines: [...arr],
            },
        });
    }

    /**
     *
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky
     */

    updateLadderListAfterDelete(cache, response, variables) {
        var resp = response.data.deleteLaddersFromDiscipline.split(",");

        const { allDisciplineLadders } = cache.readQuery({ query: GET_DISCIPLINE_LADDERS, variables });
        var arr = [...allDisciplineLadders];

        resp.forEach((it, ind) => {
            arr.forEach((item, index) => {
                if (item.hashID == it) {
                    arr = [...arr.slice(0, index), ...arr.slice(index + 1)];
                }
            });
        });

        cache.writeQuery({
            query: GET_DISCIPLINE_LADDERS,
            variables,
            data: {
                allDisciplineLadders: [...arr],
            },
        });
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */

    formHandle(e, formData, setFormData) {
        const t = e.target;
        const v = t.discipline === "checkbox" ? t.checked : t.value;
        const n = t.name;

        setFormData({ ...formData, [n]: v });
    }
}

export default Discipline;
