/*
    Přidání štítku
*/
import React, { Component, Fragment, useState } from "react";
import Modal from "react-modal";
import { ADD_LADDER_TO_DISCIPLINE } from "../../Ladder/Queries/ladder";
import { GET_LADDERS } from "../../Ladder/Queries/ladder";
import DisciplineLib from "../Library/discipline";
import TeamLib from "../Library/team";
import translate from "../../Translations/index";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import { useApolloClient, useQuery, useMutation } from "@apollo/client";

const AddLadderToDiscipline = (props) => {
    const client = useApolloClient();
    const disciplineLib = new DisciplineLib(props);
    const teamLib = new TeamLib(props);

    const [formData, setFormData] = useState({
        ladderHashID: "",
        disciplineHashID: props.disciplineHashID,
        lang: translate.locale,
    });

    const {
        loading: getLoading,
        error: getError,
        data: laddersData,
    } = useQuery(GET_LADDERS, {
        variables: {
            filters: {
                offset: 0,
                limit: 1000000,
                lang: translate.locale,
            },
        },
        fetchPolicy: "cache-and-network",
    });

    const [addLadderToDiscipline, { data, loading: addLoading, error: addError }] = useMutation(ADD_LADDER_TO_DISCIPLINE, {
        onCompleted(data) {
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md discipline-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content add-team">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{translate.t("SelectLadder")}</h4>
                    <button discipline="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="input-label">*{translate.t("Ladder")}</label>
                                <div className="form-group">
                                    <select
                                        value={formData.ladderHashID}
                                        className="form-control"
                                        name="ladderHashID"
                                        onChange={(e) => disciplineLib.formHandle(e, formData, setFormData)}
                                    >
                                        <option value={""}>-- {translate.t("SelectLadder")} --</option>
                                        {laddersData?.laddersByRole.map((item, index) => (
                                            <option key={index} value={item.hashID}>
                                                {" "}
                                                {item.title}{" "}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        discipline="button"
                        className="btn btn-primary flex-fill"
                        onClick={() => disciplineLib.addLadderToDiscipline(addLadderToDiscipline, formData)}
                    >
                        {translate.t("Add")}
                    </button>
                    <button discipline="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddLadderToDiscipline;
