/**
 *  komponenta pro štítky pro produkty
 */
import React, { Component, Fragment, useState } from "react";
import AddEditDiscipline from "./AddEditDiscipline";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_DISCIPLINES, DELETE_DISCIPLINES } from "../Queries/discipline";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import DisciplineLib from "../Library/discipline";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import { withRouter, NavLink } from "react-router-dom";

const Disciplines = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    //const helper = new Helper();
    //const adminUserData = helper.checkAdminRights(props,["1","2","3"]);

    const listVariables = {
        competitionHashID: props.match.params.competitionHashID,
    };

    const [selectedDisciplines, setSelectedDisciplines] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedDisciplineHashID, setSelectedDisciplineHashID] = useState(0);
    const {
        loading,
        error: getError,
        data: disciplineListData,
        fetchMore,
        refetch,
    } = useQuery(GET_DISCIPLINES, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
    });

    const [deleteDiscipline, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_DISCIPLINES, {
        update(cache, response) {
            refetch();

            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, disciplineID) => {
        setSelectedDisciplineHashID(disciplineID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteDisciplines funkce, která posílá data na server
     * @param {*} disciplineHashID hash ID discipliny
     */

    const initiateDeleteDiscipline = (e, disciplineHashID) => {
        var checked = e.target.checked;
        var arr = [...selectedDisciplines];

        if (!checked) {
            for (let i in selectedDisciplines) {
                if (selectedDisciplines[i] == disciplineHashID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [disciplineHashID, ...arr];
        }

        setSelectedDisciplines(arr);
    };

    const delDiscipline = (action) => {
        if (action) {
            let hashIDs = selectedDisciplines.join(",");

            deleteDiscipline({
                variables: {
                    hashIDs: hashIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedDisciplines.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div>
            <div className="mb-3">
                <div className="d-flex align-items-center mb-3">
                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                        {translate.t("AddDiscipline")}
                    </button>
                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                        {translate.t("DeleteSelected")}
                    </button>
                </div>
                <div className="">
                    {!error ? (
                        <div>
                            <div className="data-list">
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div>{translate.t("DisciplineTitle")}</div>
                                        <div className="text-center">{translate.t("Registration")}</div>
                                        <div className="text-right">{translate.t("Options")}</div>
                                    </div>
                                </div>
                                <Fragment>
                                    {disciplineListData &&
                                        disciplineListData.allDisciplines &&
                                        disciplineListData.allDisciplines.map((item, index) => {
                                            var checked = false;
                                            for (let i in selectedDisciplines) {
                                                if (selectedDisciplines[i] == item.hashID) checked = true;
                                            }

                                            return (
                                                <div key={index} className="data-list-item-content">
                                                    <div>{item.title}</div>
                                                    <div className="text-center">
                                                        {item.registrationClosed == 0 ? (
                                                            <span className="badge badge-success p-2">{translate.t("RegistrationOpened")}</span>
                                                        ) : (
                                                            <span className="badge badge-danger p-2">{translate.t("RegistrationClosed")}</span>
                                                        )}
                                                    </div>
                                                    <div className="text-right">
                                                        <NavLink
                                                            activeClassName="active"
                                                            strict
                                                            to={"/competition/" + item.competitionHashID + "/discipline/" + item.hashID + "/primary-data"}
                                                        >
                                                            <img className="edit-icon" src={edit} />
                                                        </NavLink>
                                                        <input
                                                            className="delete-checked"
                                                            type="checkbox"
                                                            name="deleteDiscipline[]"
                                                            checked={checked}
                                                            onChange={(e) => initiateDeleteDiscipline(e, item.hashID)}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </Fragment>
                            </div>

                            {!loading ? (
                                <Fragment>
                                    {disciplineListData && disciplineListData.allDisciplines && disciplineListData.allDisciplines.length == 0 ? (
                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                    ) : (
                                        <Fragment>
                                            {!loading &&
                                                disciplineListData &&
                                                disciplineListData.allDisciplines.length < disciplineListData.disciplineListCount && (
                                                    <div className="text-center p-5">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() =>
                                                                fetchMore({
                                                                    variables: { ...listVariables, offset: disciplineListData.allDisciplines.length },
                                                                    updateQuery: (prev, { fetchMoreResult }) => {
                                                                        if (!fetchMoreResult) return prev;
                                                                        return Object.assign({}, prev, {
                                                                            allDisciplines: [...prev.allDisciplines, ...fetchMoreResult.allDisciplines],
                                                                        });
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            {" "}
                                                            {translate.t("LoadMore")}{" "}
                                                        </button>
                                                    </div>
                                                )}
                                        </Fragment>
                                    )}
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    ) : (
                        <Fragment>{error && <Error text={error} />}</Fragment>
                    )}
                </div>
            </div>
            {showAdd && (
                <AddEditDiscipline
                    openCloseModal={openCloseModal}
                    selectedDisciplineHashID={selectedDisciplineHashID}
                    selectedCompetitionHashID={props.match.params.competitionHashID}
                    sportID={props.sportID}
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delDiscipline} />}
        </div>
    );
};

export default withRouter(Disciplines);
