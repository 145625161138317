import React, { Fragment } from "react";
import translate from "../../Translations/index";
import { addElementTo, setSectionClassName, setSectionFullWidth, setSectionName, showAddElements } from "../Library/contentManager";

const AddElement = (props) => {
    const { lastIndexes, element, isSection, columnType } = props;

    return (
        <div className={`cm-dropdown ${!element.showAddElements ? "closed" : ""}`}>
            <button onClick={() => showAddElements(lastIndexes)}>{translate.t("Content")}</button>
            <Fragment>
                <div className={`section-menu`}>
                    <div className="d-flex">
                        <ul>
                            <li onClick={(e) => addElementTo("drag_columns", lastIndexes)}>{translate.t("Columns")}</li>
                            <li onClick={(e) => addElementTo("text", lastIndexes)}>{translate.t("Text")}</li>
                            <li onClick={(e) => addElementTo("button", lastIndexes)}>{translate.t("Button")}</li>
                            <li onClick={(e) => addElementTo("banner", lastIndexes)}>{translate.t("Banner")}</li>
                            <li onClick={(e) => addElementTo("tournaments", lastIndexes)}>{translate.t("Tournaments")}</li>
                            <li onClick={(e) => addElementTo("ladders", lastIndexes)}>{translate.t("Ladders")}</li>
                            <li onClick={(e) => addElementTo("athletes", lastIndexes)}>{translate.t("Athletes")}</li>
                            <li onClick={(e) => addElementTo("federationList", lastIndexes)}>{translate.t("FederationList")}</li>
                            <li onClick={(e) => addElementTo("clubList", lastIndexes)}>{translate.t("ClubList")}</li>
                            <li onClick={(e) => addElementTo("sportList", lastIndexes)}>{translate.t("SportList")}</li>
                            {false && (
                                <>
                                    <li onClick={(e) => addElementTo("headline", lastIndexes)}>{translate.t("Nadpis")}</li>
                                    <li onClick={(e) => addElementTo("paragraph", lastIndexes)}>{translate.t("Paragraph")}</li>
                                    <li onClick={(e) => addElementTo("video", lastIndexes)}>{translate.t("Video")}</li>
                                    <li onClick={(e) => addElementTo("newsletter", lastIndexes)}>{translate.t("Newsletter")}</li>
                                    <li onClick={(e) => addElementTo("form", lastIndexes)}>{translate.t("Form")}</li>
                                    <li onClick={(e) => addElementTo("image", lastIndexes)}>{translate.t("Picture")}</li>
                                    <li onClick={(e) => addElementTo("articles", lastIndexes)}>{translate.t("Articles")}</li>
                                    <li onClick={(e) => addElementTo("newsletter", lastIndexes)}>{translate.t("Newsletter")}</li>
                                    <li onClick={(e) => addElementTo("contact_form", lastIndexes)}>{translate.t("ContactForm")}</li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
                {element.showAddElements && <div className={`cm-closing-overlay`} onClick={() => showAddElements(lastIndexes)}></div>}
            </Fragment>
        </div>
    );
};

export default AddElement;
