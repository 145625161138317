/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import { GET_GLOBAL_DISCIPLINES } from "../Queries/globalDiscipline";
import Notification from "../../../Library/notification";
import translate from "../../Translations/index";
import Helper from "../../../Library/helper";

class GlobalDiscipline {
    constructor(props) {
        this.props = props;
    }

    /**
     * Přidání/Editace
     * @param {*} addGlobalDiscipline funkce z apollo, pomocí které se posílají data na server
     */

    addGlobalDiscipline = async (addGlobalDiscipline, formData, callback = null) => {
        const helper = new Helper();

        let notify = new Notification();
        if (!(formData.ageLimitFrom == "" && formData.ageLimitTo == "")) {
            let langs = [];
            for (const lang of formData.langs) {
                langs.push({
                    title: lang.title,
                    description: lang.description,
                    lang: lang.lang,
                });
            }

            addGlobalDiscipline({
                variables: {
                    addEditData: {
                        hashID: formData.hashID,
                        lang: translate.locale,
                        langs,
                    },
                },
            }).then(
                (responseData) => {
                    if (callback) {
                        callback(true, responseData);
                    }
                },
                (errorData) => {
                    if (callback) {
                        callback(false, errorData);
                    }
                }
            );
        } else {
            notify.setNotification(translate.t("MustFillOneLimit"), "danger");
        }
    };

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache, response, selectedID, variables) {
        const { allGlobalDisciplines } = cache.readQuery({ query: GET_GLOBAL_DISCIPLINES, variables });
        if (!(selectedID && selectedID != 0)) {
            const arr = [response.data.addEditGlobalDiscipline, ...allGlobalDisciplines];

            cache.writeQuery({
                query: GET_GLOBAL_DISCIPLINES,
                data: {
                    allGlobalDisciplines: arr,
                },
                variables,
            });
        } else {
            var arr = [...allGlobalDisciplines];

            arr.forEach((item, index) => {
                if (item.hashID == response.data.addEditGlobalDiscipline.hashID) {
                    arr[index].hashID = response.data.addEditGlobalDiscipline.hashID;
                    arr[index].title = response.data.addEditGlobalDiscipline.title;
                }
            });
            cache.writeQuery({
                query: GET_GLOBAL_DISCIPLINES,
                data: {
                    allGlobalDisciplines: arr,
                },
                variables,
            });
        }
    }

    /**
     *
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky
     */

    updateAfterDelete(cache, response, variables) {
        var resp = response.data.deleteGlobalDisciplines.split(",");

        const { allGlobalDisciplines } = cache.readQuery({ query: GET_GLOBAL_DISCIPLINES, variables });
        var arr = [...allGlobalDisciplines];

        resp.forEach((it, ind) => {
            arr.forEach((item, index) => {
                if (item.hashID == it) {
                    arr = [...arr.slice(0, index), ...arr.slice(index + 1)];
                }
            });
        });

        cache.writeQuery({
            query: GET_GLOBAL_DISCIPLINES,
            variables,
            data: {
                allGlobalDisciplines: [...arr],
            },
        });
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Object} formData object obsahujici data formulare
     * @param {Function} setFormData funkce nastavující data formulare
     */
    formHandle(e, formData, setFormData) {
        const t = e.target;
        const v = t.type === "checkbox" ? t.checked : t.value;
        const n = t.name;
        setFormData({ ...formData, [n]: v });
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Object} formData object obsahujici data formulare
     * @param {Function} setFormData funkce nastavující data formulare
     */
    formLangHandle(e, formData, setFormData) {
        const t = e.target;
        const v = t.type === "checkbox" ? t.checked : t.value;
        const n = t.name;

        let langs = formData.langs;
        for (const lang of langs) {
            if (lang.lang == formData.selectedLang) {
                lang[n] = v;
            }
        }

        setFormData({ ...formData, langs: langs });
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     * @param {Object} formData object obsahujici data formulare
     * @param {Function} setFormData funkce nastavující data formulare
     */

    formLangHandleEditor(content, paramName, formData, setFormData) {
        let langs = formData.langs;
        for (const lang of langs) {
            if (lang.lang == formData.selectedLang) {
                lang[paramName] = content;
            }
        }

        setFormData({ ...formData, langs: langs });
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} paramName - název proměnné v objektu lang
     *
     * @returns property value
     */
    getLangValue = (formData, paramName) => {
        for (const lang of formData.langs) {
            if (lang.lang == formData.selectedLang) {
                if (lang[paramName] === null) {
                    return "";
                } else {
                    return lang[paramName];
                }
            }
        }
        return "";
    };

    completeLangsArray = (langsFromDb, allActiveLanguages) => {
        let langs = langsFromDb;
        for (const l of allActiveLanguages) {
            let langSet = false;
            for (const lang of langs) {
                if (l.suffix == lang.lang) {
                    langSet = true;
                    break;
                }
            }

            if (!langSet) {
                langs.push({
                    title: "",
                    description: "",
                    lang: l.suffix,
                });
            }
        }
        return langs;
    };
}

export default GlobalDiscipline;
