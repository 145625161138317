import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import {
    DELETE_TINYMCE_FILES_PERMANENTLY,
    DELETE_TINYMCE_IMAGES_PERMANENTLY,
    GET_TINYMCE_FILES,
    GET_TINYMCE_IMAGES,
    RECOVER_TINYMCE_FILES,
    RECOVER_TINYMCE_IMAGES,
} from "../Queries/index";
import { useLazyQuery, useMutation } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import { SERVER_URL, TINYMCE_IMAGE_URL } from "../../../Config/index";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import translate from "../../Translations/index";
import Notification from "../../../Library/notification";

Modal.setAppElement("#root");

const TinyMceFileManagerDeletedArchive = (props) => {
    const [loading, setLoading] = useState(true);
    const [allFiles, setAllFiles] = useState([]);
    const [allImages, setAllImages] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showRecoveryNotifi, setShowRecoveryNotifi] = useState(false);

    const [getImages, { loading: imagesLoading, refetch: refetchImages }] = useLazyQuery(GET_TINYMCE_IMAGES, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            setAllImages(JSON.parse(JSON.stringify(data.allTinyMceImages)));
            setLoading(false);
        },
        onError(err) {
            console.log(err);
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const [getFiles, { loading: filesLoading, refetch: refetchFiles }] = useLazyQuery(GET_TINYMCE_FILES, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            setAllFiles(JSON.parse(JSON.stringify(data.allTinyMceFiles)));
            setLoading(false);
        },
        onError(err) {
            console.log(err);
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const [deleteImages, { loading: imageDeleting }] = useMutation(DELETE_TINYMCE_IMAGES_PERMANENTLY, {
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("PicturesSuccessfullyDeleted"), "success");
            refetchImages();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const [deleteFiles, { loading: fileDeleting }] = useMutation(DELETE_TINYMCE_FILES_PERMANENTLY, {
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("FilesSuccessfullyDeleted"), "success");
            refetchFiles();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const [recoverImages, { loading: imageRecovering }] = useMutation(RECOVER_TINYMCE_IMAGES, {
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("PicturesSuccessfullyRecovered"), "success");
            refetchImages();
            !!props.onRecoveryCompleted && props.onRecoveryCompleted();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const [recoverFiles, { loading: filegeRecovering }] = useMutation(RECOVER_TINYMCE_FILES, {
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("FilesSuccessfullyRecovered"), "success");
            refetchFiles();
            !!props.onRecoveryCompleted && props.onRecoveryCompleted();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    useEffect(() => {
        if (props.tinyMCEFileType == "image") {
            getImages({
                variables: {
                    filters: {
                        deleted: 1,
                    },
                },
            });
        } else if (props.tinyMCEFileType == "file" || props.tinyMCEFileType == "media") {
            getFiles({
                variables: {
                    filters: {
                        deleted: 1,
                    },
                },
            });
        }
    }, [props.tinyMCEFileType]);

    const checkImage = (image) => {
        for (let img of allImages) {
            if (image.tinyMceImageID == img.tinyMceImageID) {
                img.checked = !img.checked;
            }
        }
        setAllImages([...allImages]);
    };
    const checkFile = (file) => {
        for (let f of allFiles) {
            if (file.tinyMceFileID == f.tinyMceFileID) {
                f.checked = !f.checked;
            }
        }
        setAllFiles([...allFiles]);
    };

    const delItems = (status) => {
        if (status) {
            let IDs = [];
            if (props.tinyMCEFileType == "image") {
                for (const image of allImages) {
                    if (!!image.checked) {
                        IDs.push(image.tinyMceImageID);
                    }
                }
                if (IDs.length > 0) {
                    deleteImages({ variables: { IDs } });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("YouDidNotSelectAnyPhoto"), "danger");
                }
            } else if (props.tinyMCEFileType == "file" || props.tinyMCEFileType == "media") {
                for (const file of allFiles) {
                    if (!!file.checked) {
                        IDs.push(file.tinyMceFileID);
                    }
                }
                if (IDs.length > 0) {
                    deleteFiles({ variables: { IDs } });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("YouDidNotSelectAnyFile"), "danger");
                }
            }
        }
        setShowDeleteNotifi(false);
    };

    const recoverItems = (status) => {
        if (status) {
            let IDs = [];
            if (props.tinyMCEFileType == "image") {
                for (const image of allImages) {
                    if (!!image.checked) {
                        IDs.push(image.tinyMceImageID);
                    }
                }
                if (IDs.length > 0) {
                    recoverImages({ variables: { IDs } });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("YouDidNotSelectAnyPhoto"), "danger");
                }
            } else if (props.tinyMCEFileType == "file" || props.tinyMCEFileType == "media") {
                for (const file of allFiles) {
                    if (!!file.checked) {
                        IDs.push(file.tinyMceFileID);
                    }
                }
                if (IDs.length > 0) {
                    recoverFiles({ variables: { IDs } });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("YouDidNotSelectAnyFile"), "danger");
                }
            }
        }
        setShowRecoveryNotifi(false);
    };

    const getDeleteText = () => {
        const itemCount =
            props.tinyMCEFileType == "image" ? allImages.filter((item) => !!item.checked).length : allFiles.filter((item) => !!item.checked).length;

        let text = `
            Opravdu chcete permanentně odstranit
            <strong>
                ${itemCount} 
                ${itemCount == 0 ? `vybraných položek` : ``}
                ${itemCount == 1 ? `vybranou položku` : ``}
                ${itemCount > 1 && itemCount <= 4 ? `vybrané položky` : ``}
                ${itemCount > 4 ? `vybraných položek` : ``}   
            </strong>? <strong>Tuto akci již nebude možné vzít zpět.</strong>
        `;
        return text;
    };

    return (
        <>
            {!loading ? (
                <>
                    {!imageDeleting && !imagesLoading && !fileDeleting && !filesLoading ? (
                        <Fragment>
                            <div className="d-flex align-items-end">
                                <button className="btn btn-success btn-delete-files ml-auto mr-2" type="button" onClick={() => setShowRecoveryNotifi(true)}>
                                    Obnovit označené {props.tinyMCEFileType == "image" ? "fotky" : "soubory"}
                                </button>
                                <button className="btn btn-danger btn-delete-files" type="button" onClick={() => setShowDeleteNotifi(true)}>
                                    Permanentně odstranit označené {props.tinyMCEFileType == "image" ? "fotky" : "soubory"}
                                </button>
                            </div>
                            <hr />
                            <div className="d-flex flex-wrap">
                                {allImages.map((item, index) => {
                                    return (
                                        <div key={index} className="col-3 p-2">
                                            <div className="position-relative" style={{ height: "100px" }}>
                                                <div className="rounded d-flex position-absolute top-0 start-0 p-2 bg-light" onClick={() => checkImage(item)}>
                                                    <input type="checkbox" name="delete" checked={!!item.checked} onChange={() => {}} />
                                                </div>
                                                <img
                                                    className="img-thumbnail"
                                                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                                    src={SERVER_URL + "/" + TINYMCE_IMAGE_URL + "/small_" + item.name}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}

                                {allFiles.map((item, index) => {
                                    return (
                                        <div key={index} className="col-3 p-2 form-group">
                                            <div className="cover-item file-list-item file-item">
                                                <div
                                                    className="rounded d-flex position-absolute top-0 start-0 p-2 bg-light checker"
                                                    onClick={() => checkFile(item)}
                                                >
                                                    <input type="checkbox" name="delete" checked={!!item.checked} onChange={() => {}} />
                                                </div>

                                                <div className="form-group text-center file-desc">
                                                    <div className="icon-wrapper">
                                                        <i className="icon las la-file text-secondary"></i>
                                                        <div className="ext text-secondary">{item.name.split(".").pop()}</div>
                                                    </div>
                                                    <p className="fs-14 cursor-pointer">{item.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Fragment>
                    ) : (
                        <Loading color="blue" />
                    )}
                </>
            ) : (
                <Loading color="blue" />
            )}
            {showDeleteNotifi && (
                <ModalNotification title={"Permanentně odstranit vybrané položky?"} yesNo={true} content={getDeleteText()} callback={delItems} />
            )}
            {showRecoveryNotifi && (
                <ModalNotification title={"Obnovit vybrané položky?"} yesNo={true} text={"Opravdu chcete vybrané položky obnovit?"} callback={recoverItems} />
            )}
        </>
    );
};

export default TinyMceFileManagerDeletedArchive;
