/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_RANKING_POINT_TABLE, GET_RANKING_POINT_TABLE } from "../Queries/rankingPoint";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import RankingPoints from "./RankingPoints";

const AddEditRankingPointTable = (props) => {
    const [selectedTab, setTab] = useState(1);

    const [formData, setFormData] = useState({
        rankingPointTableID: props.selectedRankingPointTableID,
        sportID: "0",
        name: "",
        scoringPositionsNumber: 0,
        lang: translate.locale,
    });

    const {
        loading: getLoading,
        error: getError,
        data: rankingPointTableData,
    } = useQuery(GET_RANKING_POINT_TABLE, {
        variables: { rankingPointTableID: formData.rankingPointTableID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.rankingPointTableID != "") {
                setFormData({
                    ...formData,
                    sportID: data.rankingPointTable.sportID,
                    name: data.rankingPointTable.name,
                    rankingPointTableID: data.rankingPointTable.rankingPointTableID,
                    scoringPositionsNumber: data.rankingPointTable.scoringPositionsNumber,
                });
            }
        },
    });

    const [addRankingPointTable, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_RANKING_POINT_TABLE, {
        onCompleted(data) {
            setFormData({ ...formData, rankingPointTableID: data.addEditRankingPointTable.rankingPointTableID });
            //props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addRankingPointTableData = () => {
        if (!!formData.name && formData.sportID != "0") {
            let dataToSend = {
                ...formData,
                scoringPositionsNumber: parseInt(formData.scoringPositionsNumber),
            };
            addRankingPointTable({
                variables: {
                    addEditData: dataToSend,
                },
            });
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md rankingPointTable-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {formData.rankingPointTableID && formData.rankingPointTableID != ""
                            ? translate.t("EditRankingPointTable")
                            : translate.t("AddRankingPointTable")}
                    </h4>

                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <ul className={`nav nav-tabs`} role="tablist">
                                        <li className={`nav-item mr-2`}>
                                            <a className={`nav-link ${selectedTab == 1 ? `active` : ""}`} onClick={(e) => setTab(1)}>
                                                {translate.t("MainData")}
                                            </a>
                                        </li>
                                        <li className={`nav-item mr-2`}>
                                            <a
                                                className={`nav-link ${selectedTab == 2 ? `active` : ""}  ${!formData.rankingPointTableID ? "disabled" : ""}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (formData.rankingPointTableID) setTab(2);
                                                }}
                                            >
                                                {translate.t("Points")}
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        {selectedTab == 1 && (
                                            <>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("Sport")}</label>
                                                            <div className="form-group">
                                                                <select
                                                                    className="form-control form-control"
                                                                    value={formData.sportID}
                                                                    onChange={(e) => setFormData({ ...formData, sportID: e.target.value })}
                                                                >
                                                                    <option value={0}>-- {translate.t("ChooseSport")} --</option>
                                                                    {rankingPointTableData?.sports.map((item, index) => (
                                                                        <option key={index} value={item.sportID}>
                                                                            {item.title}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("TableName")}</label>
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={formData.name}
                                                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("ScoringPositionsNumber")}</label>
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    inputMode="numeric"
                                                                    step={1}
                                                                    value={formData.scoringPositionsNumber}
                                                                    onChange={(e) => setFormData({ ...formData, scoringPositionsNumber: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-end mt-4">
                                                    <button type="button" className="btn btn-primary mr-2" onClick={() => addRankingPointTableData()}>
                                                        {formData.rankingPointTableID && formData.rankingPointTableID != ""
                                                            ? translate.t("Edit")
                                                            : translate.t("Add")}
                                                    </button>
                                                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                                                        {translate.t("Close")}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                        {selectedTab == 2 && (
                                            <RankingPoints
                                                rankingPointTableID={formData.rankingPointTableID}
                                                openCloseModal={(status) => props.openCloseModal(status)}
                                            />
                                        )}
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddEditRankingPointTable;
