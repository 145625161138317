/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

const moment = require("moment");

class Sport {
    constructor(props) {
        this.props = props;
    }

    completeLangsArray = (langsFromDb, allActiveLanguages) => {
        let langs = langsFromDb;
        for (const l of allActiveLanguages) {
            let langSet = false;
            for (const lang of langs) {
                if (l.suffix == lang.lang) {
                    langSet = true;
                    break;
                }
            }

            if (!langSet) {
                langs.push({
                    title: "",
                    lang: l.suffix,
                });
            }
        }
        return langs;
    };
}

export default Sport;
