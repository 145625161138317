/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_LADDER_ORGANISER, GET_LADDER_ORGANISER } from "../Queries/ladder";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";

const AddEditLadderOrganiser = (props) => {
    const [formData, setFormData] = useState({
        ladderOrganiserID: props.ladderOrganiserID,
        organiserID: 0,
        ladderID: props.ladderID,
        canEditLadder: 0,
        canDeleteLadder: 0,
        lang: translate.locale,
    });

    const {
        loading: getLoading,
        error: getError,
        data: ladderOrganiserData,
    } = useQuery(GET_LADDER_ORGANISER, {
        variables: { ladderOrganiserID: formData.ladderOrganiserID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.ladderOrganiserID != "") {
                setFormData({
                    ...formData,
                    organiserID: data.ladderOrganiser.organiserID,
                    canEditLadder: data.ladderOrganiser.canEditLadder,
                    canDeleteLadder: data.ladderOrganiser.canDeleteLadder,
                });
            }
        },
    });

    const [addLadderOrganiser, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_LADDER_ORGANISER, {
        onCompleted(data) {
            setFormData({ ...formData, ladderOrganiserID: data.addEditLadderOrganiser.ladderOrganiserID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addLadderOrganiserData = () => {
        if (!!formData.organiserID) {
            let dataToSend = {
                ...formData,
                organiserID: parseInt(formData.organiserID),
                canEditLadder: parseInt(formData.canEditLadder),
                canDeleteLadder: parseInt(formData.canDeleteLadder),
            };
            addLadderOrganiser({
                variables: {
                    addEditData: dataToSend,
                },
            });
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md ladderOrganiser-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {formData.ladderOrganiserID && formData.ladderOrganiserID != ""
                            ? translate.t("EditLadderOrganiser")
                            : translate.t("AddLadderOrganiser")}
                    </h4>

                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Organiser")}</label>
                                                <select
                                                    value={formData.organiserID}
                                                    className="form-control"
                                                    onChange={(e) => setFormData({ ...formData, organiserID: e.target.value })}
                                                >
                                                    <option value={0}>--{translate.t("ChooseOrganiserAccount")}--</option>
                                                    {ladderOrganiserData?.organisers?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.organiserID}>
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("CanEditLadder")}</label>
                                                <select
                                                    value={formData.canEditLadder}
                                                    className="form-control"
                                                    onChange={(e) => setFormData({ ...formData, canEditLadder: e.target.value })}
                                                >
                                                    <option value={0}>{translate.t("No")}</option>
                                                    <option value={1}>{translate.t("Yes")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("CanDeleteLadder")}</label>
                                                <select
                                                    value={formData.canDeleteLadder}
                                                    className="form-control"
                                                    onChange={(e) => setFormData({ ...formData, canDeleteLadder: e.target.value })}
                                                >
                                                    <option value={0}>{translate.t("No")}</option>
                                                    <option value={1}>{translate.t("Yes")}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-4">
                                        <button type="button" className="btn btn-primary mr-2" onClick={() => addLadderOrganiserData()}>
                                            {formData.ladderOrganiserID && formData.ladderOrganiserID != "" ? translate.t("Edit") : translate.t("Add")}
                                        </button>
                                        <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                                            {translate.t("Close")}
                                        </button>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddEditLadderOrganiser;
