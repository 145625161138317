/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import { ADD_EDIT_COMPETITION, GET_COMPETITION } from "../Queries/competition";
import CompetitionLib from "../Library/competition";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";
import { COMPETITION_IMAGE_URL, SERVER_URL, MAX_FILE_UPLOAD_SIZE } from "../../../Config/index";
import remove from "../../../Public/Images/remove.svg";
import { withRouter } from "react-router-dom";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ADMIN_USER } from "../../../GlobalQueries/globalQueries";

const PrimaryData = (props) => {
    const client = useApolloClient();
    const [fileInputKey, setFileInputKey] = useState(0);
    const [sponsorFileInputKey, setSponsorFileInputKey] = useState(0);
    const adminUserData = client.readQuery({ query: ADMIN_USER });
    const competitionLib = new CompetitionLib(props);
    const [formData, setFormData] = useState({
        selectedLang: "cs",
        hashID: props.selectedCompetitionHashID,
        competitionStateID: 1,
        countryID: "",
        title: "",
        dateFrom: null,
        dateTo: null,
        sportID: 0,
        organiserID: adminUserData.adminUser.roleID == 3 ? adminUserData.adminUser.organiserID : 0,
        place: "",
        description: "",
        photo: "",
        photoURL: "",
        file: "",
        fileName: "",
        fileSize: 0,
        lang: translate.locale,
    });

    const {
        loading: getLoading,
        error: getError,
        data: competitionData,
    } = useQuery(GET_COMPETITION, {
        variables: { hashID: formData.hashID, lang: translate.locale, sportID: props.sportID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.hashID != "") {
                setFormData({
                    ...formData,
                    title: data.competition.title,
                    dateFrom: moment(data.competition.dateFrom).toDate(),
                    dateTo: data.competition.dateTo ? moment(data.competition.dateTo).toDate() : null,
                    place: data.competition.place,
                    competitionStateID: data.competition.competitionStateID,
                    photoURL: data.competition.photo ? SERVER_URL + "/" + COMPETITION_IMAGE_URL + "/mala_" + data.competition.photo : "",
                    fileName: data.competition.file,
                    fileSize: data.competition.fileSize,
                    description: data.competition.description,
                    countryID: data.competition.countryID,
                    sportID: data.competition.sportID,
                    organiserID: data.competition.organiserID,
                });
            }
        },
    });

    const [addCompetition, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_COMPETITION, {
        onCompleted(data) {
            //pokud pridavame novy zavod, tak po pridani presmerujeme do detailu
            if (!formData.hashID) {
                props.history.push("/competition/" + data.addEditCompetition.hashID + "/primary-data");
            }
        },
        update(cache, response) {
            let notify = new Notification();
            if (formData.hashID) notify.setNotification(translate.t("SuccessfullySaved"));
            else notify.setNotification(translate.t("SuccessfullyAdded"));
        },
        onError(err) {
            const helper = new Helper();
            err = helper.getApolloErrorText(err);
            let notify = new Notification();
            notify.setNotification(err, "danger");
        },
    });

    const openImage = (e) => {
        var file = e.target.files[0];

        if (file) {
            var reader = new FileReader();
            reader.onload = (onLoadE) => {
                if (onLoadE.total / 1000000 <= MAX_FILE_UPLOAD_SIZE) {
                    setFormData({ ...formData, photoURL: reader.result, photo: file });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("MaxFileSizeExceeded", { maxFileSize: MAX_FILE_UPLOAD_SIZE }), "danger");
                    setFileInputKey(fileInputKey + 1);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Fragment>
            <div className={props.inModal ? "modal-body " : ""}>
                {error ? (
                    <Error text={error} />
                ) : (
                    <div>
                        {!getLoading ? (
                            <Fragment>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("CompetitionTitle")} </label>
                                            <div className="form-group">
                                                <input
                                                    className="form-control"
                                                    competition="text"
                                                    name="title"
                                                    value={formData.title}
                                                    onChange={(e) => competitionLib.formHandle(e, formData, setFormData)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("CompetitionState")}</label>
                                            <div className="form-group">
                                                <select
                                                    value={formData.competitionStateID}
                                                    className="form-control"
                                                    name="competitionStateID"
                                                    onChange={(e) => competitionLib.formHandle(e, formData, setFormData)}
                                                >
                                                    <option value={1}>{translate.t("Preparing")}</option>
                                                    <option value={2}>{translate.t("InProgress")}</option>
                                                    <option value={3}>{translate.t("Finished")}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("DateFrom")}</label>
                                            <div className="form-group date-w-100">
                                                <DatePicker
                                                    className="form-control w-100"
                                                    dateFormat="yyyy-MM-dd HH:mm"
                                                    locale={translate.locale}
                                                    selected={formData.dateFrom}
                                                    showTimeInput
                                                    onChange={(date) => setFormData({ ...formData, dateFrom: date })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label className="input-label">{translate.t("DateTo")}</label>
                                            <div className="form-group date-w-100">
                                                <DatePicker
                                                    className="form-control w-100"
                                                    dateFormat="yyyy-MM-dd HH:mm"
                                                    locale={translate.locale}
                                                    selected={formData.dateTo}
                                                    showTimeInput
                                                    onChange={(date) => setFormData({ ...formData, dateTo: date })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            {adminUserData.adminUser.roleID == 1 && (
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("BelongsToOrganizator")}</label>
                                                        <div className="form-group">
                                                            <select
                                                                value={formData.organiserID}
                                                                className="form-control"
                                                                name="organiserID"
                                                                onChange={(e) => competitionLib.formHandle(e, formData, setFormData)}
                                                            >
                                                                <option value={""}>-- {translate.t("SelectOrganizator")} --</option>
                                                                {competitionData &&
                                                                    competitionData.organisers.map((item, index) => (
                                                                        <option key={index} value={item.organiserID}>
                                                                            {" "}
                                                                            {item.name} {item.surname}{" "}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="input-label">*{translate.t("Sport")}</label>
                                                    <div className="form-group">
                                                        <select
                                                            value={formData.sportID}
                                                            className="form-control"
                                                            name="roleID"
                                                            onChange={(e) => setFormData({ ...formData, sportID: e.target.value })}
                                                        >
                                                            <option value={0}>-- {translate.t("ChooseSport")} --</option>
                                                            {competitionData?.allSportsBySignedAdminUser.map((item, index) => (
                                                                <option key={index} value={item.sportID}>
                                                                    {item.title}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="input-label">*{translate.t("CompetitionCountry")}</label>
                                                    <div className="form-group">
                                                        <select
                                                            value={formData.countryID}
                                                            className="form-control"
                                                            name="countryID"
                                                            onChange={(e) => competitionLib.formHandle(e, formData, setFormData)}
                                                        >
                                                            <option value={""}>-- {translate.t("SelectCountry")} --</option>
                                                            {competitionData &&
                                                                competitionData.allCountries.map((item, index) => (
                                                                    <option key={index} value={item.countryID}>
                                                                        {item.name}{" "}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="input-label">{translate.t("CompetitionPlace")}</label>
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            competition="text"
                                                            name="place"
                                                            value={formData.place}
                                                            onChange={(e) => competitionLib.formHandle(e, formData, setFormData)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group input-group">
                                                    <div className="form-group">
                                                        <label className="input-label">
                                                            {translate.t("CompetitionPhoto")} (max {MAX_FILE_UPLOAD_SIZE}Mb)
                                                        </label>
                                                        {!formData.photoURL && !formData.photo ? (
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="customPhoto"
                                                                    name="photo"
                                                                    accept="image/*"
                                                                    onChange={(e) => openImage(e)}
                                                                    key={fileInputKey}
                                                                />
                                                                <label className="custom-file-label" htmlFor="customPhoto">
                                                                    {translate.t("ChoosePhoto")}
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <div className="d-flex flex-column align-items-start">
                                                                    <img className="maxwidth-100 competition-img" src={formData.photoURL} />
                                                                    <img
                                                                        onClick={() => setFormData({ ...formData, photo: "", photoURL: "" })}
                                                                        className="remove-icon mt-2"
                                                                        src={remove}
                                                                        title={translate.t("Remove")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group input-group">
                                                    <div className="form-group">
                                                        <label className="input-label">
                                                            {translate.t("CompetitionFile")} (max {MAX_FILE_UPLOAD_SIZE}Mb)
                                                        </label>
                                                        {!formData.fileName && !formData.file ? (
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="customFile"
                                                                    name="file"
                                                                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                                                    onChange={(e) => competitionLib.openFile(e, setFormData, formData)}
                                                                />
                                                                <label className="custom-file-label" htmlFor="customFile">
                                                                    {translate.t("ChooseFile")}
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex align-items-center">
                                                                {competitionLib.getFileName(formData.fileName, formData.fileSize)}
                                                                <img
                                                                    onClick={() => setFormData({ ...formData, file: "", fileName: "", fileSize: 0 })}
                                                                    className="remove-icon"
                                                                    src={remove}
                                                                    title={translate.t("Remove")}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="input-label">{translate.t("CompetitionDescription")}</label>
                                            <div className="form-group">
                                                <Editor
                                                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                                                    name="perex"
                                                    value={formData.description}
                                                    init={{
                                                        promotion: false,
                                                        height: 260,
                                                        plugins: "link image code",
                                                        toolbar: "undo redo bold italic alignleft aligncenter alignright code",
                                                    }}
                                                    onEditorChange={(content) => setFormData({ ...formData, description: content })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <Loading />
                        )}
                    </div>
                )}
            </div>
            <div className={"d-flex " + (props.inModal ? "modal-footer" : "")}>
                <button
                    competition="button"
                    className={"btn btn-primary " + (!props.inModal ? "ml-auto" : "flex-fill")}
                    onClick={() => competitionLib.addCompetition(addCompetition, formData)}
                    disabled={addLoading}
                >
                    {formData.hashID && formData.hashID != 0 ? translate.t("Edit") : translate.t("Add")}
                </button>
                {props.inModal && (
                    <button competition="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                )}
            </div>
        </Fragment>
    );
};

export default withRouter(PrimaryData);
