/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditRankingPoint from "./AddEditRankingPoint";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_RANKING_POINTS, DELETE_RANKING_POINTS, ADD_EDIT_RANKING_POINTS } from "../Queries/rankingPoint";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import SearchInput from "../../General/Components/SearchInput";

const RankingPoints = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    helper.checkAdminRights({ ...props, client }, ["1", "2"]);

    const [listVariables, setListVariables] = useState({
        rankingPointTableID: props.rankingPointTableID,
        filters: {
            offset: 0,
            limit: 50,
            searchQuery: "",
        },
    });

    const [formData, setFormData] = useState({
        rankList: [],
    });

    const { error: getError, refetch } = useQuery(GET_RANKING_POINTS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (data) {
                const newFormData = Array.from({ length: data.rankingPointTable.scoringPositionsNumber }, (_, index) => {
                    const item = data.allRankingPoints[index];
                    return {
                        rankingPointID: item ? item.rankingPointID : 0,
                        rankingPointTableID: props.rankingPointTableID,
                        rank: item ? item.rank : index + 1,
                        points: item ? item.points : 0,
                    };
                });
                setFormData({ ...formData, rankList: newFormData });
            }
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const [addEditRankingPoints, { loading: addLoading }] = useMutation(ADD_EDIT_RANKING_POINTS, {
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));
            refetch();
            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const handleInputChange = (index, field, value) => {
        const newRankList = [...formData.rankList];
        newRankList[index][field] = value;
        setFormData({ ...formData, rankList: newRankList });
    };

    const validateFormData = () => {
        for (let item of formData.rankList) {
            if (!item.rank) {
                return false;
            }
        }
        return true;
    };

    const handleSubmit = () => {
        if (!validateFormData()) {
            let notify = new Notification();
            notify.setNotification(translate.t("AllRowsMustHavePositionAndPointsFilled"), "danger");
            return;
        }

        const dataToSend = formData.rankList.map((item) => ({
            ...item,
            rank: parseInt(!!item.rank ? item.rank : 0),
            points: parseInt(!!item.points ? item.points : 0),
        }));

        addEditRankingPoints({ variables: { addEditData: dataToSend } });
    };

    if (getError) {
        var error = getError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(getError);
    }
    return (
        <div>
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">{translate.t("RankingPointList")}</div>
                        <div className="card-body">
                            {!error ? (
                                <>
                                    <div>
                                        <div className="data-list">
                                            <div className="data-list-item header">
                                                <div className="data-list-item-content">
                                                    <div>{translate.t("Rank")}</div>
                                                    <div className="text-right">{translate.t("PointsCountToAdd")}</div>
                                                </div>
                                            </div>
                                            <Fragment>
                                                {formData.rankList.map((item, index) => {
                                                    return (
                                                        <div key={index} className="data-list-item-content">
                                                            <div className="">{item.rank}</div>
                                                            <div className="d-flex justify-content-end">
                                                                <input
                                                                    type="number"
                                                                    className="form-control form-control-sm text-center"
                                                                    step={1}
                                                                    inputMode="numeric"
                                                                    value={item.points}
                                                                    onChange={(e) => handleInputChange(index, "points", e.target.value)}
                                                                    style={{ width: "70px" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </Fragment>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>

                    <div className="d-flex justify-content-end mt-4">
                        <button type="button" className="btn btn-primary mr-2" onClick={() => handleSubmit()}>
                            {translate.t("Save")}
                        </button>
                        <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                            {translate.t("Close")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RankingPoints;
