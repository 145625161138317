import React, { Fragment } from "react";
import DragColumnElement from "./DragColumnElement";
import { useReactiveVar } from "@apollo/client";
import { cmDataVar } from "../Library/cmData";
import { addDragColumn, removeElement, selectElement, setElementData, showSettings } from "../Library/contentManager";
import translate from "../../Translations/index";

const DragColumnsElement = ({ handle, element, lastIndexes, fullWidth, index }) => {
    const cmData = useReactiveVar(cmDataVar);
    const getColumnClass = (type, value) => {
        var className = "col" + type;
        switch (value) {
            case "100":
                className += "-12";
                break;
            case "75":
                className += "-9";
                break;
            case "66":
                className += "-8";
                break;
            case "50":
                className += "-6";
                break;
            case "33":
                className += "-4";
                break;
            case "25":
                className += "-3";
                break;
            case "16":
                className += "-2";
                break;
        }

        return className;
    };

    var parentIndexes = "";

    var indexes = lastIndexes.split("-");
    if (indexes.length > 2) {
        indexes.pop();
        parentIndexes = indexes.join("-");
    }
    return (
        <div className={`cm-columns`} onClick={(e) => selectElement(e, "drag_columns", lastIndexes)}>
            {cmData.showHelpers && element.showMenu && (
                <div className={`cm-menu cm-col`} onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex">
                        <div className="mr-1">
                            <button onClick={() => addDragColumn(lastIndexes)}>{translate.t("AddColumn")}</button>
                        </div>

                        {false && (
                            <div className="mr-1">
                                <div className={`item-container`} onClick={() => showSettings("drag_columns", lastIndexes)}>
                                    <i className={`las la-cog text-primary `} title={translate.t("ModuleSettings")} />
                                </div>
                                {element.showSettings && (
                                    <Fragment>
                                        <div className={`settings`}>
                                            <div className="mb-1">
                                                <div className="input-group input-group-sm">
                                                    <div class="input-group-prepend">
                                                        <div className="input-group-text">{translate.t("Class")}:</div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        value={element.className}
                                                        className="form-control form-control-sm"
                                                        name="className"
                                                        onChange={(e) => setElementData(e, lastIndexes, "drag_columns")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`cm-closing-overlay`} onClick={() => showSettings("drag_columns", lastIndexes)}></div>
                                    </Fragment>
                                )}
                            </div>
                        )}
                        {parentIndexes != "" && (
                            <div className="mr-1">
                                <div
                                    className={`d-flex align-items-center justify-content-center item-container`}
                                    onClick={(e) => selectElement(e, "drag_column", parentIndexes)}
                                >
                                    <i className={`las la-level-up-alt text-secondary `} title={translate.t("ParentElement")} />
                                </div>
                            </div>
                        )}
                        <div>
                            <div className={`d-flex align-items-center justify-content-center mr-1 item-container`} onClick={() => removeElement(lastIndexes)}>
                                <i className={`las la-times text-danger `} title={translate.t("RemoveElement")} />
                            </div>
                        </div>
                        <div className={`cm-dragable-item`} {...handle}>
                            <div className={`d-flex align-items-center justify-content-center item-container cursor-move}`}>
                                <i className={`las la-arrows-alt-v text-primary`} title={translate.t("MoveElement")} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className={`container`}>
                <div className={"row " + element.className}>
                    {element.cols.length > 0 &&
                        element.cols.map((item, index) => {
                            var colClasses = "";
                            if (item.xlCols) colClasses += getColumnClass("-xl", item.xlCols) + " ";
                            if (item.lgCols) colClasses += getColumnClass("-lg", item.lgCols) + " ";
                            if (item.mdCols) colClasses += getColumnClass("-md", item.mdCols) + " ";
                            if (item.smCols) colClasses += getColumnClass("-sm", item.smCols) + " ";
                            if (item.xsCols) colClasses += getColumnClass("", item.xsCols) + " ";

                            return (
                                <div key={index} className={`col cm-column ${colClasses} d-flex`}>
                                    <DragColumnElement
                                        colsLength={element.cols.length}
                                        item={item}
                                        index={index}
                                        lastIndexes={lastIndexes}
                                        fullWidth={fullWidth}
                                    />
                                </div>
                            );
                        })}

                    {cmData.showHelpers && <div className={`cm-col-border ${element.showMenu ? `cm-selected` : ""}`}></div>}
                </div>
            </div>
        </div>
    );
};

export default DragColumnsElement;
