/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import logo from "../Public/Images/logo.png";
import menu from "../Public/Images/menu.svg";
import door from "../Public/Images/door.svg";
import { ADMIN_USER } from "../GlobalQueries/globalQueries";
import { ApolloConsumer } from "@apollo/client";
import withApollo from "../Library/withApollo";

class Header extends Component {
    constructor(props) {
        super(props);
        this.showSideBar = this.showSideBar.bind(this);
        this.hideSideBar = this.hideSideBar.bind(this);
        this.toogleSubmenu = this.toogleSubmenu.bind(this);

        this.logout = this.logout.bind(this);
    }
    showSideBar(e) {
        e.preventDefault();
        if (this.state.cl) {
            this.setState({ cl: "" });
        } else {
            this.setState({ cl: "open" });
        }
    }
    hideSideBar(e) {
        this.setState({ cl: "" });
    }

    logout(e, client) {
        e.preventDefault();
        client.resetStore();

        localStorage.removeItem("token");
        localStorage.removeItem("loggedUser");

        window.location.reload();
    }

    toogleSubmenu(e, menu, prevent) {
        if (prevent) e.preventDefault();

        var arr = this.state.showSubmenu.map(function (item, index) {
            if (index !== menu) return { ...item, show: false };
            else return { ...item, show: !item.show };
        });

        if (prevent) this.setState({ ...this.state, showSubmenu: arr });
        else this.setState({ ...this.state, showSubmenu: arr, cl: "" });
    }

    render() {
        const authUser = localStorage.getItem("loggedUser");
        return (
            <div id="header">
                <ul className="d-flex m-0 p-0 main">
                    <li className="logo">
                        <img alt="Urbiathlon" src={logo} />
                    </li>
                    <li className="user ml-auto">
                        <NavLink to="/settings/change-password">{authUser}</NavLink>
                    </li>
                    <li className="">
                        <ApolloConsumer>
                            {(client) => (
                                <button onClick={(e) => this.logout(e, client)}>
                                    <img src={door} />
                                </button>
                            )}
                        </ApolloConsumer>
                    </li>
                </ul>
            </div>
        );
    }
}

export default withApollo(withRouter(Header));
