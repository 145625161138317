/**
 * hlavní sekce
 */

const SECTION = {
    backgroundColorClass: "",
    backgroundImage: "",
    sectionName: "",
    fullWidth: 0,
    fullHeight: 0,
    paddingClass: "padding",
    showAddElements: false,
    elements: [],
    className: "",
};

/**
 * Seznam veškerých elementů
 */

const ELEMENTS_LIST = [
    "alert",
    "banner",
    "drag_columns",
    "text",
    "image",
    "zpravodaj",
    "article",
    "info_bordered_blocks",
    "service",
    "form",
    "faq",
    "columns",
    "headline",
    "paragraph",
    "button",
    "video",
    "articles",
    "newsletter",
    "work_in_numbers",
    "social_networks",
    "contact_form",
    "html",
    "tournaments",
    "athletes",
    "ladders",
    "federationList",
    "clubList",
    "sportList",
];

/**
 * definice elementu sloupce
 */

const COLUMN = {
    showAddElements: false,
    showSettings: false,
    showMenu: true,
    contentAlign: "start",
    columnStyle: "default",
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    elements: [],
    className: "",
};

/**
 * definice elementu seřazeného sloupce
 */

const DRAG_COLUMN = {
    showAddElements: false,
    showSettings: false,
    showMenu: false,
    contentAlign: "start",
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    xlCols: "25",
    lgCols: "25",
    mdCols: "25",
    smCols: "25",
    xsCols: "25",
    elements: [],
    className: "",
};

/**
 * definice elementu kontejneru pro seřazeného sloupce
 */

const DRAG_COLUMNS = {
    drag_columns: {
        showMenu: true,
        className: "",
        cols: [DRAG_COLUMN],
    },
};

/**
 * Definice elementu nadpis
 */

const HEADLINE = (langs) => {
    var text = {};
    var subHeadlineText = {};

    for (let i in langs) {
        text[langs[i].suffix] = "Nadpis sekce " + langs[i].suffix;
        subHeadlineText[langs[i].suffix] = "Podnadpis dané sekce s trošku delším textem " + langs[i].suffix;
    }

    return {
        headline: {
            showMenu: true,
            showSettings: false,
            type: "h1",
            text: text,
            textAlign: "text-center",
            hasSubHeadline: 1,
            subHeadlineText: subHeadlineText,
            className: "",
        },
    };
};

const PARAGRAPH = (langs) => {
    var text = {};

    for (let i in langs) {
        text[langs[i].suffix] = "Text daného odstavce " + langs[i].suffix;
    }

    return {
        paragraph: {
            showMenu: true,
            showSettings: false,
            showLinkSettings: false,
            text: text,
            textAlign: "text-left",
            fontSize: "small",
            className: "",
        },
    };
};

const IMAGE = {
    image: {
        showMenu: true,
        showSettings: false,
        url: "",
        imageAlign: "center",
        width: 300,
        height: 300,
        useDimensions: 0,
        borderRadius: 0,
        className: "",
        title: "",
    },
};

const BANNER = {
    banner: {
        showMenu: true,
        showSettings: false,
        htmlTitle: "<p>Nadpis banneru</p>",
        htmlText: "Text banneru",
        linkUrl: "",
        buttonText: "Text tlačítka",
        htmlButtonText: "<p>Text tlačítka</p>",
        showButton: "1",
        className: "",
        imgList: [],
    },
};

const TOURNAMENTS = {
    tournaments: {
        showMenu: true,
        showSettings: false,
        showFilters: true,
    },
};
const LADDERS = {
    ladders: {
        showMenu: true,
        showSettings: false,
        showFilters: true,
    },
};
const ATHLETES = {
    athletes: {
        showMenu: true,
        showSettings: false,
        showFilters: true,
    },
};
const FEDERATION_LIST = {
    federationList: {
        showMenu: true,
        showSettings: false,
    },
};
const CLUB_LIST = {
    clubList: {
        showMenu: true,
        showSettings: false,
    },
};
const SPORT_LIST = {
    sportList: {
        showMenu: true,
        showSettings: false,
    },
};

const ARTICLES = {
    articles: {
        showMenu: true,
        showSettings: false,
        pageID: 0,
        articlesOnPage: "3",
        showHeader: "1",
        title: "Nadpis bloku",
        htmlTitle: "<p>Nadpis bloku</p>",
        headerLinkTitle: "Zobrazit všechny",
        htmlHeaderLinkTitle: "<p>Zobrazit všechny</p>",
        class: "",
    },
};

const INFO_BORDERED_BLOCK = {
    showAddElements: false,
    showSettings: false,
    showMenu: false,
    className: "",
    title: "Nadpis bloku",
    htmlTitle: "<p>Nadpis bloku</p>",
    text: "Text anotace",
    linkText: "Odkaz někam jinam",
    htmlLinkText: "<p>Odkaz někam jinam</p>",
    icon: "las la-question-circle",
    url: "",
    showLink: "1",
};

const INFO_BORDERED_BLOCKS = {
    info_bordered_blocks: {
        showMenu: true,
        className: "",
        blockCountOnRow: 3,
        showTitle: "1",
        title: "Nadpis bloku",
        htmlTitle: "<p>Nadpis bloku</p>",
        cols: [INFO_BORDERED_BLOCK],
    },
};
const NEWSLETTER = {
    newsletter: {
        showMenu: true,
        showSettings: false,
        className: "",
        buttonText: "Přihlásit se k odběru novinek",
        htmlButtonText: "<p>Přihlásit se k odběru novinek</p>",
        icon: "la-envelope-open-text",
        title: "Buďte v obraze. Odebírejte náš newsletter.",
        htmlTitle: "<p>Buďte v obraze. Odebírejte náš newsletter.</p>",
    },
};
const WORK_IN_NUMBERS = {
    work_in_numbers: {
        showMenu: true,
        showSettings: false,
        className: "",
        sideBlockTitle: "Naše práce v číslech",
        htmlSideBlockTitle: "<p>Naše práce v číslech</p>",
        sideBlockText: "Kolegium expertů pro výkon rozsudků Evropského soudu pro lidská práva je poradním orgánem vládního.",
        sideBlockLinkText: "Více o naši kanceláři",
        htmlSideBlockLinkText: "<p>Více o naši kanceláři</p>",
        block1Text: "Počet rozhodnutí z Evropského soudu pro lidská práva",
        block2Text: "Počet rozhodnutí z Ústavního soudu ČR",
        block3Text: "Tématických příruček pro soudce a právníky",
        block4Text: "Mezinárodních úmluv",
        number1Text: "1234",
        htmlNumber1Text: "1234",
        number2Text: "1234",
        htmlNumber2Text: "1234",
        number3Text: "1234",
        htmlNumber3Text: "1234",
        number4Text: "1234",
        htmlNumber4Text: "1234",
        showLink: "1",
        staticNumbers: "0",
        linkUrl: "",
    },
};
const SOCIAL_NETWORKS = {
    social_networks: {
        showMenu: true,
        showSettings: false,
        class: "",
        showTitle: "1",
        title: "Nadpis bloku",
        htmlTitle: "<p>Nadpis bloku</p>",
        showFacebook: "1",
        facebookUrl: "",
        showX: "1",
        xUrl: "",
        showSpotify: "1",
        spotifyUrl: "",
        showGooglePodcast: "1",
        googlePodcastUrl: "",
        showAmazonMusic: "1",
        amazonMusicUrl: "",
        showApple: "1",
        appleUrl: "",
        podcastBlockTitle: "Děláme podcast Mezisoudy",
        htmlPodcastBlockTitle: "<p>Děláme podcast Mezisoudy</p>",
        podcastBlockText: "Podcast Kanceláře vládního zmocněnce o mezinárodní judikatuře lidských práv. Sledujte nás na podcastových platformách.",
        htmlPodcastBlockText: "<p>Podcast Kanceláře vládního zmocněnce o mezinárodní judikatuře lidských práv. Sledujte nás na podcastových platformách.</p>",
        showXembed: "1",
        xEmbedCode: "",
    },
};
const CONTACT_FORM = {
    contact_form: {
        showMenu: true,
        showSettings: false,
        class: "",
    },
};

const BUTTON = {
    button: {
        showMenu: true,
        showSettings: false,
        linkUrl: "",
        buttonText: "Text tlačítka",
        htmlButtonText: "<p>Text tlačítka</p>",
        color: "",
        className: "",
    },
};

const VIDEO = (langs) => {
    var url = {};
    var type = {};
    var mp4FileUrl = {};
    var mp4FilePosterUrl = {};
    var mp4File = {};
    var mp4FilePoster = {};

    for (let i in langs) {
        url[langs[i].suffix] = "";
        type[langs[i].suffix] = "youtube";
        mp4File[langs[i].suffix] = "";
        mp4FileUrl[langs[i].suffix] = "";
        mp4FilePoster[langs[i].suffix] = "";
        mp4FilePosterUrl[langs[i].suffix] = "";
    }

    return {
        video: {
            showMenu: true,
            showSettings: false,
            url: url,
            type: type,
            mp4File,
            mp4FileUrl,
            mp4FilePoster,
            mp4FilePosterUrl,
            mp4Align: "center",
            mp4Width: 300,
            mp4Height: 300,
            useDimensions: 0,
            changeVideo: false,
            className: "",
        },
    };
};

const TEXT = {
    text: {
        showMenu: true,
        showSettings: false,
        className: "",
        text: "",
    },
};

const HTML_ELM = (langs) => {
    var code = {};

    for (let i in langs) {
        code[langs[i].suffix] = "";
    }

    return {
        html: {
            showMenu: true,
            showSettings: false,
            code: code,
            type: "html",
            className: "",
        },
    };
};

const FAQ_ELM_QUESTION = (langs) => {
    var text = {};
    var title = {};

    for (let i in langs) {
        text[langs[i].suffix] = "Text pro odpověď " + langs[i].suffix;
        title[langs[i].suffix] = "Otázka " + langs[i].suffix;
    }

    return {
        text: text,
        title: title,
    };
};

const FAQ_ELM = (langs) => {
    var q = [];
    q.push(FAQ_ELM_QUESTION(langs));

    return {
        faq: {
            showMenu: true,
            showSettings: false,
            className: "",
            questions: q,
        },
    };
};

const FORM_ELM = (langs) => {
    var text = {};

    for (let i in langs) {
        text[langs[i].suffix] = "Odeslat " + langs[i].suffix;
    }

    return {
        form: {
            showMenu: true,
            showSettings: false,
            className: "",
            formID: 0,
            color: "",
            size: "",
            text,
        },
    };
};

const SERVICE_ELM = (langs) => {
    return {
        service: {
            showMenu: true,
            showSettings: false,
            className: "",
            serviceID: 0,
            color: "",
        },
    };
};

const ALERT = (langs) => {
    var text = {};

    for (let i in langs) {
        text[langs[i].suffix] = "Vše broběhlo parádně " + langs[i].suffix;
    }

    return {
        alert: {
            showMenu: true,
            showSettings: false,
            text: text,
            type: "success",
            className: "",
        },
    };
};

export {
    SECTION,
    COLUMN,
    TEXT,
    IMAGE,
    TOURNAMENTS,
    LADDERS,
    ATHLETES,
    ARTICLES,
    BANNER,
    INFO_BORDERED_BLOCKS,
    INFO_BORDERED_BLOCK,
    HEADLINE,
    ELEMENTS_LIST,
    PARAGRAPH,
    BUTTON,
    VIDEO,
    NEWSLETTER,
    WORK_IN_NUMBERS,
    SOCIAL_NETWORKS,
    CONTACT_FORM,
    HTML_ELM,
    FAQ_ELM,
    FAQ_ELM_QUESTION,
    FORM_ELM,
    SERVICE_ELM,
    DRAG_COLUMNS,
    DRAG_COLUMN,
    ALERT,
    FEDERATION_LIST,
    CLUB_LIST,
    SPORT_LIST,
};
