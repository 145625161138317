/**
 * soubor se seznamem veškerých graphql dotazů pro content manager
 */
import gql from "graphql-tag";

export const UPLOAD_CM_IMAGE = gql`
    mutation UploadCmImage($file: Upload!, $fileToDelete: String) {
        uploadCmImage(file: $file, fileToDelete: $fileToDelete)
    }
`;

export const UPLOAD_CM_VIDEO = gql`
    mutation UploadCmVideo($file: Upload!, $fileName: String) {
        uploadCmVideo(file: $file, fileName: $fileName)
    }
`;

export const REMOVE_CM_IMAGE = gql`
    mutation RemoveCmImage($file: String!) {
        removeCmImage(file: $file)
    }
`;
export const REMOVE_CM_VIDEO = gql`
    mutation RemoveVideo($file: String!) {
        removeCmVideo(file: $file)
    }
`;

export const UPDATE_CM_CONTENT = gql`
    mutation UpdateLinkContent($content: String, $linkID: ID) {
        updateLinkContent(content: $content, linkID: $linkID)
    }
`;

export const CONTENT_MANAGER_PAGE_LIST = gql`
    query PageListData {
        nestedPages {
            pageID
            pageContentTypeID
            pageStatusID
            name
            slug
            level
            parentID

            subpages {
                pageID
                pageContentTypeID
                pageStatusID
                name
                slug
                level
                parentID

                subpages {
                    pageID
                    pageContentTypeID
                    pageStatusID
                    name
                    slug
                    level
                    parentID

                    subpages {
                        pageID
                        pageContentTypeID
                        pageStatusID
                        name
                        slug
                        level
                        parentID

                        subpages {
                            pageID
                            pageContentTypeID
                            pageStatusID
                            name
                            slug
                            level
                            parentID
                        }
                    }
                }
            }
        }
    }
`;
export const CONTENT_MANAGER_ARTICLE_LIST = gql`
    query ArticleList($pageID: ID, $filters: ArticleFilters) {
        articles(pageID: $pageID, filters: $filters) {
            pageArticleID
            title
            anotation
            text
            publicationDate
            categories {
                pageCategoryID
                name
            }
            creator {
                firstName
                lastName
            }
        }
        articleListCount(pageID: $pageID, filters: $filters)
    }
`;

export const WORK_IN_NUMBERS_STATISTICS = gql`
    query WorkInNumbersStatistics {
        eslpDecisionCount: decisionCount(filters: { sourceDbIDIN: [1] })
        usDecisionCount: decisionCount(filters: { sourceDbIDIN: [2] })
        conventionCount: conventionListCount
    }
`;

export const LAST_KVZ = gql`
    query Kvzs {
        lastKvzNumber {
            kvzID
            number
            year
            anotation
            file
            files {
                file
            }
        }
    }
`;
export const SOCIAL_NETWORKS = gql`
    query SocialNetworks {
        socialNetworks: footerSocialNetworks(filters: { active: 1 }) {
            url
            icon
            name
        }
    }
`;
