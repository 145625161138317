/**
 *  komponenta pro štítky pro produkty
 */
import React, { Component, Fragment, useState } from "react";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_DISCIPLINE_LADDERS, GET_DISCIPLINE } from "../Queries/discipline";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import DisciplineLib from "../Library/discipline";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import AddLadderToDiscipline from "./AddLadderToDiscipline";
import { withRouter } from "react-router-dom";
import { DELETE_LADDERS_FROM_DISCIPLINE } from "../../Ladder/Queries/ladder";

const Ladders = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    //const helper = new Helper();
    //const adminUserData = helper.checkAdminRights(props,["1","2","3"]);

    const listVariables = {
        disciplineHashID: props.match.params.disciplineHashID,
        lang: translate.locale,
    };

    const [selectedLadders, setSelectedLadders] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const {
        loading,
        error: getError,
        data: ladderListData,
        fetchMore,
        refetch,
    } = useQuery(GET_DISCIPLINE_LADDERS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
    });

    const {
        loading: getDisciplineLoading,
        error: getDisciplineError,
        data: disciplineData,
    } = useQuery(GET_DISCIPLINE, {
        variables: { hashID: props.match.params.disciplineHashID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
    });

    const [deleteLadders, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_LADDERS_FROM_DISCIPLINE, {
        update(cache, response) {
            let disciplineLib = new DisciplineLib(this);
            disciplineLib.updateLadderListAfterDelete(cache, response, listVariables);

            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));

            setSelectedLadders([]);
            setShowDeleteNotifi(false);
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, ladderHashID) => {
        setShowAdd(open);
    };

    /**
     * smazání
     * @param {Event} e event kliku
     * @param {String} hashID ID disciplineu
     */

    const initiateDeleteLadder = (e, hashID) => {
        var checked = e.target.checked;
        var arr = [...selectedLadders];

        if (!checked) {
            for (let i in selectedLadders) {
                if (selectedLadders[i] == hashID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [hashID, ...arr];
        }

        setSelectedLadders(arr);
    };

    const delLadder = (action) => {
        if (action) {
            let hashIDs = selectedLadders.join(",");

            deleteLadders({
                variables: {
                    hashIDs: hashIDs,
                    disciplineHashID: props.match.params.disciplineHashID,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedLadders.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <Fragment>
            <div className="mb-3">
                <div className="d-flex align-items-center discipline-title">
                    {translate.t("Ladders")}
                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                        {translate.t("AddLadder")}
                    </button>
                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                        {translate.t("DeleteSelected")}
                    </button>
                </div>
                <div className="">
                    {!error ? (
                        <div>
                            <div className="data-list">
                                <div className="mb-4">{translate.t("AddLaddersToWhichCalculateResults")}</div>
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div>{translate.t("Ladder")}</div>
                                        <div className="text-right">{translate.t("Options")}</div>
                                    </div>
                                </div>
                                <Fragment>
                                    {ladderListData?.allDisciplineLadders?.map((item, index) => {
                                        var checked = false;
                                        for (let i in selectedLadders) {
                                            if (selectedLadders[i] == item.hashID) checked = true;
                                        }

                                        return (
                                            <div key={index} className="data-list-item-content">
                                                <div>
                                                    <div>{item.title}</div>
                                                </div>
                                                <div className="text-right">
                                                    <input
                                                        className="delete-checked"
                                                        type="checkbox"
                                                        name="deleteLadders[]"
                                                        checked={checked}
                                                        onChange={(e) => initiateDeleteLadder(e, item.hashID)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Fragment>
                            </div>

                            {!loading ? (
                                <Fragment>
                                    {ladderListData?.allDisciplineLadders?.length == 0 ? (
                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                    ) : (
                                        <Fragment>
                                            {!loading &&
                                                !!ladderListData &&
                                                ladderListData?.allDisciplineLadders?.length < ladderListData?.disciplineListCount && (
                                                    <div className="text-center p-5">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() =>
                                                                fetchMore({
                                                                    variables: { ...listVariables, offset: ladderListData.allDisciplineLadders.length },
                                                                    updateQuery: (prev, { fetchMoreResult }) => {
                                                                        if (!fetchMoreResult) return prev;
                                                                        return Object.assign({}, prev, {
                                                                            allDisciplineLadders: [
                                                                                ...prev.allDisciplineLadders,
                                                                                ...fetchMoreResult.allDisciplineLadders,
                                                                            ],
                                                                        });
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            {" "}
                                                            {translate.t("LoadMore")}{" "}
                                                        </button>
                                                    </div>
                                                )}
                                        </Fragment>
                                    )}
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    ) : (
                        <Fragment>{error && <Error text={error} />}</Fragment>
                    )}
                </div>
            </div>
            {showAdd && (
                <AddLadderToDiscipline openCloseModal={openCloseModal} disciplineHashID={props.match.params.disciplineHashID} updated={() => refetch()} />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delLadder} />}
        </Fragment>
    );
};

export default withRouter(Ladders);
