/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit performanceCategory
 */
export const ADD_EDIT_PERFORMANCE_CATEGORY = gql`
    mutation AddEditPerformanceCategory($addEditData: AddEditPerformanceCategoryDataInput) {
        addEditPerformanceCategory(addEditData: $addEditData) {
            performanceCategoryID
            hashID
            sportID
            title
        }
    }
`;

/**
 *  PerformanceCategory data
 */

export const GET_PERFORMANCE_CATEGORY = gql`
    query PerformanceCategoryWithLangs($performanceCategoryID: ID!, $lang: String) {
        performanceCategoryWithLangs(performanceCategoryID: $performanceCategoryID) {
            performanceCategoryID
            sportID
            langs {
                lang
                title
                description
            }
        }
        allLanguageMutations(onlyEnabled: true) {
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 *  Seznam veškerých performanceCategoryů
 */
export const GET_PERFORMANCE_CATEGORIES = gql`
    query AllPerformanceCategories($filters: PerformanceCategoryFilters, $lang: String) {
        allPerformanceCategories(filters: $filters) {
            performanceCategoryID
            hashID
            sportID
            title
            sport(lang: $lang) {
                title
            }
        }
        performanceCategoryListCount(filters: $filters)

        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_PERFORMANCE_CATEGORIES = gql`
    mutation deletePerformanceCategories($performanceCategoryIDs: ID!) {
        deletePerformanceCategories(performanceCategoryIDs: $performanceCategoryIDs)
    }
`;
