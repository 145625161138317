/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit country
 */
export const ADD_EDIT_COUNTRY = gql`
    mutation AddEditCountry($addEditData: AddEditCountryDataInput) {
        addEditCountry(addEditData: $addEditData) {
            name
        }
    }
`;

/**
 *  Data federace
 */

export const GET_COUNTRY = gql`
    query Country($countryID: ID) {
        country(countryID: $countryID) {
            countryID
            name
            code
        }
    }
`;
export const GET_COUNTRY_PHOTOS = gql`
    query Country($countryID: ID) {
        country(countryID: $countryID) {
            flag
        }
    }
`;

/**
 *  Seznam veškerých federací
 */
export const GET_COUNTRIES = gql`
    query Countries($filters: CountriesFilters) {
        allCountries(filters: $filters) {
            countryID
            name
            code
        }
        countriesCount(filters: $filters)
    }
`;

/**
 * Smazání federací.
 */
export const DELETE_COUNTRIES = gql`
    mutation deleteCountries($countryIDs: ID!) {
        deleteCountries(countryIDs: $countryIDs)
    }
`;
/**
 * Úprava fotek federace
 */
export const EDIT_COUNTRY_PHOTOS = gql`
    mutation EditCountryPhotos($countryID: ID, $flag: Upload, $flagURL: String) {
        editCountryPhotos(countryID: $countryID, flag: $flag, flagURL: $flagURL)
    }
`;
