/**
 *  Třída, která vkládá notifikace
 */
import { Store } from "react-notifications-component";
class Notification {
    setNotification(text, type = "success", duration = 4000) {
        Store.addNotification({
            title: "",
            message: text,
            type,
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration,
                //onScreen true
            },
        });
    }
}

export default Notification;
