/*
    Přidání štítku
*/
import React, { Component, Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_ADMINUSER, GET_ADMINUSER } from "../Queries/adminUser";
import AdminUserLib from "../Library/adminUser";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import refreshIcon from "../../../Public/Images/refresh.svg";

const AddEditAdminUser = (props) => {
    const adminUserLib = new AdminUserLib(props);
    const [formData, setFormData] = useState({
        selectedLang: "cs",
        adminUserID: props.selectedAdminUserID,
        name: "",
        surname: "",
        email: "",
        password: "",
        roleID: 0,
        lang: translate.locale,
    });
    const [allLanguageMutations, setAllLanguageMutations] = useState([]);

    const {
        loading: getLoading,
        error: getError,
        data: adminUserData,
    } = useQuery(GET_ADMINUSER, {
        variables: { adminUserID: formData.adminUserID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            setAllLanguageMutations(data.allLanguageMutations);
            if (formData.adminUserID != 0) {
                setFormData({
                    ...formData,
                    name: data.adminUserByID.name,
                    surname: data.adminUserByID.surname,
                    email: data.adminUserByID.email,
                    roleID: data.adminUserByID.roleID,
                    isCurrencyExchange: data.adminUserByID.isCurrencyExchange,
                });
            } else {
                generateNewPwd();
            }
        },
    });

    const [addAdminUser, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_ADMINUSER, {
        onCompleted(data) {
            if (formData.adminUserID && formData.adminUserID != 0) {
                props.openCloseModal(false);
            } else {
                setFormData({ ...formData, adminUserID: data.addEditAdminUser.adminUserID });

                props.openCloseModal(false);
            }
        },
        update(cache, response) {
            adminUserLib.updateList(cache, response, formData.adminUserID, props.listVariables);

            let notify = new Notification();

            if (formData.adminUserID && formData.adminUserID != 0) notify.setNotification(translate.t("SuccessfullySaved"));
            else notify.setNotification(translate.t("SuccessfullyAdded"));
        },
    });

    const generateNewPwd = () => {
        const helper = new Helper();
        setFormData({ ...formData, password: helper.makeid(6) });
    };

    if (getError || addError) {
        var error = getError ? getError : addError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }

    return (
        <Modal className="Modal__Bootstrap modal-dialog modal-md" closeTimeoutMS={150} isOpen={true} onRequestClose={() => props.openCloseModal(false)}>
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {formData.adminUserID && formData.adminUserID != 0 ? translate.t("EditAdminUser") : translate.t("AddAdminUser")}
                    </h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 && (
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" onChange={(e) => adminUserLib.formHandle(e)}>
                                {allLanguageMutations &&
                                    allLanguageMutations.map((item, index) => (
                                        <option key={index} value={item.suffix}>
                                            {" "}
                                            {item.suffix}{" "}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                    <button adminUser="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">{translate.t("Name")}</label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        adminUser="text"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={(e) => adminUserLib.formHandle(e, formData, setFormData)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">{translate.t("Surname")}</label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        adminUser="text"
                                                        name="surname"
                                                        value={formData.surname}
                                                        onChange={(e) => adminUserLib.formHandle(e, formData, setFormData)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">{translate.t("Email")}</label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        adminUser="text"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={(e) => adminUserLib.formHandle(e, formData, setFormData)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                {formData.adminUserID && formData.adminUserID == 0 ? (
                                                    <label className="input-label">{translate.t("Password")}</label>
                                                ) : (
                                                    <label className="input-label">{translate.t("NewPassword")}</label>
                                                )}
                                                <div className="input-group mb-2">
                                                    <input
                                                        className="form-control"
                                                        adminUser="text"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={(e) => adminUserLib.formHandle(e, formData, setFormData)}
                                                    />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <img className="hp-30 cursor-pointer" onClick={() => generateNewPwd()} src={refreshIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">{translate.t("Role")}</label>
                                                <div className="form-group">
                                                    <select
                                                        value={formData.roleID}
                                                        className="form-control"
                                                        name="roleID"
                                                        onChange={(e) => adminUserLib.formHandle(e, formData, setFormData)}
                                                    >
                                                        <option value={0}>-- {translate.t("ChooseRole")} --</option>
                                                        {adminUserData &&
                                                            adminUserData.allRolesByRoleID.map((item, index) => (
                                                                <option key={index} value={item.roleID}>
                                                                    {" "}
                                                                    {item.title}{" "}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <button adminUser="button" className="btn btn-primary flex-fill" onClick={() => adminUserLib.addAdminUser(addAdminUser, formData)}>
                        {formData.adminUserID && formData.adminUserID != 0 ? translate.t("Edit") : translate.t("Add")}
                    </button>
                    <button adminUser="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddEditAdminUser;
