/*
    Přidání kategorie
*/
import React, { Component, Fragment } from "react";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_LINK, GET_LINKS, EDIT_LINK_CONTENT } from "../Queries/index.js";
import SelectSubLink from "./SelectSubLink";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import LinkLib from "../Library/link";
import translate from "../../Translations/index";
import { Query, Mutation } from "@apollo/client/react/components";
import withApollo from "../../../Library/withApollo.js";
import ContentManager from "../../ContentManager/Components/ContentManager.js";
import client from "../../../Library/apolloClient";

const INITIAL_STATE = {
    err: "",
    allLanguageMutations: [],
    linkContentTypes: [],
    selectedTab: 1,
    initialPageText: "",
    formData: {
        selectedLang: "cs",
        linkContentTypeID: "1",
        active: 1,
        parentID: "0",
        inHeader: 1,
        inFooter: 0,
        linkID: 0,
        langs: [],
    },
};

class AddEditLink extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        this.linkLib = new LinkLib(this);
    }

    componentDidMount = () => {
        this.setState({ formData: { ...this.state.formData, linkID: this.props.selectedLinkID || 0 } }, () => {
            this.linkLib.getData(this.state.formData.linkID);
        });
    };

    saveLinkText = async (content) => {
        let notify = new Notification();
        try {
            await client.mutate({
                mutation: EDIT_LINK_CONTENT,
                variables: {
                    linkID: this.state.formData.linkID,
                    lang: this.state.formData.selectedLang,
                    content,
                },
            });
            notify.setNotification(translate.t("SuccessfullySaved"));
        } catch (error) {
            notify.setNotification(!!error.message ? error.message : error.toStrng(), "danger");
        }
    };

    render() {
        var { err, allLanguageMutations, selectedTab } = this.state;

        return (
            <Mutation
                mutation={ADD_EDIT_LINK}
                errorPolicy="all"
                refetchQueries={(data) => {
                    return [
                        {
                            query: GET_LINKS,
                            variables: { lang: "cs" },
                        },
                    ];
                }}
                update={async (cache, response) => {
                    let notify = new Notification();

                    if (this.state.formData.linkID && this.state.formData.linkID != 0) notify.setNotification(translate.t("SuccessfullySaved"));
                    else notify.setNotification(translate.t("SuccessfullyAdded"));
                }}
                onCompleted={(data) => {
                    console.log(data);

                    this.setState({ formData: { ...this.state.formData, linkID: data.addEditLink.linkID } }, () => {
                        this.linkLib.getData(this.state.formData.linkID);
                    });
                    //this.props.openCloseModal(false);
                }}
            >
                {(addEditLink, { loading, error }) => {
                    const { selectedLang, parentID, active, inHeader, inFooter } = this.state.formData;
                    const { selectedLinkID } = this.props;

                    if (error) {
                        const helper = new Helper();
                        err = helper.getApolloErrorText(error);
                    }

                    return (
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-max"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{selectedLinkID && selectedLinkID != 0 ? translate.t("LinkEdit") : translate.t("LinkAdd")}</h4>
                                    {allLanguageMutations && allLanguageMutations.length > 1 && (
                                        <div className="ml-auto">
                                            <select className="form-control" name="selectedLang" onChange={(e) => this.linkLib.formHandle(e)}>
                                                {allLanguageMutations.map((item, index) => (
                                                    <option key={index} value={item.suffix}>
                                                        {" "}
                                                        {item.suffix}{" "}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {err ? (
                                        <Error text={err} />
                                    ) : (
                                        <Fragment>
                                            {!loading ? (
                                                <>
                                                    <ul className={`nav nav-tabs`} role="tablist">
                                                        <li className={`nav-item mr-2`}>
                                                            <a
                                                                className={`nav-link ${selectedTab == 1 ? `active` : ""}`}
                                                                onClick={(e) => this.setState({ selectedTab: 1 })}
                                                            >
                                                                {translate.t("MainData")}
                                                            </a>
                                                        </li>
                                                        <li className={`nav-item mr-2`}>
                                                            <a
                                                                className={`nav-link ${selectedTab == 2 ? `active` : ""}  ${
                                                                    !this.state.formData.linkID ? "disabled" : ""
                                                                }`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (this.state.formData.linkID) this.setState({ selectedTab: 2 });
                                                                }}
                                                            >
                                                                {translate.t("PageContent")}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="myTabContent">
                                                        {selectedTab == 1 && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-4">
                                                                        <label className="input-label">{translate.t("Active")}</label>
                                                                        <div className="form-group">
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="active"
                                                                                    id="active1"
                                                                                    value="1"
                                                                                    checked={active == 1 ? true : false}
                                                                                    onChange={(e) => this.linkLib.formHandle(e)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor="active1">
                                                                                    {translate.t("Yes")}
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="active"
                                                                                    id="active2"
                                                                                    value="0"
                                                                                    checked={active == 0 ? true : false}
                                                                                    onChange={(e) => this.linkLib.formHandle(e)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor="active2">
                                                                                    {translate.t("No")}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4">
                                                                        <label className="input-label">{translate.t("ShowInHeader")}</label>
                                                                        <div className="form-group">
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="inHeader"
                                                                                    id="inHeader1"
                                                                                    value="1"
                                                                                    checked={inHeader == 1 ? true : false}
                                                                                    onChange={(e) => this.linkLib.formHandle(e)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor="inHeader1">
                                                                                    {translate.t("Yes")}
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="inHeader"
                                                                                    id="inHeader2"
                                                                                    value="0"
                                                                                    checked={inHeader == 0 ? true : false}
                                                                                    onChange={(e) => this.linkLib.formHandle(e)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor="inHeader2">
                                                                                    {translate.t("No")}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4">
                                                                        <label className="input-label">{translate.t("ShowInFooter")}</label>
                                                                        <div className="form-group">
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="inFooter"
                                                                                    id="inFooter1"
                                                                                    value="1"
                                                                                    checked={inFooter == 1 ? true : false}
                                                                                    onChange={(e) => this.linkLib.formHandle(e)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor="inFooter1">
                                                                                    {translate.t("Yes")}
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="inFooter"
                                                                                    id="inFooter2"
                                                                                    value="0"
                                                                                    checked={inFooter == 0 ? true : false}
                                                                                    onChange={(e) => this.linkLib.formHandle(e)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor="inFooter2">
                                                                                    {translate.t("No")}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6">
                                                                        <label className="input-label">
                                                                            {translate.t("LinkTitle")}{" "}
                                                                            {allLanguageMutations && allLanguageMutations.length > 1 && (
                                                                                <Fragment>({selectedLang})</Fragment>
                                                                            )}
                                                                        </label>
                                                                        <div className="form-group">
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                name="name"
                                                                                value={this.linkLib.getLangValue("name")}
                                                                                onChange={(e) => this.linkLib.formLangHandle(e)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {!!this.state.formData.linkID && (
                                                                        <div className="col-12 col-sm-6">
                                                                            <label className="input-label">
                                                                                {translate.t("Slug")}{" "}
                                                                                {allLanguageMutations && allLanguageMutations.length > 1 && (
                                                                                    <Fragment>({selectedLang})</Fragment>
                                                                                )}
                                                                            </label>
                                                                            <div className="form-group">
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    name="niceName"
                                                                                    value={this.linkLib.getLangValue("niceName")}
                                                                                    onChange={(e) => this.linkLib.formLangHandle(e)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div className="col-12 col-sm-6">
                                                                        <label className="input-label">{translate.t("ParentLink")}</label>
                                                                        <div className="form-group">
                                                                            <Query query={GET_LINKS} errorPolicy="all" variables={{ lang: "cs" }}>
                                                                                {({ data, loading, error }) => {
                                                                                    if (loading) return <Loading />;
                                                                                    if (error) {
                                                                                        const helper = new Helper();
                                                                                        return <Error text={helper.getApolloErrorText(error)} />;
                                                                                    }

                                                                                    return (
                                                                                        <select
                                                                                            value={parentID}
                                                                                            className="form-control"
                                                                                            name="parentID"
                                                                                            onChange={(e) => this.linkLib.formHandle(e)}
                                                                                        >
                                                                                            <option key={0} value={0}>
                                                                                                {" "}
                                                                                                {translate.t("MainLink")}
                                                                                            </option>

                                                                                            <SelectSubLink links={data.allLinks} />
                                                                                        </select>
                                                                                    );
                                                                                }}
                                                                            </Query>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6">
                                                                        <label className="input-label">{translate.t("ContentType")}</label>
                                                                        <div className="form-group">
                                                                            <select
                                                                                value={this.state.formData.linkContentTypeID}
                                                                                className="form-control"
                                                                                name="linkContentTypeID"
                                                                                onChange={(e) => this.linkLib.formHandle(e)}
                                                                            >
                                                                                {this.state.linkContentTypes.map((item, index) => (
                                                                                    <option key={index} value={item.linkContentTypeID}>
                                                                                        {translate.t(item.title)}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12">
                                                                        <label className="input-label">
                                                                            {translate.t("OwnUrlAddress")}{" "}
                                                                            {allLanguageMutations && allLanguageMutations.length > 1 && (
                                                                                <Fragment>({selectedLang})</Fragment>
                                                                            )}
                                                                        </label>
                                                                        <div className="form-group">
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                name="ownUrl"
                                                                                value={this.linkLib.getLangValue("ownUrl")}
                                                                                onChange={(e) => this.linkLib.formLangHandle(e)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12">
                                                                        <div className="card main">
                                                                            <div className="card-header d-flex align-items-center">{translate.t("SEO")}</div>

                                                                            <div className="card-body">
                                                                                <div className="row">
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <label className="input-label">
                                                                                            {translate.t("MetaTitle")}{" "}
                                                                                            {allLanguageMutations && allLanguageMutations.length > 1 && (
                                                                                                <Fragment>({selectedLang})</Fragment>
                                                                                            )}
                                                                                        </label>
                                                                                        <div className="form-group">
                                                                                            <input
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                name="metaTitle"
                                                                                                value={this.linkLib.getLangValue("metaTitle")}
                                                                                                onChange={(e) => this.linkLib.formLangHandle(e)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-12 col-sm-6">
                                                                                        <label className="input-label">
                                                                                            {translate.t("MetaKeywords")}{" "}
                                                                                            {allLanguageMutations && allLanguageMutations.length > 1 && (
                                                                                                <Fragment>({selectedLang})</Fragment>
                                                                                            )}
                                                                                        </label>
                                                                                        <div className="form-group">
                                                                                            <input
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                name="metaKeywords"
                                                                                                value={this.linkLib.getLangValue("metaKeywords")}
                                                                                                onChange={(e) => this.linkLib.formLangHandle(e)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-12">
                                                                                        <label className="input-label">
                                                                                            {translate.t("MetaDescription")}{" "}
                                                                                            {allLanguageMutations && allLanguageMutations.length > 1 && (
                                                                                                <Fragment>({selectedLang})</Fragment>
                                                                                            )}
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <input
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                name="metaDescription"
                                                                                                value={this.linkLib.getLangValue("metaDescription")}
                                                                                                onChange={(e) => this.linkLib.formLangHandle(e)}
                                                                                            />

                                                                                            <div className="input-group-append">
                                                                                                <span
                                                                                                    className={
                                                                                                        "input-group-text " +
                                                                                                        (this.linkLib.getCharCount("metaDescription") > 160
                                                                                                            ? "red"
                                                                                                            : "")
                                                                                                    }
                                                                                                >
                                                                                                    {this.linkLib.getCharCount("metaDescription")}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-end mt-4">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary flex-fill mr-2"
                                                                        onClick={() => this.linkLib.addLink(addEditLink)}
                                                                    >
                                                                        {this.state.formData.linkID && this.state.formData.linkID != 0
                                                                            ? translate.t("Edit")
                                                                            : translate.t("Add")}
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger"
                                                                        onClick={() => this.props.openCloseModal(false)}
                                                                    >
                                                                        {translate.t("Close")}
                                                                    </button>
                                                                </div>
                                                            </>
                                                        )}
                                                        {selectedTab == 2 && (
                                                            <ContentManager
                                                                content={
                                                                    !!this.state.formData.langs.find((item) => item.lang == selectedLang).content
                                                                        ? this.state.formData.langs.find((item) => item.lang == selectedLang).content
                                                                        : ""
                                                                }
                                                                initialContent={this.state.initialPageText}
                                                                onChange={(content) => this.setState({ pageText: JSON.stringify(content) })}
                                                                onSave={(content) => {
                                                                    this.setState({
                                                                        formData: {
                                                                            ...this.state.formData,
                                                                            langs: this.state.formData.langs.map((item) =>
                                                                                item.lang == selectedLang ? { ...item, content: JSON.stringify(content) } : item
                                                                            ),
                                                                        },
                                                                    });
                                                                    this.saveLinkText(JSON.stringify(content));
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <Loading />
                                            )}
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </Modal>
                    );
                }}
            </Mutation>
        );
    }
}

export default withApollo(AddEditLink);
