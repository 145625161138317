import React, { Fragment } from "react";
import { removeElement, selectElement, setElementData, showSettings } from "../Library/contentManager";
import { cmDataVar } from "../Library/cmData";
import { useReactiveVar } from "@apollo/client";
import TinyMceInlineEditor from "../../TinyMce/Components/TinyMceInlineEditor";
import translate from "../../Translations/index";

const TextElement = ({ lastIndexes, element, handle }) => {
    const cmData = useReactiveVar(cmDataVar);

    var parentIndexes = "";

    var indexes = lastIndexes.split("-");
    if (indexes.length > 2) {
        indexes.pop();
        parentIndexes = indexes.join("-");
    }
    return (
        <div className={`cm-elm-content`}>
            {cmData.showHelpers && element.showMenu && (
                <div className={`cm-menu`} onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex">
                        {false && (
                            <div className="mr-1">
                                <div className={`item-container`} onClick={() => showSettings("text", lastIndexes)}>
                                    <i className={`las la-cog text-primary `} title={translate.t("ModuleSettings")} />
                                </div>
                                {element.showSettings && (
                                    <Fragment>
                                        <div className={`settings`}>
                                            <div className="mb-1"></div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => showSettings("text", lastIndexes)}></div>
                                    </Fragment>
                                )}
                            </div>
                        )}
                        {parentIndexes != "" && (
                            <div className="mr-1">
                                <div
                                    className={`d-flex align-items-center justify-content-center item-container`}
                                    onClick={(e) => selectElement(e, "drag_column", parentIndexes)}
                                >
                                    <i className={`las la-level-up-alt text-secondary `} title={translate.t("ParentElement")} />
                                </div>
                            </div>
                        )}

                        <div className={`d-flex align-items-center justify-content-center mr-1 item-container`} onClick={() => removeElement(lastIndexes)}>
                            <i className={`las la-times text-danger `} title={translate.t("RemoveElement")} />
                        </div>
                        <div className={`cm-dragableItem`} {...handle}>
                            <div className={`d-flex align-items-center justify-content-center item-container cursor-move`}>
                                <i className={`las la-arrows-alt-v text-primary`} title={translate.t("MoveElement")} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div
                className={`cm-text-content`}
                onClick={(e) => {
                    e.stopPropagation();
                    !element.showMenu && selectElement(e, "text", lastIndexes);
                }}
            >
                {cmData.showHelpers ? (
                    <TinyMceInlineEditor
                        value={!!element.text ? element.text : ""}
                        onChange={(content) => setElementData(null, lastIndexes, "text", content, null, "text")}
                    />
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: element.text }}></div>
                )}
            </div>
            {cmData.showHelpers && <div className={`cm-col-border ${element.showMenu ? `cm-selected` : ""}`}></div>}
        </div>
    );
};

export default TextElement;
