import React, { Fragment } from "react";
import AddElement from "./AddElement";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useReactiveVar } from "@apollo/client";
import { cmDataVar } from "../Library/cmData";
import ColElement from "./DragColumnsElement";
import { changeDragColPosition, onDragEnd, removeDragColumn, selectElement, setElementData, showSettings } from "../Library/contentManager";
import TextElement from "./TextElement";
import ArticlesElement from "./ArticlesElement";
import ContactFormElement from "./ContactFormElement";
import BannerElement from "./BannerElement";
import ButtonElement from "./ButtonElement";
import Tournaments from "./TournamentsElement";
import Ladders from "./LaddersElement";
import Athletes from "./AthletesElement";
import translate from "../../Translations/index";
import Federations from "./FederationsElement";
import Clubs from "./ClubElement";
import Sports from "./SportsElement";

const DragColumnElement = ({ lastIndexes, item, index, colsLength, fullWidth }) => {
    const cmData = useReactiveVar(cmDataVar);

    var parentIndexes = "";
    var indexes = lastIndexes.split("-");
    if (indexes.length > 2) {
        indexes.pop();
        parentIndexes = indexes.join("-");
    }

    var positions = [];
    for (let i = 1; i <= colsLength; i++) positions.push(i);

    return (
        <div className={`cm-col-wrapper d-flex`} onClick={(e) => selectElement(e, "drag_column", lastIndexes + "-" + index)}>
            {cmData.showHelpers && item.showMenu && (
                <div className={`cm-menu cm-col`} onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex">
                        <div className="mr-1">
                            <AddElement columnType="drag" lastIndexes={lastIndexes + "-" + index} element={item} />
                        </div>
                        <div className="mr-1">
                            <div className={`item-container`} onClick={() => showSettings("drag_column", lastIndexes + "-" + index)}>
                                <i className={`las la-cog text-primary `} title={translate.t("ModuleSettings")} />
                            </div>
                            {item.showSettings && (
                                <Fragment>
                                    <div className={`settings`}>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text" htmlFor="inputGroupSelect02">
                                                        {translate.t("Position")}:
                                                    </div>
                                                </div>
                                                <select
                                                    className="form-control form-control-sm"
                                                    name="position"
                                                    value={index + 1}
                                                    onChange={(e) => changeDragColPosition(e, lastIndexes, index + 1)}
                                                >
                                                    {positions.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("Align")}:</div>
                                                </div>
                                                <select
                                                    value={item.contentAlign}
                                                    className="form-control form-control-sm"
                                                    name="contentAlign"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                >
                                                    <option value="start"> {translate.t("TopAlign")} </option>
                                                    <option value="center"> {translate.t("CenterAlign")} </option>
                                                    <option value="end"> {translate.t("BottomAlign")} </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">
                                                        {translate.t("TopAlign")}
                                                        {translate.t("ColumnStyle")}:
                                                    </div>
                                                </div>
                                                <select
                                                    value={item.columnStyle}
                                                    className="form-control form-control-sm"
                                                    name="columnStyle"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                >
                                                    <option value="default"> {translate.t("Basic")} </option>
                                                    <option value="bordered"> {translate.t("Bordered")} </option>
                                                    <option value="blue"> {translate.t("BlueStyle")} </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("PaddingTop")}:</div>
                                                </div>
                                                <input
                                                    type="number"
                                                    value={item.paddingTop}
                                                    className="form-control form-control-sm"
                                                    name="paddingTop"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("PaddingRight")}:</div>
                                                </div>
                                                <input
                                                    type="number"
                                                    value={item.paddingRight}
                                                    className="form-control form-control-sm"
                                                    name="paddingRight"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("PaddingBottom")}:</div>
                                                </div>
                                                <input
                                                    type="number"
                                                    value={item.paddingBottom}
                                                    className="form-control form-control-sm"
                                                    name="paddingBottom"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("PaddingLeft")}:</div>
                                                </div>
                                                <input
                                                    type="number"
                                                    value={item.paddingLeft}
                                                    className="form-control form-control-sm"
                                                    name="paddingLeft"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("ColumntWidthBasedOnScreen")}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("MoreThan1400")}</div>
                                                </div>
                                                <select
                                                    className="form-control form-control-sm"
                                                    value={item.xlCols}
                                                    name="xlCols"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                >
                                                    <option value="100">100%</option>
                                                    <option value="75">75%</option>
                                                    <option value="66">66%</option>
                                                    <option value="50">50%</option>
                                                    <option value="33">33%</option>
                                                    <option value="25">25%</option>
                                                    <option value="16">16%</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">991px - 1400px</div>
                                                </div>
                                                <select
                                                    className="form-control form-control-sm"
                                                    value={item.lgCols}
                                                    name="lgCols"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                >
                                                    <option value="100">100%</option>
                                                    <option value="75">75%</option>
                                                    <option value="66">66%</option>
                                                    <option value="50">50%</option>
                                                    <option value="33">33%</option>
                                                    <option value="25">25%</option>
                                                    <option value="16">16%</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">751px - 990px</div>
                                                </div>
                                                <select
                                                    className="form-control form-control-sm"
                                                    value={item.mdCols}
                                                    name="mdCols"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                >
                                                    <option value="100">100%</option>
                                                    <option value="75">75%</option>
                                                    <option value="66">66%</option>
                                                    <option value="50">50%</option>
                                                    <option value="33">33%</option>
                                                    <option value="25">25%</option>
                                                    <option value="16">16%</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">401px - 750px</div>
                                                </div>
                                                <select
                                                    className="form-control form-control-sm"
                                                    value={item.smCols}
                                                    name="smCols"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                >
                                                    <option value="100">100%</option>
                                                    <option value="75">75%</option>
                                                    <option value="66">66%</option>
                                                    <option value="50">50%</option>
                                                    <option value="33">33%</option>
                                                    <option value="25">25%</option>
                                                    <option value="16">16%</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("LowerThan400")}</div>
                                                </div>
                                                <select
                                                    className="form-control form-control-sm"
                                                    value={item.xsCols}
                                                    name="xsCols"
                                                    onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                >
                                                    <option value="100">100%</option>
                                                    <option value="75">75%</option>
                                                    <option value="66">66%</option>
                                                    <option value="50">50%</option>
                                                    <option value="33">33%</option>
                                                    <option value="25">25%</option>
                                                    <option value="16">16%</option>
                                                </select>
                                            </div>
                                        </div>
                                        {false && (
                                            <div className="mb-1">
                                                <div className="input-group input-group-sm">
                                                    <div class="input-group-prepend">
                                                        <div className="input-group-text">{translate.t("Class")}:</div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        value={item.className}
                                                        className="form-control form-control-sm"
                                                        name="className"
                                                        onChange={(e) => setElementData(e, lastIndexes + "-" + index, "drag_column")}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`cm-closing-overlay`} onClick={() => showSettings("drag_column", lastIndexes + "-" + index)}></div>
                                </Fragment>
                            )}
                        </div>
                        <div className="mr-1">
                            <div className={`item-container`} onClick={(e) => selectElement(e, "drag_columns", lastIndexes)}>
                                <i className={`las la-level-up-alt text-secondary `} title={translate.t("ParentElement")} />
                            </div>
                        </div>
                        {colsLength > 1 ? (
                            <div className={`item-container`} onClick={() => removeDragColumn(lastIndexes, index)}>
                                <i className={`las la-times text-danger `} title={translate.t("RemoveColumn")} />
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
            <div
                className={`
                    ${item.className} 
                    ${item.contentAlign != "start" ? `cm-col-content h-100 d-flex flex-column justify-content-${item.contentAlign}` : `cm-col-content`}
                    ${item.columnStyle}
                `}
                style={
                    item.paddingTop != 0 || item.paddingRight != 0 || item.paddingBottom != 0 || item.paddingLeft != 0
                        ? {
                              paddingTop: item.paddingTop + "px",
                              paddingRight: item.paddingRight + "px",
                              paddingBottom: item.paddingBottom + "px",
                              paddingLeft: item.paddingLeft + "px",
                          }
                        : {}
                }
            >
                <DragDropContext onDragEnd={(result) => onDragEnd(result, lastIndexes + "-" + index)}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {item.elements &&
                                    item.elements.map((elmItem, elmIndex) => {
                                        return (
                                            <Draggable key={`elm-${elmIndex + 1}`} draggableId={`elm-${elmIndex + 1}`} index={elmIndex}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                                        {elmItem.drag_columns && (
                                                            <ColElement
                                                                handle={provided.dragHandleProps}
                                                                fullWidth={fullWidth}
                                                                element={elmItem.drag_columns}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.text && (
                                                            <TextElement
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.text}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.articles && (
                                                            <ArticlesElement
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.articles}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.banner && (
                                                            <BannerElement
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.banner}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.contact_form && (
                                                            <ContactFormElement
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.contact_form}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.button && (
                                                            <ButtonElement
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.button}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.tournaments && (
                                                            <Tournaments
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.tournaments}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.ladders && (
                                                            <Ladders
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.ladders}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.athletes && (
                                                            <Athletes
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.athletes}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.federationList && (
                                                            <Federations
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.federationList}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.clubList && (
                                                            <Clubs
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.clubList}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                        {elmItem.sportList && (
                                                            <Sports
                                                                handle={provided.dragHandleProps}
                                                                element={elmItem.sportList}
                                                                lastIndexes={lastIndexes + "-" + index + "-" + elmIndex}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            {cmData.showHelpers && (
                <div
                    className={`${item.showMenu ? `cm-selected` : ""} cm-col-border`}
                    onClick={(e) => selectElement(e, "drag_column", lastIndexes + "-" + index)}
                ></div>
            )}
        </div>
    );
};

export default DragColumnElement;
