/*
    Výběr veškerých kategorií
*/
import React, { Component } from "react";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import NoItems from "../../../GlobalComponents/NoItems";
import { UPDATE_LINK_PRIORITY, GET_LINKS } from "../Queries/index.js";
import SubLink from "./SubLink";
import AddEditLink from "./AddEditLink";
import Helper from "../../../Library/helper";
import ContentManager from "../../ContentManager/Components/ContentManager";
import translate from "../../Translations/index";
import { withRouter } from "react-router-dom";
import { Query, Mutation } from "@apollo/client/react/components";
import withApollo from "../../../Library/withApollo.js";

const INITIAL_STATE = {
    showDeleteLinkNotifi: false,
    showDoNotDelete: false,
    showAddLink: false,
    selectedLinkID: 0,
    linkID: 0,
    parentID: 0,
    deleteLink: null,
    contentManagerLinkID: 0,
};

class AllLinks extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        //zjistime jestli ma prihlaseny administrator spravna prava
        const helper = new Helper();
        helper.checkAdminRights(props, ["1", "2"]);

        this.deleteLink = this.deleteLink.bind(this);
        this.delLink = this.delLink.bind(this);
        this.showDoNotDel = this.showDoNotDel.bind(this);
        this.openCloseModal = this.openCloseModal.bind(this);
        this.openContentManager = this.openContentManager.bind(this);
    }

    /**
     * funkce přebírá hodnoty z vnoření komponenty deleteLink
     * @param {*} deleteLink funkce, která posílá data na server
     * @param {*} linkID ID kategorie
     */

    deleteLink(deleteLink, linkID, parentID, subLink) {
        if (subLink) {
            this.setState({
                showDoNotDelete: true,
            });
        } else {
            this.setState({
                showDeleteLinkNotifi: true,
                deleteLink,
                linkID,
                parentID,
            });
        }
    }

    delLink(action) {
        if (action) {
            this.state.deleteLink({
                variables: {
                    linkID: this.state.linkID,
                    parentID: this.state.parentID,
                },
            });
        }
        this.setState(INITIAL_STATE);
    }

    showDoNotDel(action) {
        this.setState({
            showDoNotDelete: action,
        });
    }

    openCloseModal(type, linkID) {
        this.setState({
            showAddLink: type,
            selectedLinkID: linkID,
        });
    }

    openContentManager(linkID) {
        this.setState({
            contentManagerLinkID: linkID,
        });
    }

    render() {
        return (
            <Query query={GET_LINKS} errorPolicy="all" variables={{ lang: "cs" }}>
                {({ data, loading, error, refetch }) => {
                    if (error) {
                        const helper = new Helper();
                        return <Error text={error} />;
                    }
                    if (loading) return <Loading />;

                    return (
                        <div>
                            <div className="data-list">
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div>{translate.t("LinkTitle")}</div>
                                        <div className="text-center">{translate.t("Active")}</div>
                                        <div className="text-center">{translate.t("InHeader")}</div>
                                        <div className="text-center">{translate.t("InFooter")}</div>
                                        <div className="text-right">{translate.t("Options")}</div>
                                    </div>
                                </div>

                                <Mutation
                                    mutation={UPDATE_LINK_PRIORITY}
                                    update={() => {
                                        refetch();
                                    }}
                                >
                                    {(updateLinkPriority, { error }) => {
                                        if (error) {
                                            const helper = new Helper();
                                            return <Error text={helper.getApolloErrorText(error)} />;
                                        }

                                        return (
                                            <SubLink
                                                openCloseModal={this.openCloseModal}
                                                openContentManager={this.openContentManager}
                                                deleteLink={this.deleteLink}
                                                updateLinkPriority={updateLinkPriority}
                                                links={data.allLinks}
                                                level={0}
                                            />
                                        );
                                    }}
                                </Mutation>
                            </div>
                            {data.allLinks && data.allLinks.length == 0 && <NoItems text={translate.t("NoItemsAddedYet")} />}
                            {this.state.showDeleteLinkNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItem?")} callback={this.delLink} />}
                            {this.state.showDoNotDelete && (
                                <ModalNotification yesNo={false} text={translate.t("UnableToDeleteLinkBecauseSublink")} callback={this.showDoNotDel} />
                            )}
                            {this.state.showAddLink && <AddEditLink openCloseModal={this.openCloseModal} selectedLinkID={this.state.selectedLinkID} />}
                        </div>
                    );
                }}
            </Query>
        );
    }
}

export default withRouter(withApollo(AllLinks));
