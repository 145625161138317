/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditCountry from "./AddEditCountry";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_COUNTRIES, DELETE_COUNTRIES } from "../Queries/country";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import SearchInput from "../../General/Components/SearchInput";

const Countries = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    helper.checkAdminRights({ ...props, client }, ["1", "2"]);

    const [searchQuery, setSearchQuery] = useState("");
    const [listVariables, setListVariables] = useState({
        filters: {
            offset: 0,
            limit: 50,
            searchQuery: "",
            countryID: 0,
        },
    });

    const [selectedCountries, setSelectedCountries] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedCountryID, setSelectedCountryID] = useState("");
    const {
        loading,
        error: getError,
        data: countryListData,
        fetchMore,
        refetch,
    } = useQuery(GET_COUNTRIES, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const [deleteCountry, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_COUNTRIES, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            refetch({ filters: { ...listVariables.filters, limit: countryListData.allCountries.length } });
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const openCloseModal = (open, countryID) => {
        setSelectedCountryID(countryID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteCountries funkce, která posílá data na server
     * @param {*} countryID ID countryu
     */

    const initiateDeleteCountry = (e, countryID) => {
        var checked = e.target.checked;
        var arr = [...selectedCountries];

        if (!checked) {
            for (let i in selectedCountries) {
                if (selectedCountries[i] == countryID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [countryID, ...arr];
        }

        setSelectedCountries(arr);
    };

    const delCountry = (action) => {
        if (action) {
            let countryIDs = selectedCountries.join(",");

            deleteCountry({
                variables: {
                    countryIDs: countryIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedCountries.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div id="settings" className="whole-container">
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("CountryList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className={`d-flex px-3 py-2 bg-light border-bottom align-items-center`}>
                            <div className="form-group ml-auto">
                                <div className="text-nowrap fs12">{translate.t("Search")}</div>
                                <SearchInput
                                    className={"form-control form-control-sm "}
                                    value={searchQuery}
                                    onChange={(text) => {
                                        setListVariables({ ...listVariables, filters: { ...listVariables.filters, searchQuery: text, offset: 0 } });
                                    }}
                                    textChanged={(text) => {
                                        setSearchQuery(text);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("CountryTitle")}</div>
                                                <div>{translate.t("CountryCode")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {countryListData?.allCountries?.map((item, index) => {
                                                var checked = false;
                                                for (let i in selectedCountries) {
                                                    if (selectedCountries[i] == item.countryID) checked = true;
                                                }

                                                return (
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.name}</div>
                                                        <div>{item.code}</div>

                                                        <div className="text-right">
                                                            <img onClick={() => openCloseModal(true, item.countryID)} className="edit-icon" src={edit} />
                                                            <input
                                                                className="delete-checked"
                                                                type="checkbox"
                                                                name="deleteCountry[]"
                                                                checked={checked}
                                                                onChange={(e) => initiateDeleteCountry(e, item.countryID)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {!loading && (
                                                <>
                                                    {!!countryListData?.allCountries?.length ? (
                                                        <>
                                                            {countryListData.allCountries.length < countryListData.countriesCount && (
                                                                <div className="text-center p-5">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() =>
                                                                            fetchMore({
                                                                                variables: {
                                                                                    ...listVariables,
                                                                                    filters: {
                                                                                        ...listVariables.filters,
                                                                                        offset: countryListData.allCountries.length,
                                                                                    },
                                                                                },
                                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                                    if (!fetchMoreResult) return prev;
                                                                                    return Object.assign({}, prev, {
                                                                                        allCountries: [...prev.allCountries, ...fetchMoreResult.allCountries],
                                                                                    });
                                                                                },
                                                                            })
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {translate.t("LoadMore")}{" "}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                                    )}
                                                </>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditCountry
                    openCloseModal={openCloseModal}
                    selectedCountryID={selectedCountryID}
                    listVariables={listVariables}
                    updated={() => refetch({ filters: { ...listVariables.filters, limit: countryListData.allCountries.length } })}
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delCountry} />}
        </div>
    );
};

export default Countries;
