/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import { GET_COMPETITION, GET_COMPETITIONS } from "../Queries/competition";
import Notification from "../../../Library/notification";
import translate from "../../Translations/index";
import Helper from "../../../Library/helper";
import moment from "moment";

class Competition {
    constructor(props) {
        this.props = props;
    }

    /**
     * Přidání/Editace
     * @param {*} addCompetition funkce z apollo, pomocí které se posílají data na server
     */

    addCompetition = async (addCompetition, formData, callback = null) => {
        const helper = new Helper();

        let notify = new Notification();

        if (formData && formData.title != "" && formData.countryID != "" && formData.sportID != "0" && formData.dateFrom != null) {
            addCompetition({
                variables: {
                    addEditData: {
                        hashID: formData.hashID,
                        adminUserHashID: formData.adminUserHashID,
                        dateFrom: moment(formData.dateFrom).format("YYYY-MM-DD HH:mm:ss"),
                        dateTo: formData.dateTo ? moment(formData.dateTo).format("YYYY-MM-DD H:mm:ss") : null,
                        title: formData.title,
                        competitionStateID: formData.competitionStateID,
                        countryID: formData.countryID,
                        place: formData.place,
                        description: formData.description,
                        photo: formData.photo,
                        photoURL: formData.photoURL,
                        file: formData.file,
                        fileName: formData.fileName,
                        fileSize: formData.fileSize,
                        sportID: formData.sportID,
                        organiserID: formData.organiserID,
                        sponsorPhoto: formData.sponsorPhoto,
                        sponsorPhotoURL: formData.sponsorPhotoURL,
                        sponsorName: formData.sponsorName,
                        lang: translate.locale,
                    },
                },
            }).then(
                (responseData) => {
                    if (callback) {
                        callback(true, responseData);
                    }
                },
                (errorData) => {
                    if (callback) {
                        callback(false, errorData);
                    }
                }
            );
        } else {
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache, response, selectedID, variables) {
        const { allCompetitions } = cache.readQuery({ query: GET_COMPETITIONS, variables });
        if (!(selectedID && selectedID != 0)) {
            const arr = [response.data.addEditCompetition, ...allCompetitions];
            arr.sort(this.sortListFunction);

            cache.writeQuery({
                query: GET_COMPETITIONS,
                data: {
                    allCompetitions: arr,
                },
                variables,
            });
        } else {
            var arr = [...allCompetitions];

            arr.forEach((item, index) => {
                if (item.competitionID == response.data.addEditCompetition.competitionID) {
                    arr[index].competitionID = response.data.addEditCompetition.competitionID;
                    arr[index].title = response.data.addEditCompetition.title;
                    arr[index].dateFrom = response.data.addEditCompetition.dateFrom;
                    arr[index].dateTo = response.data.addEditCompetition.dateTo;
                    arr[index].organizatorName = response.data.addEditCompetition.organizatorName;
                    arr[index].organizatorSurname = response.data.addEditCompetition.organizatorSurname;
                }
            });
            arr.sort(this.sortListFunction);
            cache.writeQuery({
                query: GET_COMPETITIONS,
                data: {
                    allCompetitions: arr,
                },
                variables,
            });
        }
    }

    sortListFunction(a, b) {
        if (moment(a.dateFrom).toDate() < moment(b.dateFrom).toDate()) {
            return 1;
        } else if (moment(a.dateFrom).toDate() > moment(b.dateFrom).toDate()) {
            return -1;
        } else {
            if (a.competitionID > b.competitionID) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    /**
     *
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky
     */

    updateAfterDelete(cache, response, variables) {
        var resp = response.data.deleteCompetitions.split(",");

        const { allCompetitions } = cache.readQuery({ query: GET_COMPETITIONS, variables });
        var arr = [...allCompetitions];

        resp.forEach((it, ind) => {
            arr.forEach((item, index) => {
                if (item.hashID == it) {
                    arr = [...arr.slice(0, index), ...arr.slice(index + 1)];
                }
            });
        });

        cache.writeQuery({
            query: GET_COMPETITIONS,
            variables,
            data: {
                allCompetitions: [...arr],
            },
        });
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */

    formHandle(e, formData, setFormData) {
        const t = e.target;
        const v = t.competition === "checkbox" ? t.checked : t.value;
        const n = t.name;

        setFormData({ ...formData, [n]: v });
    }

    /**
     * Otevře obrázek, zpracuje a zobrazí jeho náhled
     * @param {Event} e
     * @param {Function} setFormData    - nastavuje object formData v komponente kde je funkce volala
     * @param {Object} formData         - object formuláře v komponentě kde je funkce volaná
     */

    openImage(e, setFormData, formData) {
        var photo = e.target.files[0];
        var reader = new FileReader();
        reader.onload = () => {
            setFormData({ ...formData, photoURL: reader.result, photo: photo });
        };
        reader.readAsDataURL(photo);
    }
    /**
     * Otevře soubor, zpracuje a zobrazí jeho náhled
     * @param {Event} e
     * @param {Function} setFormData    - nastavuje object formData v komponente kde je funkce volala
     * @param {Object} formData         - object formuláře v komponentě kde je funkce volaná
     */

    openFile(e, setFormData, formData) {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = () => {
            setFormData({ ...formData, fileName: file.name, fileSize: file.size, file: file });
        };
        reader.readAsDataURL(file);
    }

    /**
     * Vráti stringovou hodnotu velikosti souboru
     * @param {Number} fileSize - velikost souboru v bytech
     */
    getFileSizeString(fileSize) {
        let sizeString = "";
        if (fileSize < 1000000) {
            sizeString += Math.round(fileSize / 100) / 10 + " KB";
        } else {
            sizeString += Math.round(fileSize / 100000) / 10 + " MB";
        }
        return sizeString;
    }

    /**
     * Vrátí název souboru společně s velikostí
     * @param {*} fileObj
     * @param {*} fileTitleLength
     */
    getFileName(fileName, fileSize = 0, showSize = true, shortenFile = true, fileTitleLength = 30) {
        let halfLength = Math.round(fileTitleLength / 2);
        if (shortenFile && fileName.length > fileTitleLength) {
            fileName = fileName.substring(0, halfLength) + "..." + fileName.substring(fileName.length - halfLength, fileName.length);
        }

        if (showSize) {
            fileName += " (" + this.getFileSizeString(fileSize) + ")";
        }
        return fileName;
    }
}

export default Competition;
