/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit adminUser
 */
export const ADD_EDIT_ADMINUSER = gql`
    mutation AddEditAdminUser($addEditData:AddEditAdminUserDataInput){
        addEditAdminUser(addEditData:$addEditData){
            adminUserID
            name
            surname
            email
            roleID
            roleTitle
        }
    }
`;


/**
 *  AdminUser data
 */

export const GET_ADMINUSER = gql`
    query AdminUserByID($adminUserID: ID!,$lang:String){
        adminUserByID(adminUserID:$adminUserID){
            adminUserID
            name
            surname
            email
            roleID            
        }
        allRolesByRoleID(lang:$lang){
            roleID
            title
        }
    }
`;

/**
 *  Seznam veškerých adminUserů
 */
export const GET_ADMINUSERS = gql`
    query AllAdminUsers($lang:String){
        allAdminUsers(lang:$lang){            
            adminUserID
            name
            surname
            email
            roleID
            roleTitle
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_ADMINUSERS = gql`
    mutation deleteAdminUsers($adminUserIDs:ID!) {
        deleteAdminUsers(adminUserIDs:$adminUserIDs)
    }
`;

/**
 * Smazání kategorie.
 */
export const GENERATE_NEW_ADMIN_PWD = gql`
    mutation GenerateNewAdminPassword($adminUserID:ID,$password:String!) {
        generateNewAdminPassword(adminUserID:$adminUserID,password:$password)
    }
`;


