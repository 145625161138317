import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import edit from "../../../Public/Images/edit.svg";
import contentManager from "../../../Public/Images/content_manager.svg";
import move from "../../../Public/Images/move.svg";
import DeleteLink from "./DeleteLink";
import translate from "../../Translations/index";
import Helper from "../../../Library/helper";

class SubLink extends Component {
    constructor(props) {
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.helper = new Helper();
    }

    onDragEnd(result, updateLinkPriority) {
        const IDs = result.draggableId.split("-");

        updateLinkPriority({
            variables: {
                parentID: IDs[1],
                linkID: IDs[0],
                fromIndex: result.source.index,
                toIndex: result.destination.index,
            },
        });
    }

    render() {
        return (
            <DragDropContext onDragEnd={(result) => this.onDragEnd(result, this.props.updateLinkPriority)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {this.props.links &&
                                this.props.links.map((item, index) => {
                                    if (item.linkID) {
                                        return (
                                            <Draggable key={index} draggableId={item.linkID + "-" + item.parentID} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        className="data-list-item"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="data-list-item-content categories">
                                                            <div
                                                                className="cross"
                                                                style={
                                                                    this.props.level == 0 ? { paddingLeft: 10 } : { paddingLeft: this.props.level * 20 + 10 }
                                                                }
                                                            >
                                                                <img className="move-icon" src={move} />
                                                                {this.helper.getLangValueText(item.langs, "name")}
                                                            </div>
                                                            <div className="text-center">{item.active == 1 ? translate.t("Yes") : translate.t("No")}</div>
                                                            <div className="text-center">{item.inHeader == 1 ? translate.t("Yes") : translate.t("No")}</div>
                                                            <div className="text-center">{item.inFooter == 1 ? translate.t("Yes") : translate.t("No")}</div>
                                                            <div className="text-right">
                                                                <img
                                                                    onClick={() => this.props.openCloseModal(true, item.linkID)}
                                                                    className="edit-icon"
                                                                    src={edit}
                                                                />
                                                                <DeleteLink
                                                                    linkID={item.linkID}
                                                                    parentID={item.parentID}
                                                                    subLink={item.subLink}
                                                                    deleteLink={this.props.deleteLink}
                                                                />
                                                            </div>
                                                        </div>
                                                        {item.subLink && (
                                                            <SubLink
                                                                openCloseModal={this.props.openCloseModal}
                                                                deleteLink={this.props.deleteLink}
                                                                updateLinkPriority={this.props.updateLinkPriority}
                                                                links={item.subLink}
                                                                level={this.props.level + 1}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    } else {
                                        return "";
                                    }
                                })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

export default SubLink;
