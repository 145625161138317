/*
	Komponenta, kerá kontroluje, jestli je uživatel přihlášen.
*/

import { useApolloClient, useQuery } from "@apollo/client";
import { ADMIN_USER, INITIAL_DATA } from "../../../GlobalQueries/globalQueries";

function withAuthentication(Component) {
    return function WithAuthenticationComponent(props) {
        const client = useApolloClient();

        const adminUserData = client.readQuery({ query: ADMIN_USER });
        console.log(adminUserData);
        if (!adminUserData || !adminUserData.adminUser) {
            props.history.push("/");
            return null;
        } else {
            return <Component {...props} />;
        }
    };
}

export default withAuthentication;
