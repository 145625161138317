import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import {
    DELETE_TINYMCE_FILES,
    DELETE_TINYMCE_IMAGES,
    GET_TINYMCE_FILES,
    GET_TINYMCE_IMAGES,
    UPLOAD_TINYMCE_FILES,
    UPLOAD_TINYMCE_IMAGES,
} from "../Queries/index";
import { useLazyQuery, useMutation } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import { SERVER_URL, TINYMCE_FILE_URL, TINYMCE_IMAGE_URL } from "../../../Config/index";
import TinyMceFileManagerDeletedArchive from "./TinyMceFileManagerDeletedArchive";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import translate from "../../Translations/index";

Modal.setAppElement("#root");

const TinyMceFileManager = (props) => {
    const [loading, setLoading] = useState(true);
    const [allFiles, setAllFiles] = useState([]);
    const [allImages, setAllImages] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [selectedTab, setTab] = useState(1);

    const [getImages, { loading: imagesLoading, refetch: refetchImages }] = useLazyQuery(GET_TINYMCE_IMAGES, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            setAllImages(JSON.parse(JSON.stringify(data.allTinyMceImages)));
            setLoading(false);
        },
        onError(err) {
            console.log(err);
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const [getFiles, { loading: filesLoading, refetch: refetchFiles }] = useLazyQuery(GET_TINYMCE_FILES, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            setAllFiles(JSON.parse(JSON.stringify(data.allTinyMceFiles)));
            setLoading(false);
        },
        onError(err) {
            console.log(err);
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const [uploadImages, { loading: imageUploading }] = useMutation(UPLOAD_TINYMCE_IMAGES, {
        context: { headers: { "apollo-require-preflight": true } },
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("PicturesSuccessfullyUploaded"), "success");
            refetchImages();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });
    const [deleteImages, { loading: imageDeleting }] = useMutation(DELETE_TINYMCE_IMAGES, {
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("PicturesSuccessfullyDeleted"), "success");
            refetchImages();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const [uploadFiles, { loading: fileUploading }] = useMutation(UPLOAD_TINYMCE_FILES, {
        context: { headers: { "apollo-require-preflight": true } },
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("FilesSuccessfullyUploaded"), "success");
            refetchFiles();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });
    const [deleteFiles, { loading: fileDeleting }] = useMutation(DELETE_TINYMCE_FILES, {
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("FilesSuccessfullyDeleted"), "success");
            refetchFiles();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const upload = (files) => {
        if (props.tinyMCEFileType == "image") {
            uploadImages({ variables: { files: files } });
        } else if (props.tinyMCEFileType == "file" || props.tinyMCEFileType == "media") {
            uploadFiles({ variables: { files: files } });
        }
    };
    useEffect(() => {
        if (props.tinyMCEFileType == "image") {
            getImages({
                variables: {
                    filters: {
                        deleted: 0,
                    },
                },
            });
        } else if (props.tinyMCEFileType == "file" || props.tinyMCEFileType == "media") {
            getFiles({
                variables: {
                    filters: {
                        deleted: 0,
                    },
                },
            });
        }
    }, [props.tinyMCEFileType]);

    const selectImage = (image) => {
        props.tinyMCECallBack(SERVER_URL + "/" + TINYMCE_IMAGE_URL + "/wide_" + image.name);
        !!props.callback && props.callback();
    };
    const selectFile = (file) => {
        props.tinyMCECallBack(SERVER_URL + "/" + TINYMCE_FILE_URL + "/" + file.name);
        !!props.callback && props.callback();
    };

    const checkImage = (image) => {
        for (let img of allImages) {
            if (image.tinyMceImageID == img.tinyMceImageID) {
                img.checked = !img.checked;
            }
        }
        setAllImages([...allImages]);
    };
    const checkFile = (file) => {
        for (let f of allFiles) {
            if (file.tinyMceFileID == f.tinyMceFileID) {
                f.checked = !f.checked;
            }
        }
        setAllFiles([...allFiles]);
    };

    const delItems = (status) => {
        if (status) {
            let IDs = [];
            if (props.tinyMCEFileType == "image") {
                for (const image of allImages) {
                    if (!!image.checked) {
                        IDs.push(image.tinyMceImageID);
                    }
                }
                if (IDs.length > 0) {
                    deleteImages({ variables: { IDs } });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("YouDidNotChooseAnyPhoto"), "danger");
                }
            } else if (props.tinyMCEFileType == "file" || props.tinyMCEFileType == "media") {
                for (const file of allFiles) {
                    if (!!file.checked) {
                        IDs.push(file.tinyMceFileID);
                    }
                }
                if (IDs.length > 0) {
                    deleteFiles({ variables: { IDs } });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("YouDidNotChooseAnyFile"), "danger");
                }
            }
        }
        setShowDeleteNotifi(false);
    };

    const recoveryCompleted = () => {
        if (props.tinyMCEFileType == "image") {
            refetchImages();
        } else if (props.tinyMCEFileType == "file" || props.tinyMCEFileType == "media") {
            refetchFiles();
        }
    };

    const getDeleteText = () => {
        const itemCount =
            props.tinyMCEFileType == "image" ? allImages.filter((item) => !!item.checked).length : allFiles.filter((item) => !!item.checked).length;

        let text = `
            Opravdu chcete odstranit 
            <strong>
                ${itemCount} 
                ${itemCount == 0 ? `vybraných položek` : ``}
                ${itemCount == 1 ? `vybranou položku` : ``}
                ${itemCount > 1 && itemCount <= 4 ? `vybrané položky` : ``}
                ${itemCount > 4 ? `vybraných položek` : ``}   
            </strong>?
        `;
        return text;
    };

    return (
        <Modal className={`Modal__Bootstrap modal-dialog modal-lg club-modal`} closeTimeoutMS={150} isOpen={true} onRequestClose={() => props.callback(false)}>
            <div className={`modal-dialog modal-xl`}>
                <div className="modal-content file-manager">
                    <div className="modal-header d-flex align-items-center">
                        <h4 className="modal-title">
                            {translate.t("FileManager")} | {props.tinyMCEFileType == "image" ? translate.t("Photos") : translate.t("Files")}
                        </h4>

                        <button type="button" className="close ml-3" onClick={() => props.callback(false)}>
                            <span aria-hidden="true">x</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {!loading ? (
                            <>
                                <ul className={`nav nav-tabs `} role="tablist">
                                    <li className={`nav-item mr-2`}>
                                        <a className={`nav-link ${selectedTab == 1 ? `active` : ""}`} onClick={(e) => setTab(1)}>
                                            {props.tinyMCEFileType == "image" ? translate.t("Photos") : translate.t("Files")}
                                        </a>
                                    </li>
                                    <li className={`nav-item mr-2`}>
                                        <a
                                            className={`nav-link ${selectedTab == 2 ? `active` : ""} `}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setTab(2);
                                            }}
                                        >
                                            {translate.t("DeletedArchive")}{" "}
                                            {props.tinyMCEFileType == "image" ? translate.t("DeletedArchivePhotos") : translate.t("DeletedArchiveFiles")}
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    {selectedTab == 1 && (
                                        <>
                                            <div className="modal-body">
                                                {!imageUploading && !imageDeleting && !imagesLoading && !fileUploading && !fileDeleting && !filesLoading ? (
                                                    <Fragment>
                                                        <div className="d-flex align-items-end">
                                                            <div class="input-group" style={{ maxWidth: "500px" }}>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text" id="inputGroupFileAddon01">
                                                                        {translate.t("Upload")}
                                                                    </span>
                                                                </div>
                                                                <div class="custom-file">
                                                                    <input
                                                                        type="file"
                                                                        className="custom-file-input"
                                                                        id="formFile"
                                                                        aria-describedby="formFile"
                                                                        multiple={true}
                                                                        onChange={({ target: { validity, files: files } }) => {
                                                                            validity.valid && upload(files);
                                                                        }}
                                                                        {...(props.tinyMCEFileType == "image" ? { accept: "image/*" } : null)}
                                                                    />
                                                                    <label class="custom-file-label" for="formFile">
                                                                        {translate.t("ChooseFile")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <button className="btn btn-danger  ml-auto" type="button" onClick={() => setShowDeleteNotifi(true)}>
                                                                {translate.t("DeleteSelected")}{" "}
                                                                {props.tinyMCEFileType == "image" ? translate.t("Photos") : translate.t("Files")}
                                                            </button>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex flex-wrap">
                                                            {allImages.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="col-3 p-2">
                                                                        <div className="position-relative" style={{ height: "100px" }}>
                                                                            <div
                                                                                className="rounded d-flex position-absolute top-0 start-0 p-2 bg-light"
                                                                                onClick={() => checkImage(item)}
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="delete"
                                                                                    checked={!!item.checked}
                                                                                    onChange={() => {}}
                                                                                />
                                                                            </div>
                                                                            <img
                                                                                className="img-thumbnail cursor-pointer"
                                                                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                                                                onClick={() => selectImage(item)}
                                                                                src={SERVER_URL + "/" + TINYMCE_IMAGE_URL + "/small_" + item.name}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}

                                                            {allFiles.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="col-3 p-2 form-group">
                                                                        <div className="cover-item file-list-item file-item">
                                                                            <div
                                                                                className="rounded d-flex position-absolute top-0 start-0 p-2 bg-light checker"
                                                                                onClick={() => checkFile(item)}
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="delete"
                                                                                    checked={!!item.checked}
                                                                                    onChange={() => {}}
                                                                                />
                                                                            </div>

                                                                            <div className="form-group text-center file-desc">
                                                                                <div className="icon-wrapper cursor-pointer" onClick={() => selectFile(item)}>
                                                                                    <i className="icon las la-file text-secondary"></i>
                                                                                    <div className="ext text-secondary">{item.name.split(".").pop()}</div>
                                                                                </div>
                                                                                <p className="fs-14 cursor-pointer" onClick={() => selectFile(item)}>
                                                                                    {item.name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    <Loading color="blue" />
                                                )}
                                            </div>

                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger" onClick={() => props.callback(false)}>
                                                    {translate.t("Close")}
                                                </button>
                                            </div>
                                        </>
                                    )}

                                    {selectedTab == 2 && (
                                        <TinyMceFileManagerDeletedArchive
                                            tinyMCEFileType={props.tinyMCEFileType}
                                            onRecoveryCompleted={() => recoveryCompleted()}
                                        />
                                    )}
                                </div>
                            </>
                        ) : (
                            <Loading color="blue" />
                        )}
                    </div>
                </div>
                {showDeleteNotifi && <ModalNotification title={"Odstranit vybrané položky?"} yesNo={true} content={getDeleteText()} callback={delItems} />}
            </div>
        </Modal>
    );
};

export default TinyMceFileManager;
