import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloLink, concat } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { GRAPHQL_SERVER_URL } from "../Config/index";

import * as process from "process";
window.process = process;
// inicializace Cache - dá se do této cache ukládat hodnoty pro příští použití
const cache = new InMemoryCache();

// URl adresa na server pro graphql
const httpLink = new createUploadLink({
    uri: GRAPHQL_SERVER_URL,
});

//prostředník, který při každém requestu zasílá token na server z localstorage
const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
        headers: {
            authorization: localStorage.getItem("token") || null,
        },
    });

    return forward(operation);
});

/**
 * globílní apollo client instance pro použití kdekoli na webu
 * @returns
 */
export function getApolloInstance() {
    return new ApolloClient({
        cache: new InMemoryCache(),
        link: concat(authMiddleware, httpLink),
    });
}
const client = getApolloInstance();

export default client;
