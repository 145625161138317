/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import { GET_ATHLETE, GET_ATHLETES } from "../Queries/athlete";
import Notification from "../../../Library/notification";
import translate from "../../Translations/index";
import Helper from "../../../Library/helper";
const moment = require("moment");

class Athlete {
    constructor(props) {
        this.props = props;
    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache, response, selectedID, variables) {
        const { allAthletes } = cache.readQuery({ query: GET_ATHLETES, variables });
        if (!(selectedID && selectedID != 0)) {
            const arr = [response.data.addEditAthlete, ...allAthletes];

            cache.writeQuery({
                query: GET_ATHLETES,
                data: {
                    allAthletes: arr,
                },
                variables,
            });
        } else {
            var arr = [...allAthletes];

            arr.forEach((item, index) => {
                if (item.athleteID == response.data.addEditAthlete.athleteID) {
                    arr[index].athleteID = response.data.addEditAthlete.athleteID;
                    arr[index].name = response.data.addEditAthlete.name;
                    arr[index].surname = response.data.addEditAthlete.surname;
                    arr[index].email = response.data.addEditAthlete.email;
                    arr[index].phone = response.data.addEditAthlete.phone;
                    arr[index].birthDate = response.data.addEditAthlete.birthDate;
                    arr[index].sexID = response.data.addEditAthlete.sexID;
                    arr[index].internationalLicence = response.data.addEditAthlete.internationalLicence;
                    arr[index].licenceNumber = response.data.addEditAthlete.licenceNumber;
                    arr[index].countryID = response.data.addEditAthlete.countryID;
                    arr[index].clubID = response.data.addEditAthlete.clubID;
                }
            });
            cache.writeQuery({
                query: GET_ATHLETES,
                data: {
                    allAthletes: arr,
                },
                variables,
            });
        }
    }

    /**
     *
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky
     */

    updateAfterDelete(cache, response, variables) {
        var resp = response.data.deleteAthletes.split(",");

        const { allAthletes } = cache.readQuery({ query: GET_ATHLETES, variables });
        var arr = [...allAthletes];

        resp.forEach((it, ind) => {
            arr.forEach((item, index) => {
                if (item.athleteID == it) {
                    arr = [...arr.slice(0, index), ...arr.slice(index + 1)];
                }
            });
        });

        cache.writeQuery({
            query: GET_ATHLETES,
            variables,
            data: {
                allAthletes: [...arr],
            },
        });
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */

    formHandle(e, formData, setFormData) {
        const t = e.target;
        const v = t.athlete === "checkbox" ? t.checked : t.value;
        const n = t.name;

        setFormData({ ...formData, [n]: v });
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */

    formHandleEditor(content, param, formData, setFormData) {
        setFormData({ ...formData, [param]: content });
    }

    /**
     * Otevře obrázek, zpracuje a zobrazí jeho náhled
     * @param {Event} e
     * @param {Function} setFormData    - nastavuje object formData v komponente kde je funkce volala
     * @param {Object} formData         - object formuláře v komponentě kde je funkce volaná
     */

    openImage(e, setFormData, formData) {
        var photo = e.target.files[0];
        var reader = new FileReader();
        reader.onload = () => {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = reader.result;

            //Validate the File Height and Width.
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height < 1024 && width < 1024) {
                    setFormData({ ...formData, photoURL: reader.result, photo: photo });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("ImageWidthOrHeightNotMatch") + "1024 x 1024px", "danger");
                }
            };
        };
        reader.readAsDataURL(photo);
    }
}

export default Athlete;
