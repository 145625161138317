/**
 *  komponenta pro štítky pro produkty
 */
import React, { Component, Fragment, useState } from "react";
import AddEditAthlete from "./AddEditAthlete";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_ATHLETES, DELETE_ATHLETES } from "../Queries/athlete";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import AthleteLib from "../Library/athlete";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import moment from "moment";
import { FormattedDate } from "react-intl";

const Athletes = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    helper.checkAdminRights({ ...props, client }, ["1", "2"]);

    const listVariables = {
        offset: 0,
        limit: 50,
    };

    const [selectedAthletes, setSelectedAthletes] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedAthleteID, setSelectedAthleteID] = useState(0);
    const {
        loading,
        error: getError,
        data: athleteListData,
        fetchMore,
        refetch,
    } = useQuery(GET_ATHLETES, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
    });

    const [deleteAthlete, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_ATHLETES, {
        update(cache, response) {
            let athlete = new AthleteLib(this);
            athlete.updateAfterDelete(cache, response, listVariables);

            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, athleteID) => {
        setSelectedAthleteID(athleteID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteAthletes funkce, která posílá data na server
     * @param {*} athleteID ID athleteu
     */

    const initiateDeleteAthlete = (e, athleteID) => {
        var checked = e.target.checked;
        var arr = [...selectedAthletes];

        if (!checked) {
            for (let i in selectedAthletes) {
                if (selectedAthletes[i] == athleteID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [athleteID, ...arr];
        }

        setSelectedAthletes(arr);
    };

    const delAthlete = (action) => {
        if (action) {
            let athleteIDs = selectedAthletes.join(",");

            deleteAthlete({
                variables: {
                    athleteIDs: athleteIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedAthletes.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div id="settings" className="whole-container">
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("AthleteList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, 0)}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Name")}</div>
                                                <div className="text-center">{translate.t("Email")}</div>
                                                <div className="text-center">{translate.t("Phone")}</div>
                                                <div className="text-center">{translate.t("BirthDate")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {athleteListData &&
                                                athleteListData.allAthletes &&
                                                athleteListData.allAthletes.map((item, index) => {
                                                    var checked = false;
                                                    for (let i in selectedAthletes) {
                                                        if (selectedAthletes[i] == item.athleteID) checked = true;
                                                    }

                                                    return (
                                                        <div key={index} className="data-list-item-content">
                                                            <div>
                                                                {item.name} {item.surname}
                                                            </div>
                                                            <div className="text-center">{item.email}</div>
                                                            <div className="text-center">{item.phone}</div>
                                                            <div className="text-center">
                                                                <FormattedDate value={moment(item.birthDate).toDate()} />
                                                            </div>
                                                            <div className="text-right">
                                                                <img onClick={() => openCloseModal(true, item.athleteID)} className="edit-icon" src={edit} />
                                                                <input
                                                                    className="delete-checked"
                                                                    type="checkbox"
                                                                    name="deleteAthlete[]"
                                                                    checked={checked}
                                                                    onChange={(e) => initiateDeleteAthlete(e, item.athleteID)}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {athleteListData && athleteListData.allAthletes && athleteListData.allAthletes.length == 0 ? (
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                            ) : (
                                                <Fragment>
                                                    {!loading && athleteListData && athleteListData.allAthletes.length < athleteListData.athleteListCount && (
                                                        <div className="text-center p-5">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() =>
                                                                    fetchMore({
                                                                        variables: { ...listVariables, offset: athleteListData.allAthletes.length },
                                                                        updateQuery: (prev, { fetchMoreResult }) => {
                                                                            if (!fetchMoreResult) return prev;
                                                                            return Object.assign({}, prev, {
                                                                                allAthletes: [...prev.allAthletes, ...fetchMoreResult.allAthletes],
                                                                            });
                                                                        },
                                                                    })
                                                                }
                                                            >
                                                                {" "}
                                                                {translate.t("LoadMore")}{" "}
                                                            </button>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditAthlete
                    openCloseModal={openCloseModal}
                    selectedAthleteID={selectedAthleteID}
                    listVariables={listVariables}
                    updated={() =>
                        refetch({ limit: listVariables.limit > athleteListData.allAthletes.length ? listVariables.limit : athleteListData.allAthletes.length })
                    }
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delAthlete} />}
        </div>
    );
};

export default Athletes;
