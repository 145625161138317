/*
    Přidání štítku
*/
import React, { Fragment } from "react";
import PrimaryData from "./PrimaryData";
import { useQuery } from "@apollo/client";
import translate from "../../Translations/index";
import { GET_DISCIPLINE } from "../Queries/discipline";
import Loading from "../../../GlobalComponents/Loading";
import { Switch, Route, NavLink, withRouter } from "react-router-dom";
import Registration from "./Registration";
import Ladders from "./Ladders";
import Heats from "../../Heat/Components/Heats";
import DisciplineResultList from "./DisciplineResultList";

const DisciplineDetail = (props) => {
    const {
        loading: getLoading,
        error: getError,
        data: disciplineData,
        refetch,
    } = useQuery(GET_DISCIPLINE, {
        variables: { hashID: props.match.params.disciplineHashID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
    });
    return (
        <Fragment>
            {!getLoading ? (
                <div className="d-flex discipline-detail">
                    <div className="col-2 px-0 d-flex">
                        <ul className="side-nav">
                            <li className="side-nav-item-title" title={disciplineData?.discipline?.title}>
                                {disciplineData?.discipline?.title}
                            </li>
                            <li className="side-nav-item">
                                <NavLink
                                    activeClassName="active"
                                    className="nav-link"
                                    strict
                                    to={
                                        "/competition/" +
                                        props.match.params.competitionHashID +
                                        "/discipline/" +
                                        props.match.params.disciplineHashID +
                                        "/primary-data"
                                    }
                                >
                                    {translate.t("DisciplinePrimaryData")}
                                </NavLink>
                            </li>
                            <li className="side-nav-item">
                                <NavLink
                                    activeClassName="active"
                                    className="nav-link"
                                    strict
                                    to={
                                        "/competition/" +
                                        props.match.params.competitionHashID +
                                        "/discipline/" +
                                        props.match.params.disciplineHashID +
                                        "/ladders"
                                    }
                                >
                                    {translate.t("Ladders")}
                                </NavLink>
                            </li>
                            <li className="side-nav-item">
                                <NavLink
                                    activeClassName="active"
                                    className="nav-link"
                                    strict
                                    to={
                                        "/competition/" +
                                        props.match.params.competitionHashID +
                                        "/discipline/" +
                                        props.match.params.disciplineHashID +
                                        "/registration"
                                    }
                                >
                                    {translate.t("Registration")}
                                </NavLink>
                            </li>
                            {disciplineData?.discipline?.heatsEnabled == 1 && (
                                <li className="side-nav-item">
                                    <NavLink
                                        activeClassName="active"
                                        className="nav-link"
                                        strict
                                        to={
                                            "/competition/" +
                                            props.match.params.competitionHashID +
                                            "/discipline/" +
                                            props.match.params.disciplineHashID +
                                            "/heats"
                                        }
                                    >
                                        {translate.t("Heats")}
                                    </NavLink>
                                </li>
                            )}
                            <li className="side-nav-item">
                                <NavLink
                                    activeClassName="active"
                                    className="nav-link"
                                    strict
                                    to={
                                        "/competition/" +
                                        props.match.params.competitionHashID +
                                        "/discipline/" +
                                        props.match.params.disciplineHashID +
                                        "/results"
                                    }
                                >
                                    {translate.t("Final/Results")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="col-10 p-4">
                        <Switch>
                            <Route exact path="/competition/:competitionHashID/discipline/:disciplineHashID/primary-data">
                                <PrimaryData
                                    inModal={false}
                                    selectedCompetitionHashID={props.match.params.competitionHashID}
                                    selectedDisciplineHashID={props.match.params.disciplineHashID}
                                    sportID={props.sportID}
                                    updated={() => refetch()}
                                />
                            </Route>
                            <Route exact path="/competition/:competitionHashID/discipline/:disciplineHashID/registration">
                                <Registration />
                            </Route>
                            <Route exact path="/competition/:competitionHashID/discipline/:disciplineHashID/ladders">
                                <Ladders />
                            </Route>
                            <Route exact path="/competition/:competitionHashID/discipline/:disciplineHashID/heats">
                                <Heats />
                            </Route>
                            <Route exact path="/competition/:competitionHashID/discipline/:disciplineHashID/results">
                                <DisciplineResultList disciplineID={disciplineData?.discipline?.disciplineID} />
                            </Route>
                        </Switch>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </Fragment>
    );
};

export default withRouter(DisciplineDetail);
