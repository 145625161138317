/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import SendNotification from "./SendNotification";
import { useApolloClient, useQuery } from "@apollo/client";
import { GET_NOTIFICATIONS } from "../Queries/notification";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { FormattedDate, FormattedTime } from "react-intl";

const Notifications = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    const adminUserData = helper.checkAdminRights({ ...props, client }, ["1", "2", "3"]);

    const listVariables = {
        competitionHashID: props.match.params.competitionHashID,
        offset: 0,
        limit: 50,
        orderBy: "C.dateFrom DESC",
    };

    const [showAdd, setShowAdd] = useState(false);
    const {
        loading,
        error: getError,
        data: notificationListData,
        fetchMore,
    } = useQuery(GET_NOTIFICATIONS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
    });

    const openCloseModal = (open, hashID) => {
        setShowAdd(open);
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }
    return (
        <div>
            <div className="mb-3">
                <div className="d-flex align-items-center mb-3">
                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                        {translate.t("SendNewNotification")}
                    </button>
                </div>
                <div className="">
                    {!error ? (
                        <div>
                            <div className="data-list">
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div>{translate.t("NotificationTitle")}</div>
                                        <div>{translate.t("Message")}</div>
                                        <div className="text-right">{translate.t("SendDate")}</div>
                                    </div>
                                </div>
                                <Fragment>
                                    {notificationListData &&
                                        notificationListData.allNotifications &&
                                        notificationListData.allNotifications.map((item, index) => {
                                            return (
                                                <div key={index} className="data-list-item-content">
                                                    <div>{item.title}</div>
                                                    <div className="text-truncate">{item.text}</div>
                                                    <div className="d-flex">
                                                        <div className="ml-auto flex-none d-flex flex-column align-items-center w-auto">
                                                            <FormattedDate value={moment(item.dateAdd).toDate()} />{" "}
                                                            <FormattedTime value={moment(item.dateAdd).toDate()} />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </Fragment>
                            </div>

                            {!loading ? (
                                <Fragment>
                                    {notificationListData && notificationListData.allNotifications && notificationListData.allNotifications.length == 0 ? (
                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                    ) : (
                                        <Fragment>
                                            {!loading &&
                                                notificationListData &&
                                                notificationListData.allNotifications.length < notificationListData.notificationListCount && (
                                                    <div className="text-center p-5">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() =>
                                                                fetchMore({
                                                                    variables: { ...listVariables, offset: notificationListData.allNotifications.length },
                                                                    updateQuery: (prev, { fetchMoreResult }) => {
                                                                        if (!fetchMoreResult) return prev;
                                                                        return Object.assign({}, prev, {
                                                                            allNotifications: [...prev.allNotifications, ...fetchMoreResult.allNotifications],
                                                                        });
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            {" "}
                                                            {translate.t("LoadMore")}{" "}
                                                        </button>
                                                    </div>
                                                )}
                                        </Fragment>
                                    )}
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    ) : (
                        <Fragment>{error && <Error text={error} />}</Fragment>
                    )}
                </div>
            </div>
            {showAdd && <SendNotification openCloseModal={openCloseModal} listVariables={listVariables} />}
        </div>
    );
};

export default withRouter(Notifications);
