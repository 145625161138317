/*
    Smazání kategorie
*/
import React, { Component } from "react";
import { Mutation } from "@apollo/client/react/components";
import remove from "../../../Public/Images/remove.svg";
import { DELETE_LINKS, GET_LINKS } from "../Queries/index.js";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import Error from "../../../GlobalComponents/Error";
import translate from "../../Translations/index";

class DeleteLink extends Component {
    render() {
        /* 
            atribut refetchQueries opětovně provede dotaz definovaný v returnu v query
            update se provede po provedení mutace
        */

        return (
            <Mutation
                mutation={DELETE_LINKS}
                refetchQueries={(data) => {
                    return [
                        {
                            query: GET_LINKS,
                            variables: { lang: "cs" },
                        },
                    ];
                }}
                update={async (cache, response) => {
                    let notify = new Notification();
                    notify.setNotification(translate.t("SuccessFullyDeleted"));
                }}
            >
                {(deleteLink, { data, loading, error }) => {
                    if (error) {
                        const helper = new Helper();
                        return <Error text={helper.getApolloErrorText(error)} />;
                    } else {
                        return (
                            <img
                                onClick={() => this.props.deleteLink(deleteLink, this.props.linkID, this.props.parentID, this.props.subLink)}
                                className="remove-icon"
                                src={remove}
                            />
                        );
                    }
                }}
            </Mutation>
        );
    }
}

export default DeleteLink;
