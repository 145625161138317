/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_GLOBAL_DISCIPLINE, GET_GLOBAL_DISCIPLINE } from "../Queries/globalDiscipline";
import GlobalDisciplineLib from "../Library/globalDiscipline";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";

const AddEditGlobalDiscipline = (props) => {
    const globalDisciplineLib = new GlobalDisciplineLib(props);
    const [selectedTab, setTab] = useState(1);

    const [formData, setFormData] = useState({
        globalDisciplineID: props.selectedGlobalDisciplineID,
        title: "",
        sportID: "0",
    });

    const {
        loading: getLoading,
        error: getError,
        data: globalDisciplineData,
    } = useQuery(GET_GLOBAL_DISCIPLINE, {
        variables: { globalDisciplineID: formData.globalDisciplineID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.globalDisciplineID != "") {
                setFormData({
                    ...formData,
                    title: data.globalDiscipline.title,
                    globalDisciplineID: data.globalDiscipline.globalDisciplineID,
                    sportID: data.globalDiscipline.sportID,
                });
            }
        },
    });

    const [addGlobalDiscipline, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_GLOBAL_DISCIPLINE, {
        onCompleted(data) {
            setFormData({ ...formData, globalDisciplineID: data.addEditGlobalDiscipline.globalDisciplineID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addGlobalDisciplineData = () => {
        if (!!formData.title && formData.sportID != "0") {
            let dataToSend = {
                ...formData,
            };
            addGlobalDiscipline({
                variables: {
                    addEditData: dataToSend,
                },
            });
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md globalDiscipline-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {formData.globalDisciplineID && formData.globalDisciplineID != ""
                            ? translate.t("EditGlobalDiscipline")
                            : translate.t("AddGlobalDiscipline")}
                    </h4>

                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("DisciplineTitle")}</label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={formData.title}
                                                        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Sport")}</label>
                                                <div className="form-group">
                                                    <select
                                                        className="form-control form-control"
                                                        value={formData.sportID}
                                                        onChange={(e) => setFormData({ ...formData, sportID: e.target.value })}
                                                    >
                                                        <option value={0}>-- {translate.t("ChooseSport")} --</option>
                                                        {globalDisciplineData?.sports.map((item, index) => (
                                                            <option key={index} value={item.sportID}>
                                                                {item.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-end mt-4">
                                        <button type="button" className="btn btn-primary mr-2" onClick={() => addGlobalDisciplineData()}>
                                            {formData.globalDisciplineID && formData.globalDisciplineID != "" ? translate.t("Edit") : translate.t("Add")}
                                        </button>
                                        <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                                            {translate.t("Close")}
                                        </button>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddEditGlobalDiscipline;
