/*
    Přidání štítku
*/
import React, { useState, useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import searchIcon from "../../../Public/Images/search_green.svg";
import loadingIcon from "../../../Public/Images/Spin-1s-200px_blue.gif";

import { useDebouncedCallback } from "use-debounce";
import { SEARCH_ATHLETES } from "../Queries/athlete";

const RegisterTeam = (props) => {
    const node = useRef();
    const [text, setText] = useState("");
    const [searchAthletes, { loading, data }] = useLazyQuery(SEARCH_ATHLETES);
    // Debounce callback
    const [debouncedCallback] = useDebouncedCallback(
        // function
        (value) => {
            searchAthletes({ variables: { searchText: value, limit: 2 } });
        },
        // delay in ms
        300
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        setText("");
    };

    const searchTextChanged = (text) => {
        setText(text);
        debouncedCallback(text);
    };

    return (
        <div className="d-flex flex-column position-relative" ref={node}>
            <div className="input-group">
                <input
                    autoComplete="off"
                    className="form-control"
                    discipline="text"
                    name="name"
                    value={text}
                    onChange={(e) => searchTextChanged(e.target.value)}
                />
                <div className="input-group-append">
                    <div className="input-group-text">
                        {!loading ? (
                            <img className="hp-20 athlete-search-icon" src={searchIcon} />
                        ) : (
                            <img className="hp-20 athlete-search-icon" src={loadingIcon} />
                        )}
                    </div>
                </div>
            </div>

            {data && data.searchAthletes && data.searchAthletes.length > 0 && text.length > 0 && (
                <div className="d-flex flex-column search-result">
                    {data.searchAthletes.map((item, index) => {
                        return (
                            <div key={index} className="item" onClick={(e) => props.athleteSelected(item)}>
                                {item.name} {item.surname} ({item.email})
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default RegisterTeam;
