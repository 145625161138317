/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit rankingPointTable
 */
export const ADD_EDIT_RANKING_POINT_TABLE = gql`
    mutation AddEditRankingPointTable($addEditData: AddEditRankingPointTableDataInput) {
        addEditRankingPointTable(addEditData: $addEditData) {
            rankingPointTableID
        }
    }
`;

/**
 *  Data federace
 */

export const GET_RANKING_POINT_TABLE = gql`
    query RankingPointTable($rankingPointTableID: ID, $lang: String) {
        rankingPointTable(rankingPointTableID: $rankingPointTableID) {
            rankingPointTableID
            name
            sportID
            scoringPositionsNumber
        }
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 *  Seznam veškerých federací
 */
export const GET_RANKING_POINT_TABLES = gql`
    query RankingPointTables($filters: RankingPointTablesFilters, $lang: String) {
        allRankingPointTables(filters: $filters) {
            rankingPointTableID
            name
            sportID
            sport(lang: $lang) {
                title
            }
        }
        rankingPointTablesCount(filters: $filters)
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 * Smazání.
 */
export const DELETE_RANKING_POINT_TABLES = gql`
    mutation deleteRankingPointTables($rankingPointTableIDs: ID!) {
        deleteRankingPointTables(rankingPointTableIDs: $rankingPointTableIDs)
    }
`;

/**
 * add/edit rankingPoint
 */
export const ADD_EDIT_RANKING_POINT = gql`
    mutation AddEditRankingPoint($addEditData: AddEditRankingPointDataInput) {
        addEditRankingPoint(addEditData: $addEditData) {
            rank
            points
        }
    }
`;

/**
 * add/edit rankingPoints
 */
export const ADD_EDIT_RANKING_POINTS = gql`
    mutation AddEditRankingPoints($addEditData: [AddEditRankingPointDataInput]) {
        addEditRankingPoints(addEditData: $addEditData)
    }
`;

/**
 *  Data federace
 */

export const GET_RANKING_POINT = gql`
    query RankingPoint($rankingPointID: ID) {
        rankingPoint(rankingPointID: $rankingPointID) {
            rankingPointID
            rank
            points
        }
    }
`;

/**
 *  Seznam veškerých federací
 */
export const GET_RANKING_POINTS = gql`
    query RankingPoints($rankingPointTableID: ID, $filters: RankingPointsFilters) {
        allRankingPoints(rankingPointTableID: $rankingPointTableID, filters: $filters) {
            rankingPointID
            rank
            points
        }
        rankingPointTable(rankingPointTableID: $rankingPointTableID) {
            scoringPositionsNumber
        }
    }
`;

/**
 * Smazání federací.
 */
export const DELETE_RANKING_POINTS = gql`
    mutation deleteRankingPoints($rankingPointIDs: ID!) {
        deleteRankingPoints(rankingPointIDs: $rankingPointIDs)
    }
`;
