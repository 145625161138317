/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_ORGANISER_ADMIN_USER, GET_ORGANISER_ADMIN_USER } from "../Queries/organiser";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";

const AddEditOrganiserAdminUser = (props) => {
    const [formData, setFormData] = useState({
        organiserAdminUserID: props.organiserAdminUserID,
        adminUserID: 0,
        organiserID: props.organiserID,
        lang: translate.locale,
    });

    const {
        loading: getLoading,
        error: getError,
        data: organiserAdminUserData,
    } = useQuery(GET_ORGANISER_ADMIN_USER, {
        variables: { organiserAdminUserID: formData.organiserAdminUserID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.organiserAdminUserID != "") {
                setFormData({
                    ...formData,
                    adminUserID: data.organiserAdminUser.adminUserID,
                });
            }
        },
    });

    const [addOrganiserAdminUser, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_ORGANISER_ADMIN_USER, {
        onCompleted(data) {
            setFormData({ ...formData, organiserAdminUserID: data.addEditOrganiserAdminUser.organiserAdminUserID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addOrganiserAdminUserData = () => {
        if (!!formData.adminUserID) {
            let dataToSend = {
                ...formData,
            };
            addOrganiserAdminUser({
                variables: {
                    addEditData: dataToSend,
                },
            });
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    const changeSelectedSports = (item) => {
        if (formData.sportIDs.includes(item.sportID)) {
            setFormData({ ...formData, sportIDs: [...formData.sportIDs.filter((filterItem) => filterItem != item.sportID)] });
        } else {
            formData.sportIDs.push(item.sportID);
            setFormData({ ...formData, sportIDs: [...formData.sportIDs] });
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md organiserAdminUser-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {formData.organiserAdminUserID && formData.organiserAdminUserID != ""
                            ? translate.t("EditOrganiserAdminUser")
                            : translate.t("AddOrganiserAdminUser")}
                    </h4>

                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("UserAccount")}</label>
                                                <select
                                                    value={formData.adminUserID}
                                                    className="form-control"
                                                    onChange={(e) => setFormData({ ...formData, adminUserID: e.target.value })}
                                                >
                                                    <option value={0}>--{translate.t("ChooseOrganiserAccount")}--</option>
                                                    {organiserAdminUserData?.organiserUnassignedAdminUsers?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.adminUserID}>
                                                                {item.name} {item.surname} ({item.email})
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-4">
                                        <button type="button" className="btn btn-primary mr-2" onClick={() => addOrganiserAdminUserData()}>
                                            {formData.organiserAdminUserID && formData.organiserAdminUserID != "" ? translate.t("Edit") : translate.t("Add")}
                                        </button>
                                        <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                                            {translate.t("Close")}
                                        </button>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddEditOrganiserAdminUser;
