import React, { Component, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import useOnClickOutside from "use-onclickoutside";

const INITIAL_STATE = {
    value: "",
};

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

/**
 * Vyhledávací komponenta (input) s debounce timeout efektem
 * @param {Object} props - parametry komponenty
 * @param {String} props.value - parametry komponenty
 * @param {function():void} props.onClickedOutside - callback volaný při změně hodnoty inputu
 * @param {function(String):void} props.textChanged - callback volaný když se změní hodnota value
 * @param {function(String):void} props.onChange - callback (s timeout debounce efektem) volaný když se změní hodnota value
 * @param {String} props.className - Název třídy vyhledávacího inputu
 * @param {String} props.placeholder - placeholder vyhledávacího inputu
 * @returns
 */
const SearchInput = (props) => {
    const value = useRef(!!props.value ? props.value : "");
    const [timer, setTimer] = useState(null);
    const [count, setCount] = useState(0);

    const inputRef = useRef();

    useOnClickOutside(inputRef, () => {
        props.onClickedOutside && props.onClickedOutside();
    });

    useEffect(() => {
        if (value.current != props.value) {
            //console.log(props.value);
            value.current = props.value;

            //force render to display current value
            setCount(count + 1);
        }
    }, [props.value]);

    const changeText = (e) => {
        clearTimeout(timer);

        value.current = e.target.value;
        const tmr = setTimeout(triggerChange, props.waitInterwal ? props.waitInterwal : WAIT_INTERVAL);
        setTimer(tmr);

        if (props.textChanged) {
            !!props.textChanged && props.textChanged(e.target.value);
        }
    };

    const triggerChange = () => {
        !!props.onChange && props.onChange(value.current);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY) {
            clearTimeout(timer);
            triggerChange();
        }
        !!props.onKeyDown && props.onKeyDown(e);
    };

    return (
        <input
            type="text"
            className={props.className}
            placeholder={props.placeholder}
            value={value.current}
            onChange={(e) => changeText(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            autoComplete="new-password"
            ref={inputRef}
        />
    );
};

export default SearchInput;
