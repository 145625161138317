/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_COMPETITION_SPONSOR, GET_COMPETITION_SPONSOR } from "../Queries/competitionSponsor";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import { SERVER_URL, COMPETITION_SPONSOR_IMAGE_URL, MAX_FILE_UPLOAD_SIZE } from "../../../Config";
import remove from "../../../Public/Images/remove.svg";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

const AddEditCompetitionSponsor = (props) => {
    const client = useApolloClient();
    const [fileInputKey, setFileInputKey] = useState(0);
    const [formData, setFormData] = useState({
        competitionSponsorID: props.competitionSponsorID,
        competitionID: props.competitionID,
        name: "",
        photo: "",
        photoURL: "",
        lang: translate.locale,
    });
    const [allLanguageMutations, setAllLanguageMutations] = useState([]);

    const {
        loading: getLoading,
        error: getError,
        data: competitionSponsorData,
    } = useQuery(GET_COMPETITION_SPONSOR, {
        variables: { competitionSponsorID: formData.competitionSponsorID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            setAllLanguageMutations(data.allLanguageMutations);
            if (formData.competitionSponsorID != 0) {
                setFormData({
                    ...formData,
                    name: data.competitionSponsor.name,
                    photoURL: data.competitionSponsor.photo
                        ? SERVER_URL + "/" + COMPETITION_SPONSOR_IMAGE_URL + "/stredni_" + data.competitionSponsor.photo
                        : "",
                });
            }
        },
    });

    const [addCompetitionSponsor, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_COMPETITION_SPONSOR, {
        onCompleted(data) {
            setFormData({ ...formData, competitionSponsorID: data.addEditCompetitionSponsor.competitionSponsorID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const validateFormData = () => {
        if (!formData.name) {
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (!validateFormData()) {
            let notify = new Notification();
            notify.setNotification(translate.t("AllRowsMustHavePositionAndPointsFilled"), "danger");
            return;
        }

        const dataToSend = { ...formData };

        addCompetitionSponsor({ variables: { addEditData: dataToSend } });
    };

    const openImage = (e) => {
        var file = e.target.files[0];

        if (file) {
            var reader = new FileReader();
            reader.onload = (onLoadE) => {
                console.log(onLoadE);
                if (onLoadE.total / 1000000 <= MAX_FILE_UPLOAD_SIZE) {
                    setFormData({ ...formData, photoURL: reader.result, photo: file });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("MaxFileSizeExceeded", { maxFileSize: MAX_FILE_UPLOAD_SIZE }), "danger");
                    setFileInputKey(fileInputKey + 1);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg competitionSponsor-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {formData.competitionSponsorID && formData.competitionSponsorID != 0
                            ? translate.t("EditCompetitionSponsor")
                            : translate.t("AddCompetitionSponsor")}
                    </h4>
                    <button competitionSponsor="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("Name")}</label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        competitionSponsor="text"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="form-group input-group">
                                                <div className="form-group">
                                                    <label className="input-label">
                                                        {translate.t("CompetitionSponsorPhoto")} (max {MAX_FILE_UPLOAD_SIZE}Mb)
                                                    </label>
                                                    {!formData.photoURL && !formData.photo ? (
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customPhoto"
                                                                name="photo"
                                                                accept="image/*"
                                                                onChange={(e) => openImage(e)}
                                                                key={fileInputKey}
                                                            />
                                                            <label className="custom-file-label" htmlFor="customPhoto">
                                                                {translate.t("ChoosePhoto")}
                                                            </label>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div className="d-flex flex-column align-items-start">
                                                                <img className="maxwidth-100 competitionSponsor-img" src={formData.photoURL} />
                                                                <img
                                                                    onClick={() => setFormData({ ...formData, photo: "", photoURL: "" })}
                                                                    className="remove-icon mt-2"
                                                                    src={remove}
                                                                    title={translate.t("Remove")}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <button competitionSponsor="button" className="btn btn-primary flex-fill" onClick={() => handleSubmit()}>
                        {formData.competitionSponsorID && formData.competitionSponsorID != 0 ? translate.t("Edit") : translate.t("Add")}
                    </button>
                    <button competitionSponsor="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddEditCompetitionSponsor;
