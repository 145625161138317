/**
 *  komponenta pro štítky pro produkty
 */
import React, { Component, Fragment, useState } from "react";
import AddEditCompetition from "./AddEditCompetition";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_COMPETITIONS, DELETE_COMPETITIONS } from "../Queries/competition";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import CompetitionLib from "../Library/competition";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import moment from "moment";
import { FormattedDate } from "react-intl";
import { NavLink, withRouter } from "react-router-dom";

const Competitions = (props) => {
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    const client = useApolloClient();
    const adminUserData = helper.checkAdminRights({ ...props, client }, ["1", "2", "3"]);

    const listVariables = {
        filters: {
            offset: 0,
            limit: 50,
            orderBy: "C.dateFrom DESC",
        },
    };

    const [selectedCompetitions, setSelectedCompetitions] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedCompetitionHashID, setSelectedCompetitionHashID] = useState(0);
    const {
        loading,
        error: getError,
        data: competitionListData,
        fetchMore,
    } = useQuery(GET_COMPETITIONS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
    });

    const [deleteCompetition, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_COMPETITIONS, {
        update(cache, response) {
            let competition = new CompetitionLib(this);
            competition.updateAfterDelete(cache, response, listVariables);

            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, hashID) => {
        setSelectedCompetitionHashID(hashID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteCompetitions funkce, která posílá data na server
     * @param {*} hashID ID competitionu
     */

    const initiateDeleteCompetition = (e, hashID) => {
        var checked = e.target.checked;
        var arr = [...selectedCompetitions];

        if (!checked) {
            for (let i in selectedCompetitions) {
                if (selectedCompetitions[i] == hashID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [hashID, ...arr];
        }

        setSelectedCompetitions(arr);
    };

    const delCompetition = (action) => {
        if (action) {
            let hashIDs = selectedCompetitions.join(",");

            deleteCompetition({
                variables: {
                    hashIDs: hashIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedCompetitions.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div id="settings" className="whole-container">
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("CompetitionList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Competition")}</div>
                                                <div className="text-center">{translate.t("Organizator")}</div>
                                                <div className="text-center">{translate.t("CompetitionDate")}</div>
                                                <div className="text-center">{translate.t("CompetitionStateTitle")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {competitionListData &&
                                                competitionListData.competitionsByRole &&
                                                competitionListData.competitionsByRole.map((item, index) => {
                                                    var checked = false;
                                                    for (let i in selectedCompetitions) {
                                                        if (selectedCompetitions[i] == item.hashID) checked = true;
                                                    }

                                                    return (
                                                        <div key={index} className="data-list-item-content">
                                                            <div>{item.title}</div>
                                                            <div className="text-center">{item?.organiser?.name}</div>
                                                            <div className="text-center">
                                                                <FormattedDate value={moment(item.dateFrom).toDate()} />
                                                                {item.dateTo && (
                                                                    <Fragment>
                                                                        <span> - </span>
                                                                        <FormattedDate value={moment(item.dateTo).toDate()} />
                                                                    </Fragment>
                                                                )}
                                                            </div>
                                                            <div className="text-center">
                                                                {item.competitionStateID == 1 && (
                                                                    <span className="badge badge-success p-2">{translate.t("Preparing")}</span>
                                                                )}
                                                                {item.competitionStateID == 2 && (
                                                                    <span className="badge badge-orange p-2">{translate.t("InProgress")}</span>
                                                                )}
                                                                {item.competitionStateID == 3 && (
                                                                    <span className="badge badge-danger p-2">{translate.t("Finished")}</span>
                                                                )}
                                                            </div>
                                                            <div className="text-right">
                                                                <NavLink activeClassName="active" strict to={"/competition/" + item.hashID + "/primary-data"}>
                                                                    <img className="edit-icon" src={edit} />
                                                                </NavLink>
                                                                <input
                                                                    className="delete-checked"
                                                                    type="checkbox"
                                                                    name="deleteCompetition[]"
                                                                    checked={checked}
                                                                    onChange={(e) => initiateDeleteCompetition(e, item.hashID)}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </Fragment>
                                    </div>

                                    {!loading ? (
                                        <Fragment>
                                            {competitionListData &&
                                            competitionListData.competitionsByRole &&
                                            competitionListData.competitionsByRole.length == 0 ? (
                                                <NoItems text={translate.t("NoItemsAddedYet")} />
                                            ) : (
                                                <Fragment>
                                                    {!loading &&
                                                        competitionListData &&
                                                        competitionListData.competitionsByRole.length < competitionListData.competitionsByRoleListCount && (
                                                            <div className="text-center p-5">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={() =>
                                                                        fetchMore({
                                                                            variables: {
                                                                                ...listVariables,
                                                                                offset: competitionListData.competitionsByRole.length,
                                                                            },
                                                                            updateQuery: (prev, { fetchMoreResult }) => {
                                                                                if (!fetchMoreResult) return prev;
                                                                                return Object.assign({}, prev, {
                                                                                    competitionsByRole: [
                                                                                        ...prev.competitionsByRole,
                                                                                        ...fetchMoreResult.competitionsByRole,
                                                                                    ],
                                                                                });
                                                                            },
                                                                        })
                                                                    }
                                                                >
                                                                    {" "}
                                                                    {translate.t("LoadMore")}{" "}
                                                                </button>
                                                            </div>
                                                        )}
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditCompetition
                    openCloseModal={openCloseModal}
                    selectedCompetitionHashID={selectedCompetitionHashID}
                    setSelectedCompetitionHashID={setSelectedCompetitionHashID}
                    listVariables={listVariables}
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delCompetition} />}
        </div>
    );
};

export default withRouter(Competitions);
