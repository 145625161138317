import React, { Fragment } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { removeElement, selectElement, setElementData, showSettings } from "../Library/contentManager";
import { cmDataVar } from "../Library/cmData";
import { CONTENT_MANAGER_PAGE_LIST } from "../Queries/contentManager";
import translate from "../../Translations/index";
import moment from "moment";
import Notification from "../../../Library/notification";
import PageSelect from "./PageSelect";
import TinyMceInlineTextOnlyEditor from "../../TinyMce/Components/TinyMceInlineTextOnlyEditor";

const ArticlesElement = ({ lastIndexes, element, handle }) => {
    const cmData = useReactiveVar(cmDataVar);

    var parentIndexes = "";
    var indexes = lastIndexes.split("-");
    if (indexes.length > 2) {
        indexes.pop();
        parentIndexes = indexes.join("-");
    }
    const { data: pageList } = useQuery(CONTENT_MANAGER_PAGE_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        onError(err) {
            console.log(err);
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });
    const articleList = [];
    /*
    const { data: articleList, refetch } = useQuery(ARTICLES, {
        variables: {
            pageID: element.pageID,
            filters: {
                offset: 0,
                limit: parseInt(element.articlesOnPage),
                pageStatusID: 1,
            },
        },

        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        onCompleted(data) {},
        onError(err) {
            console.log(err);
            showMessage(!!err.message ? err.message : err.toString(), "danger", 4000);
        },
    });
    */

    return (
        <div className={`cm-elm-content`} onClick={(e) => selectElement(e, "articles", lastIndexes)}>
            {cmData.showHelpers && element.showMenu && (
                <div className={`cm-enu`} onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex">
                        <div className="mr-1">
                            <div className={`item-ontainer`} onClick={() => showSettings("articles", lastIndexes)}>
                                <i className={`las la-cog text-primary `} title={translate.t("ModuleSettings")} />
                            </div>
                            {element.showSettings && (
                                <Fragment>
                                    <div className={`settings settings-articles`}>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("LoadFromPage")}:</div>
                                                </div>
                                                <PageSelect
                                                    pageItemList={pageList?.nestedPages}
                                                    value={element.pageID}
                                                    name="pageID"
                                                    onChange={(e) => setElementData(e, lastIndexes, "articles")}
                                                    selectDefaultTitle={`-- ${translate.t("NotSelected")} --`}
                                                    excludeOptionSelector={(item) => {
                                                        return item.pageContentTypeID != 5;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("NumberOfDisplayedArticles")}:</div>
                                                </div>
                                                <select
                                                    value={element.articlesOnPage}
                                                    className="form-control form-control-sm"
                                                    name="articlesOnPage"
                                                    onChange={(e) => setElementData(e, lastIndexes, "articles")}
                                                >
                                                    <option value="2"> 2 </option>
                                                    <option value="3"> 3 </option>
                                                    <option value="4"> 4 </option>
                                                    <option value="6"> 6 </option>
                                                    <option value="8"> 8 </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("ShowHeaderWithTitle")}:</div>
                                                </div>

                                                <select
                                                    value={element.showHeader}
                                                    className="form-control form-control-sm"
                                                    name="showHeader"
                                                    onChange={(e) => setElementData(e, lastIndexes, "articles")}
                                                >
                                                    <option value="0"> {translate.t("No")} </option>
                                                    <option value="1"> {translate.t("Yes")} </option>
                                                </select>
                                                {element.showHeader}
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div className="input-group-text">{translate.t("Class")}:</div>
                                                </div>
                                                <input
                                                    type="text"
                                                    value={element.class}
                                                    className="form-control form-control-sm"
                                                    name="class"
                                                    onChange={(e) => setElementData(e, lastIndexes, "class")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`cm-closing-overlay`} onClick={() => showSettings("articles", lastIndexes)}></div>
                                </Fragment>
                            )}
                        </div>
                        {parentIndexes != "" && (
                            <div className="mr-1">
                                <div
                                    className={`d-flex align-items-center justify-content-center item-container`}
                                    onClick={(e) => selectElement(e, "drag_column", parentIndexes)}
                                >
                                    <i className={`las la-level-up-alt text-secondary `} title={translate.t("ParentElement")} />
                                </div>
                            </div>
                        )}
                        <div className={`d-flex align-items-center justify-content-center mr-1 item-container`} onClick={() => removeElement(lastIndexes)}>
                            <i className={`las la-times text-danger `} title={translate.t("RemoveElement")} />
                        </div>
                        <div className={`cm-dragable-item`} {...handle}>
                            <div className={`d-flex align-items-center justify-content-center item-container} cursor-move`}>
                                <i className={`las la-arrows-alt-v text-primary`} title={translate.t("MoveElement")} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div
                className={`cm-content`}
                onClick={(e) => {
                    e.stopPropagation();
                    !element.showMenu && selectElement(e, "articles", lastIndexes);
                }}
            >
                {element.pageID == 0 ? (
                    <div className={`cm-actuality-not-loaded-wrapper`}>
                        <div className={`cm-custom-block-title`}>{translate.t("ArticleBlock")}</div>
                        <div className={`cm-custom-block-sub-title`}>{translate.t("ChoosePageToLoadArticles")}</div>
                        <div className="mt-3">
                            <PageSelect
                                pageItemList={pageList?.nestedPages}
                                value={element.pageID}
                                name="pageID"
                                onChange={(e) => setElementData(e, lastIndexes, "articles")}
                                selectDefaultTitle={`-- ${translate.t("NotSelected")} --`}
                                excludeOptionSelector={(item) => {
                                    return item.pageContentTypeID != 5;
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={`row cm-actuality-wrapper}`}>
                        {element.showHeader == "1" && (
                            <div className={`header`}>
                                {cmData.showHelpers ? (
                                    <div className={`title edit`}>
                                        <TinyMceInlineTextOnlyEditor
                                            value={!!element.htmlTitle ? element.htmlTitle : ""}
                                            validElements={"p"}
                                            onChange={(content, editor) => {
                                                setElementData(null, lastIndexes, "articles", editor.getContent({ format: "text" }), null, "title");
                                                setElementData(null, lastIndexes, "articles", content, null, "htmlTitle");
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className={`title`} dangerouslySetInnerHTML={{ __html: element.title }}></div>
                                )}

                                {cmData.showHelpers ? (
                                    <div className={`link edit`}>
                                        <TinyMceInlineTextOnlyEditor
                                            value={!!element.htmlHeaderLinkTitle ? element.htmlHeaderLinkTitle : ""}
                                            validElements={"p"}
                                            onChange={(content, editor) => {
                                                setElementData(null, lastIndexes, "articles", editor.getContent({ format: "text" }), null, "headerLinkTitle");
                                                setElementData(null, lastIndexes, "articles", content, null, "htmlHeaderLinkTitle");
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className={`link`} dangerouslySetInnerHTML={{ __html: element.headerLinkTitle }}></div>
                                )}
                            </div>
                        )}
                        <div className={`row cm-actuality-list-wrapper`}>
                            {articleList?.articles?.length > 0 ? (
                                <>
                                    {articleList?.articles?.map((item, index) => (
                                        <div key={index} className={`col-4`}>
                                            <div className={`meta`}>
                                                {!!item.publicationDate && (
                                                    <div className={`date`}>
                                                        <i className="las la-calendar"></i>
                                                        {moment(item.publicationDate).format("D. M. YYYY")}
                                                    </div>
                                                )}
                                            </div>
                                            <div className={`title`}>{item.title}</div>
                                            {!!item.categories?.length && (
                                                <div className={`tags`}>
                                                    {item.categories.map((item, index) => (
                                                        <div key={index} className={`tag`}>
                                                            {item.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            <div className={`anotation`} dangerouslySetInnerHTML={{ __html: item.anotation }}></div>
                                            <div className={`whole-message-link`}>
                                                <i className="las la-arrow-right"></i>
                                                {translate.t("WholeMessage")}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div className="d-flex align-items-center justify-content-center fw-bold">
                                    {translate.t("ThreIsNoActualitiesInThisPageYet")}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {cmData.showHelpers && <div className={`cm-border ${element.showMenu ? `cm-selected` : ""}`}></div>}
        </div>
    );
};

export default ArticlesElement;
