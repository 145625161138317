/**
 * soubor se seznamem veškerých graphql dotazů pro tinyMCE
 */
import gql from "graphql-tag";

export const GET_TINYMCE_IMAGES = gql`
    query allTinyMceImages($filters: TinyMceFilters) {
        allTinyMceImages(filters: $filters) {
            tinyMceImageID
            name
        }
    }
`;

export const UPLOAD_TINYMCE_IMAGES = gql`
    mutation uploadTinyMceImages($files: [Upload]) {
        uploadTinyMceImages(files: $files)
    }
`;

/**
 * smazání fotek
 */
export const DELETE_TINYMCE_IMAGES = gql`
    mutation deleteTinyMceImages($IDs: [ID]!) {
        deleteTinyMceImages(IDs: $IDs)
    }
`;
export const DELETE_TINYMCE_IMAGES_PERMANENTLY = gql`
    mutation deleteTinyMceImagesPermanently($IDs: [ID]!) {
        deleteTinyMceImagesPermanently(IDs: $IDs)
    }
`;

/**
 * obnovení fotek
 */
export const RECOVER_TINYMCE_IMAGES = gql`
    mutation recoverTinyMceImages($IDs: [ID]!) {
        recoverTinyMceImages(IDs: $IDs)
    }
`;

export const GET_TINYMCE_FILES = gql`
    query allTinyMceFiles($filters: TinyMceFilters) {
        allTinyMceFiles(filters: $filters) {
            tinyMceFileID
            name
        }
    }
`;

export const UPLOAD_TINYMCE_FILES = gql`
    mutation uploadTinyMceFiles($files: [Upload]) {
        uploadTinyMceFiles(files: $files)
    }
`;

/**
 * smazání souborů
 */
export const DELETE_TINYMCE_FILES = gql`
    mutation deleteTinyMceFiles($IDs: [ID]!) {
        deleteTinyMceFiles(IDs: $IDs)
    }
`;
export const DELETE_TINYMCE_FILES_PERMANENTLY = gql`
    mutation deleteTinyMceFilesPermanently($IDs: [ID]!) {
        deleteTinyMceFilesPermanently(IDs: $IDs)
    }
`;

/**
 * obnovení souborů
 */
export const RECOVER_TINYMCE_FILES = gql`
    mutation recoverTinyMceFiles($IDs: [ID]!) {
        recoverTinyMceFiles(IDs: $IDs)
    }
`;
