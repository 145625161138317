import gql from "graphql-tag";

export const GET_NOTIFICATIONS = gql`
    {
        notifications @client {
            text
            success
            error
        }
    }
`;

export const INITIAL_DATA = gql`
    {
        notifications @client
        loginPlace @client
        isLoggedIn @client
    }
`;

/**
 *  jayzkove mutace
 */

export const GET_ADMIN_LANGUAGES = gql`
    query allAdminLanguageMutations {
        allAdminLanguageMutations(onlyEnabled: true) {
            title
            suffix
            file
            langTitle
            priority
            main
        }
    }
`;

export const ADMIN_USER = gql`
    {
        adminUser {
            name
            surname
            email
            roleID
            organiserID
        }
    }
`;
