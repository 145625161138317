/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_LADDER, GET_LADDER } from "../Queries/ladder";
import LadderLib from "../Library/ladder";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import { Editor } from "@tinymce/tinymce-react";
import { GET_ADMINUSERS } from "../../AdminUser/Queries/adminUser";
import { ADMIN_USER } from "../../../GlobalQueries/globalQueries";
import LadderOrganiserList from "./LadderOrganiserList";

const AddEditLadder = (props) => {
    const client = useApolloClient();
    const ladderLib = new LadderLib(props);
    const helper = new Helper();
    const adminUserData = client.readQuery({ query: ADMIN_USER });
    const [selectedTab, setTab] = useState(1);
    const [formData, setFormData] = useState({
        selectedLang: "cs",
        hashID: props.selectedLadderHashID,
        lang: translate.locale,
        langs: [],
    });
    const [allLanguageMutations, setAllLanguageMutations] = useState([]);

    const {
        loading: getLoading,
        error: getError,
        data: ladderData,
    } = useQuery(GET_LADDER, {
        variables: { hashID: formData.hashID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            setAllLanguageMutations(data.allLanguageMutations);

            if (formData.hashID != "") {
                let langs = ladderLib.completeLangsArray(helper.copyObjectsWithoutParam(data.ladderWithLangs.langs, "__typename"), data.allLanguageMutations);
                console.log(langs);
                setFormData({ ...formData, langs: langs });
            } else {
                let langs = ladderLib.completeLangsArray([], data.allLanguageMutations);
                setFormData({ ...formData, langs: langs });
            }
        },
    });

    const [addLadder, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_LADDER, {
        onCompleted(data) {
            setFormData({ ...formData, hashID: data.addEditLadder.hashID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className={`Modal__Bootstrap modal-dialog ladder-modal modal-lg`}
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{formData.hashID && formData.hashID != "" ? translate.t("EditLadder") : translate.t("AddLadder")}</h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 && (
                        <div className="ml-auto">
                            <select
                                className="form-control"
                                name="selectedLang"
                                onChange={(e) => {
                                    ladderLib.formHandle(e, formData, setFormData);
                                }}
                            >
                                {allLanguageMutations &&
                                    allLanguageMutations.map((item, index) => (
                                        <option key={index} value={item.suffix}>
                                            {" "}
                                            {item.suffix}{" "}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <ul className={`nav nav-tabs`} role="tablist">
                                        <li className={`nav-item mr-2`}>
                                            <a className={`nav-link ${selectedTab == 1 ? `active` : ""}`} onClick={(e) => setTab(1)}>
                                                {translate.t("MainData")}
                                            </a>
                                        </li>
                                        {["1", "2"].includes(adminUserData.adminUser.roleID) && (
                                            <li className={`nav-item mr-2`}>
                                                <a
                                                    className={`nav-link ${selectedTab == 2 ? `active` : ""}  ${!formData.hashID ? "disabled" : ""}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (formData.hashID) setTab(2);
                                                    }}
                                                >
                                                    {translate.t("Organisers")}
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        {selectedTab == 1 && (
                                            <>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="input-label">
                                                                {translate.t("LadderTitle")}{" "}
                                                                {allLanguageMutations && allLanguageMutations.length > 1 && (
                                                                    <Fragment>({formData.selectedLang})</Fragment>
                                                                )}
                                                            </label>
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="title"
                                                                    value={ladderLib.getLangValue(formData, "title")}
                                                                    onChange={(e) => ladderLib.formLangHandle(e, formData, setFormData)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="input-label">
                                                                {translate.t("Description")}{" "}
                                                                {allLanguageMutations && allLanguageMutations.length > 1 && (
                                                                    <Fragment>({formData.selectedLang})</Fragment>
                                                                )}
                                                            </label>
                                                            <div className="form-group">
                                                                <Editor
                                                                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                                                                    name="description"
                                                                    value={ladderLib.getLangValue(formData, "description")}
                                                                    init={{
                                                                        promotion: false,
                                                                        height: 260,
                                                                        plugins: "link image code",
                                                                        toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code",
                                                                    }}
                                                                    onEditorChange={(content) =>
                                                                        ladderLib.formLangHandleEditor(content, "description", formData, setFormData)
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary flex-fill"
                                                        onClick={() => ladderLib.addLadder(addLadder, formData)}
                                                    >
                                                        {formData.hashID && formData.hashID != "" ? translate.t("Edit") : translate.t("Add")}
                                                    </button>
                                                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                                                        {translate.t("Close")}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                    {selectedTab == 2 && (
                        <LadderOrganiserList
                            ladderID={ladderData.ladderWithLangs.ladderID}
                            openCloseModal={(status) => props.openCloseModal(status)}
                            updated={() => {
                                !!props.updated && props.updated();
                            }}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddEditLadder;
