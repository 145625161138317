import React, { Fragment } from "react";
import { removeElement, selectElement, setElementData, showSettings } from "../Library/contentManager";
import { cmDataVar } from "../Library/cmData";
import { useReactiveVar } from "@apollo/client";
import TinyMceInlineTextOnlyEditor from "../../TinyMce/Components/TinyMceInlineTextOnlyEditor";
import arrowRightBlueIcon from "../../../Public/Images/ContentManager/arrow_right_blue.svg";
import arrowRightIcon from "../../../Public/Images/ContentManager/arrow_right.svg";
import translate from "../../Translations/index";

const ButtonElement = ({ lastIndexes, element, handle }) => {
    const cmData = useReactiveVar(cmDataVar);

    console.log(element);
    var parentIndexes = "";

    var indexes = lastIndexes.split("-");
    if (indexes.length > 2) {
        indexes.pop();
        parentIndexes = indexes.join("-");
    }

    return (
        <div className={`cm-elm-content`}>
            {cmData.showHelpers && element.showMenu && (
                <div className={`cm-menu`} onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex">
                        <div className="mr-1">
                            <div className={`item-container`} onClick={() => showSettings("button", lastIndexes)}>
                                <i className={`las la-cog text-primary `} title={translate.t("ModuleSettings")} />
                            </div>
                            {element.showSettings && (
                                <Fragment>
                                    <div className={`settings settings-button`}>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">{translate.t("ButtonColor")}:</div>
                                                </div>
                                                <select
                                                    value={element.color}
                                                    className="form-control form-control-sm"
                                                    name="color"
                                                    onChange={(e) => setElementData(e, lastIndexes, "button")}
                                                >
                                                    <option value="blue"> {translate.t("Blue")} </option>
                                                    <option value="white"> {translate.t("White")} </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">{translate.t("ButtonLink")}:</div>
                                                </div>
                                                <input
                                                    type="text"
                                                    value={element.linkUrl}
                                                    className="form-control form-control-sm"
                                                    name="linkUrl"
                                                    onChange={(e) => setElementData(e, lastIndexes, "button")}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">{translate.t("Class")}:</div>
                                                </div>
                                                <input
                                                    type="text"
                                                    value={element.className}
                                                    className="form-control form-control-sm"
                                                    name="className"
                                                    onChange={(e) => setElementData(e, lastIndexes, "button")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`cm-closing-overlay`} onClick={() => showSettings("button", lastIndexes)}></div>
                                </Fragment>
                            )}
                        </div>
                        {parentIndexes != "" && (
                            <div className="mr-1">
                                <div
                                    className={`d-flex align-items-center justify-content-center item-container`}
                                    onClick={(e) => selectElement(e, "drag_column", parentIndexes)}
                                >
                                    <i className={`las la-level-up-alt text-secondary `} title={translate.t("ParentElement")} />
                                </div>
                            </div>
                        )}
                        <div className={`d-flex align-items-center justify-content-center mr-1 item-container`} onClick={() => removeElement(lastIndexes)}>
                            <i className={`las la-times text-danger `} title={translate.t("RemoveElement")} />
                        </div>
                        <div className={`cmd-ragable-item`} {...handle}>
                            <div className={`d-flex align-items-center justify-content-center item-container cursor-move`}>
                                <i className={`las la-arrows-alt-v text-primary`} title={translate.t("MoveElement")} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div
                className={`cm-button-content`}
                onClick={(e) => {
                    e.stopPropagation();
                    !element.showMenu && selectElement(e, "button", lastIndexes);
                }}
            >
                {cmData.showHelpers ? (
                    <button className={`btn ${element.color} d-flex align-items-center`}>
                        <TinyMceInlineTextOnlyEditor
                            value={!!element.htmlButtonText ? element.htmlButtonText : ""}
                            validElements={"p,br"}
                            onChange={(content, editor) => {
                                setElementData(null, lastIndexes, "button", editor.getContent({ format: "text" }), null, "buttonText");
                                setElementData(null, lastIndexes, "button", content, null, "htmlButtonText");
                            }}
                        />{" "}
                        {element.color == "blue" ? <img className="arrow" src={arrowRightIcon} /> : <img className="arrow" src={arrowRightBlueIcon} />}
                    </button>
                ) : (
                    <button className={`btn ${element.color} d-flex align-items-center`}>
                        <span dangerouslySetInnerHTML={{ __html: element.buttonText }}></span>
                        {element.color == "blue" ? <img className="arrow" src={arrowRightIcon} /> : <img className="arrow" src={arrowRightBlueIcon} />}
                    </button>
                )}
            </div>

            {cmData.showHelpers && <div className={`cm-col-border ${element.showMenu ? `cm-selected` : ""}`}></div>}
        </div>
    );
};

export default ButtonElement;
