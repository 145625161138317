import React,{ Component } from 'react';
import Modal from 'react-modal';
import translate from '../Modules/Translations/index';

Modal.setAppElement('#root');

class ModalNotification extends Component {
	  	
  render() {
	
   
    return (
    	<Modal
	        className="Modal__Bootstrap modal-dialog"
	        closeTimeoutMS={150}
	        isOpen={true}
	        onRequestClose={() => this.props.callback(false)}
	    >
	        <div className="modal-content">
	          <div className="modal-header">
				<h4 className="modal-title">{translate.t("Alert")}</h4>
	            <button type="button" className="close" onClick={() => this.props.callback(false)}>
	              <span aria-hidden="true">x</span>
	              <span className="sr-only">Close</span>
	            </button>
	          </div>
	          <div className="modal-body">
	          
	          {this.props.yesNo ? this.props.text : <div className="alert alert-danger">{this.props.text}</div>}	
	                    
	          </div>
			  {this.props.yesNo ? 
				<div className="modal-footer"> 
					<button type="button" className="btn btn-primary flex-fill" onClick={() => this.props.callback(true)}>{translate.t("Yes")}</button>
					<button type="button" className="btn btn-danger" onClick={() => this.props.callback(false)}>{translate.t("No")}</button>
				</div>
			  :
				<div className="modal-footer"> 
					<button type="button" className="btn btn-danger" onClick={() => this.props.callback(false)}>{translate.t("Close")}</button>
				</div>
			  }
	        </div>
	    </Modal>
    );
  }
  
}


export default ModalNotification;
