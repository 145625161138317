/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit athlete
 */
export const ADD_EDIT_ATHLETE = gql`
    mutation AddEditAthlete($addEditData: AddEditAthleteDataInput) {
        addEditAthlete(addEditData: $addEditData) {
            athleteID
            name
            surname
            email
            phone
            birthDate
        }
    }
`;

/**
 *  Athlete data
 */

export const GET_ATHLETE = gql`
    query Athlete($athleteID: ID!) {
        athlete(athleteID: $athleteID) {
            athleteID
            countryID
            sexID
            clubID
            internationalLicence
            licenceNumber
            name
            surname
            email
            phone
            photo
            bibliography
            birthDate
        }
        allCountries {
            countryID
            name
        }
        clubs {
            clubID
            countryID
            name
        }
        sexList {
            sexID
            name
        }
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const GET_ATHLETES = gql`
    query AllAthletes($offset: Int, $limit: Int) {
        allAthletes(offset: $offset, limit: $limit) {
            athleteID
            name
            surname
            email
            phone
            photo
            bibliography
            birthDate
        }
        athleteListCount
    }
`;

/**
 *  Seznam veškerých athleteů
 */
export const SEARCH_ATHLETES = gql`
    query SearchAthletes($searchText: String, $limit: Int) {
        searchAthletes(searchText: $searchText, limit: $limit) {
            athleteID
            name
            surname
            email
            phone
            photo
            bibliography
            birthDate
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_ATHLETES = gql`
    mutation deleteAthletes($athleteIDs: ID!) {
        deleteAthletes(athleteIDs: $athleteIDs)
    }
`;
