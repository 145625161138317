/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditHeat from "./AddEditHeat";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_HEATS, DELETE_HEATS, GENERATE_HEATS } from "../Queries/heat";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import SearchInput from "../../General/Components/SearchInput";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

const Heats = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    helper.checkAdminRights({ ...props, client }, ["1", "2", "3"]);

    const [searchQuery, setSearchQuery] = useState("");
    const [listVariables, setListVariables] = useState({
        disciplineHashID: props.match.params.disciplineHashID,
        filters: {
            offset: 0,
            limit: 1000,
        },
    });

    const [selectedHeats, setSelectedHeats] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showGenerateHeatsNotifi, setShowGenerateHeatsNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedHeatID, setSelectedHeatID] = useState("");
    const {
        loading,
        error: getError,
        data: heatListData,
        fetchMore,
        refetch,
    } = useQuery(GET_HEATS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const [deleteHeat, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_HEATS, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            refetch({ filters: { ...listVariables.filters, limit: heatListData.heats.length } });
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const [generateHeats, { loading: genHeatsLoading }] = useMutation(GENERATE_HEATS, {
        onCompleted(data) {
            refetch();
            let notify = new Notification();
            notify.setNotification(translate.t("HeatsSuccessfullyGenerated"));
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const openCloseModal = (open, heatID) => {
        setSelectedHeatID(heatID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteHeats funkce, která posílá data na server
     * @param {*} heatID ID heatu
     */

    const initiateDeleteHeat = (e, heatID) => {
        var checked = e.target.checked;
        var arr = [...selectedHeats];

        if (!checked) {
            for (let i in selectedHeats) {
                if (selectedHeats[i] == heatID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [heatID, ...arr];
        }

        setSelectedHeats(arr);
    };

    const delHeat = (action) => {
        if (action) {
            let heatIDs = selectedHeats.join(",");

            deleteHeat({
                variables: {
                    heatIDs: heatIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedHeats.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    const runGenerateHeats = (action) => {
        if (action) {
            generateHeats({ variables: { disciplineHashID: props.match.params.disciplineHashID, lang: translate.locale } });
        }
        setShowGenerateHeatsNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <Fragment>
            <div className="mb-3">
                <div className="d-flex align-items-center discipline-title">
                    {translate.t("HeatList")}
                    <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                        {translate.t("AddHeat")}
                    </button>
                    <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                        {translate.t("DeleteSelected")}
                    </button>
                </div>

                <div className="">
                    {!error ? (
                        <div>
                            <div className="data-list">
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div>{translate.t("HeatNumber")}</div>
                                        <div className="text-center">{translate.t("AthleteSlashTeamCount")}</div>
                                        <div className="text-right">{translate.t("Options")}</div>
                                    </div>
                                </div>
                                <Fragment>
                                    {heatListData?.heats?.map((item, index) => {
                                        var checked = false;
                                        for (let i in selectedHeats) {
                                            if (selectedHeats[i] == item.heatID) checked = true;
                                        }

                                        return (
                                            <div key={index} className="data-list-item-content">
                                                <div>
                                                    {translate.t("HeatNumberTitle")} {item.heatOrder}
                                                </div>
                                                <div className="text-center">{item.teamCount}</div>

                                                <div className="text-right">
                                                    <img onClick={() => openCloseModal(true, item.heatID)} className="edit-icon" src={edit} />
                                                    <input
                                                        className="delete-checked"
                                                        type="checkbox"
                                                        name="deleteHeat[]"
                                                        checked={checked}
                                                        onChange={(e) => initiateDeleteHeat(e, item.heatID)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Fragment>
                            </div>
                            {!loading && !genHeatsLoading ? (
                                <Fragment>
                                    {!loading && (
                                        <>
                                            {!!heatListData?.heats?.length ? (
                                                <>
                                                    {heatListData.heats.length < heatListData.heatsCount && (
                                                        <div className="text-center p-5">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() =>
                                                                    fetchMore({
                                                                        variables: {
                                                                            ...listVariables,
                                                                            filters: {
                                                                                ...listVariables.filters,
                                                                                offset: heatListData.heats.length,
                                                                            },
                                                                        },
                                                                        updateQuery: (prev, { fetchMoreResult }) => {
                                                                            if (!fetchMoreResult) return prev;
                                                                            return Object.assign({}, prev, {
                                                                                heats: [...prev.heats, ...fetchMoreResult.heats],
                                                                            });
                                                                        },
                                                                    })
                                                                }
                                                            >
                                                                {" "}
                                                                {translate.t("LoadMore")}{" "}
                                                            </button>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <NoItems text={translate.t("NoItemsAddedYet")} />
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <button className="btn btn-primary" onClick={() => setShowGenerateHeatsNotifi(true)}>
                                                            {translate.t("GenerateHeatsAutomatically")}
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    ) : (
                        <Fragment>{error && <Error text={error} />}</Fragment>
                    )}
                </div>
            </div>
            {showAdd && (
                <AddEditHeat
                    openCloseModal={openCloseModal}
                    selectedHeatID={selectedHeatID}
                    disciplineID={heatListData?.discipline?.disciplineID}
                    listVariables={listVariables}
                    updated={() =>
                        refetch({
                            filters: {
                                ...listVariables.filters,
                                limit: listVariables.filters.limit > heatListData.heats.length ? listVariables.filters.limit : heatListData.heats.length,
                            },
                        })
                    }
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delHeat} />}
            {showGenerateHeatsNotifi && <ModalNotification yesNo={true} text={translate.t("AreYouSureYouWantToGenerateHeats?")} callback={runGenerateHeats} />}
        </Fragment>
    );
};

export default withRouter(Heats);
