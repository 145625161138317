/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci autentikací
 */
import Notification from "../../../Library/notification";
import translate from "../../Translations/index";

class Auth {
    constructor(parent) {
        this.parent = parent;
    }

    changePassword(changePassword) {
        const data = this.parent.state.password;
        let notify = new Notification();

        if (data.oldPassword !== "") {
            if (data.newPassword !== "" && data.checkNewPassword !== "" && data.newPassword === data.checkNewPassword) {
                if (data.newPassword !== data.oldPassword) {
                    changePassword({
                        variables: {
                            password: data.newPassword,
                            oldPassword: data.oldPassword,
                        },
                    });
                } else {
                    notify.setNotification(translate.t("OldPwdNotSame"), "danger");
                }
            } else {
                notify.setNotification(translate.t("NewPwdsNotSame"), "danger");
            }
        } else {
            notify.setNotification(translate.t("NotFilledOldPwd"), "danger");
        }
    }

    formHandle(e) {
        const t = e.target;
        const v = t.type === "checkbox" ? t.checked : t.value;
        const n = t.name;

        this.parent.setState({ ...this.parent.state, password: { ...this.parent.state.password, [n]: v } });
    }
}

export default Auth;
