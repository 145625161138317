/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit organiser
 */
export const ADD_EDIT_ORGANISER = gql`
    mutation AddEditOrganiser($addEditData: AddEditOrganiserDataInput) {
        addEditOrganiser(addEditData: $addEditData) {
            hashID
            name
        }
    }
`;

/**
 *  Data organizátora
 */

export const GET_ORGANISER = gql`
    query Organiser($organiserID: ID, $lang: String) {
        organiser(organiserID: $organiserID) {
            organiserID
            hashID
            name
            countryID
            logo
            sports(filters: { lang: $lang }) {
                sportID
                title
            }
        }
        allCountries {
            countryID
            name
        }
        allOrganisators {
            adminUserID
            name
            surname
        }
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;
export const GET_ORGANISER_PHOTOS = gql`
    query Organiser($organiserID: ID) {
        organiser(organiserID: $organiserID) {
            logo
        }
    }
`;

/**
 *  Seznam veškerých organizátorů
 */
export const GET_ORGANISERS = gql`
    query Organisers($filters: OrganisersFilters, $lang: String) {
        organisers(filters: $filters) {
            hashID
            organiserID
            countryID
            name
            sports(filters: { lang: $lang }) {
                title
            }
            country {
                name
            }
            contactPersons {
                name
                email
                phone
            }
        }

        organisersCount(filters: $filters)

        allCountries {
            countryID
            name
        }
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 * Smazání organizátorů.
 */
export const DELETE_ORGANISERS = gql`
    mutation deleteOrganisers($organiserIDs: ID!) {
        deleteOrganisers(organiserIDs: $organiserIDs)
    }
`;
/**
 * Úprava fotek organizátora
 */
export const EDIT_ORGANISER_PHOTOS = gql`
    mutation EditOrganiserPhotos($organiserID: ID, $logo: Upload, $logoURL: String) {
        editOrganiserPhotos(organiserID: $organiserID, logo: $logo, logoURL: $logoURL)
    }
`;

/**
 * Správa kontaktů
 */
export const ADD_EDIT_ORGANISER_CONTACT = gql`
    mutation AddEditOrganiserContact($addEditData: AddEditOrganiserContactDataInput) {
        addEditOrganiserContact(addEditData: $addEditData) {
            organiserContactID
        }
    }
`;
export const GET_ORGANISER_CONTACTS = gql`
    query OrganiserContacts($organiserID: ID) {
        organiserContacts(organiserID: $organiserID) {
            organiserContactID
            name
            email
            phone
        }
    }
`;
export const GET_ORGANISER_CONTACT = gql`
    query OrganiserContact($organiserContactID: ID) {
        organiserContact(organiserContactID: $organiserContactID) {
            organiserContactID
            name
            email
            phone
        }
    }
`;
export const DELETE_ORGANISER_CONTACTS = gql`
    mutation deleteOrganiserContacts($organiserContactIDs: ID!) {
        deleteOrganiserContacts(organiserContactIDs: $organiserContactIDs)
    }
`;

/**
 * Správa uživatelů (admin)
 */

export const ADD_EDIT_ORGANISER_ADMIN_USER = gql`
    mutation AddEditOrganiserAdminUser($addEditData: AddEditOrganiserAdminUserDataInput) {
        addEditOrganiserAdminUser(addEditData: $addEditData) {
            organiserAdminUserID
        }
    }
`;
export const GET_ORGANISER_ADMIN_USERS = gql`
    query OrganiserAdminUsers($organiserID: ID) {
        organiserAdminUsers(organiserID: $organiserID) {
            organiserAdminUserID
            adminUserID
            name
            surname
            email
        }
    }
`;
export const GET_ORGANISER_ADMIN_USER = gql`
    query OrganiserAdminUser($organiserAdminUserID: ID) {
        organiserAdminUser(organiserAdminUserID: $organiserAdminUserID) {
            organiserAdminUserID
            adminUserID
            name
            surname
            email
        }
        organiserUnassignedAdminUsers {
            adminUserID
            name
            surname
            email
        }
    }
`;
export const DELETE_ORGANISER_ADMIN_USERS = gql`
    mutation deleteOrganiserAdminUsers($organiserAdminUserIDs: ID!) {
        deleteOrganiserAdminUsers(organiserAdminUserIDs: $organiserAdminUserIDs)
    }
`;
