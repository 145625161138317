/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit notification
 */
export const ADD_NOTIFICATION = gql`
    mutation AddNotification($addEditData:AddEditNotificationDataInput){
        addNotification(addEditData:$addEditData){  
            hashID
            competitionHashID
            title
            text
            dateAdd
        }
    }
`;


/**
 *  Notification data
 */

export const GET_NOTIFICATION = gql`
    query Notification($hashID: ID!){
        notification(hashID:$hashID){  
            hashID
            competitionHashID
            title
            text
            dateAdd
        }
    }
`;

/**
 *  Seznam veškerých notificationů
 */
export const GET_NOTIFICATIONS = gql`
    query AllNotifications($competitionHashID:ID,$offset:Int,$limit:Int){
        allNotifications(competitionHashID:$competitionHashID,offset:$offset,limit:$limit){ 
            hashID
            competitionHashID
            title
            text
            dateAdd
        }
        notificationListCount(competitionHashID:$competitionHashID)
    }
`;





